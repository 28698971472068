import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button, List, Image } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";

import { linkClassName, useStyles } from "../styles"

import passwort1 from "../images/passwort1.png"
import passwort2 from "../images/passwort2.png"
import passwort3 from "../images/passwort3.png"
import { useTranslation } from "react-i18next";

export default function PasswortPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
            <div className={classes.container}>
                
                <h1 className={classes.title}>{t("PasswortPage.title")}</h1>

                <div className={classes.content}>

                    <Paragraph>
                    {t("PasswortPage.para1")} <a href={t("PasswortPage.link1_1")} className={linkClassName}>{t("PasswortPage.link1_2")}</a>.
                    </Paragraph>

                </div>
                
                <Button className={classes.headerbutton} component="a" href={t("PasswortPage.link1_1")} target="_blank" leftIcon={<ExternalLink size={14} />}>
                {t("PasswortPage.button")}
                </Button>
        
                <div className={classes.container}>
                    
                    <div className={classes.content}>
                        <Image
                            mx="auto"
                            radius="md"
                            src={passwort1}
                            caption={t("PasswortPage.caption1")}
                            alt={t("PasswortPage.alt1")}
                        />
                    </div>

                    <Paragraph>
                    {t("PasswortPage.para2")}
                    </Paragraph>

                    <Paragraph>
                    {t("PasswortPage.para3")}
                    </Paragraph>

                    <div className={classes.content}>
                        <Image
                            mx="auto"
                            radius="md"
                            src={passwort2}
                            caption={t("PasswortPage.caption2")}
                            alt={t("PasswortPage.alt2")}
                        />
                    </div>

                    <Paragraph>
                    {t("PasswortPage.para4")}
                    </Paragraph>
                    
                    <div className={classes.content}>
                        <Image
                            mx="auto"
                            radius="md"
                            src={passwort3}
                            caption={t("PasswortPage.caption3")}
                            alt={t("PasswortPage.alt3")}
                        />
                    </div>

                    <Paragraph>
                    {t("PasswortPage.para5")}
                    </Paragraph>

                    <List className={classes.content}>
                        <List.Item><b>LDAPS:</b> {t("PasswortPage.list1")}</List.Item>
                        <List.Item><b>Active Directory:</b> {t("PasswortPage.list2")}</List.Item>
                        <List.Item><b>Radius:</b> {t("PasswortPage.list3")}</List.Item>
                    </List>

                    <Paragraph>
                    {t("PasswortPage.para6")}
                    </Paragraph>

                </div>
            </div>
        </SafeAreaContainer>
    </div>
  );
}
