import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button, Grid, Image } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";

import { useStyles } from "../styles"
import { useTranslation } from "react-i18next";

import kartenlesegeraet from "../images/kartenlesegeraet.png"
import pin from "../images/pin.png"

export default function LernplaetzePage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
          <div className={classes.container}>

            <h1 className={classes.title}>{t("LernplaetzePage.title")}</h1>

            <div className={classes.content}>

              <h2>{t("LernplaetzePage.uebersichttitle")}</h2>

              <Paragraph>
              {t("LernplaetzePage.uebersichtpara1")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" href={t("LernplaetzePage.uebersichtlink1")} target="_blank" leftIcon={<ExternalLink size={14} />}>
            {t("LernplaetzePage.uebersichtbutton1")}
			      </Button>

            <div className={classes.content}>

              <Paragraph>
              {t("LernplaetzePage.uebersichtpara2")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" href={t("LernplaetzePage.uebersichtlink2")} target="_blank" leftIcon={<ExternalLink size={14} />}>
            {t("LernplaetzePage.uebersichtbutton2")}
			      </Button>

            <div className={classes.content}>

              <Paragraph>
              {t("LernplaetzePage.uebersichtpara3_1")}<b>EduApp</b>{t("LernplaetzePage.uebersichtpara3_2")}
              </Paragraph>

              <h2>{t("LernplaetzePage.zugrifftitle")}</h2>

              <Grid grow gutter="lg" justify="space-around" align="stretch">

                <Grid.Col sm ={8} md={5}>

                <Paragraph>
                {t("LernplaetzePage.zugriffpara1_1")}<b>Legi</b>{t("LernplaetzePage.zugriffpara1_2")}<b>CHN</b>{t("LernplaetzePage.zugriffpara1_3")}<b>HCI</b>{t("LernplaetzePage.zugriffpara1_4")}
                </Paragraph>

                  <Paragraph>
                  {t("LernplaetzePage.zugriffpara2")}
                  </Paragraph>

                </Grid.Col>
                        
                <Grid.Col sm ={4} md={3}>

                  <div>
                    <Image
                      maw = {240}
                      mx="auto"
                      radius="md"
                      src={kartenlesegeraet}
                      caption={t("LernplaetzePage.zugriffcaption1")}
                      alt={t("LernplaetzePage.zugriffalt1")}
                    />
                  </div>

                </Grid.Col>

              </Grid>

              <Paragraph>
              {t("LernplaetzePage.zugriffpara3")}<a href={t("LernplaetzePage.zugrifflink1_1")}>{t("LernplaetzePage.zugrifflink1_2")}</a>.
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" target="_blank" href={t("LernplaetzePage.zugrifflink1_1")} leftIcon={<ExternalLink size={14} />}>
            {t("LernplaetzePage.zugriffbutton")}
            </Button>

            <div className={classes.content}>

            <Paragraph>
            {t("LernplaetzePage.zugriffpara4")}
            </Paragraph>

              <Image
                mx="auto"
                radius="md"
                src={pin}
                caption={t("LernplaetzePage.zugriffcaption2")}
                alt={t("LernplaetzePage.zugriffalt2")}
              />

            </div>

          </div>
        </SafeAreaContainer>
    </div>
  );
}
