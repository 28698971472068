import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button, Grid, Image } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";

import {useStyles} from "../styles"
import { useTranslation } from "react-i18next";

import link from "../images/link.jpg"
import zug from "../images/zug.jpg"

export default function MobilitaetPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
          <div className={classes.container}>
            
            <h1 className={classes.title}>{t("MobilitaetPage.title")}</h1>

            <div className={classes.content}>

              <h2>{t("MobilitaetPage.campustitle")}</h2>

              <Grid grow gutter="lg" justify="space-around" align="stretch">

                <Grid.Col sm ={8} md={5}>

                  <Paragraph>
                  {t("MobilitaetPage.campuspara_1")}<b>ETH eLink</b>{t("MobilitaetPage.campuspara_2")}
                  </Paragraph>

                </Grid.Col>
                        
                <Grid.Col sm ={4} md={3}>

                  <div>
                    <Image
                      maw = {240}
                      mx="auto"
                      radius="md"
                      src={link}
                      caption={t("MobilitaetPage.campuscaption")}
                      alt={t("MobilitaetPage.campusalt")}
                    />
                  </div>
                        
                </Grid.Col>
              </Grid>

              <h2>{t("MobilitaetPage.fahrraedertitle")}</h2>
              
              <Paragraph>
              {t("MobilitaetPage.fahrraederpara_1")}<b>Publibike</b>{t("MobilitaetPage.fahrraederpara_2")}<b>VELOVE</b>{t("MobilitaetPage.fahrraederpara_3")}
              </Paragraph>

              <Button className={classes.headerbutton} component="a" target="_blank" href={t("MobilitaetPage.fahrraederlink1")} leftIcon={<ExternalLink size={14} />}>
              {t("MobilitaetPage.fahrraederbutton1")}
              </Button>

              <Button className={classes.headerbutton} component="a" target="_blank" href={t("MobilitaetPage.fahrraederlink2")} leftIcon={<ExternalLink size={14} />}>
              {t("MobilitaetPage.fahrraederbutton2")}
              </Button>

            </div>

            <div className={classes.content}>

              <h2>{t("MobilitaetPage.oevtitle")}</h2>

              <Paragraph>
              {t("MobilitaetPage.oevpara1_1")}<b>Zürcher Verkehrsverbund (ZVV)</b>{t("MobilitaetPage.oevpara1_2")}<i>NetzPass</i>{t("MobilitaetPage.oevpara1_3")}
              </Paragraph>

              <Button className={classes.headerbutton} component="a" target="_blank" href={t("MobilitaetPage.oevlink1")} leftIcon={<ExternalLink size={14} />}>
              {t("MobilitaetPage.oevbutton1")}
              </Button>

              <Grid grow gutter="lg" justify="space-around" align="stretch">

                <Grid.Col sm ={8} md={5}>

                  <Paragraph>
                  {t("MobilitaetPage.oevpara2_1")}<b>{t("MobilitaetPage.oevpara2_2")}</b>{t("MobilitaetPage.oevpara2_3")}<i>GA Night</i>{t("MobilitaetPage.oevpara2_4")}
                  </Paragraph>

                  <Button className={classes.headerbutton} component="a" target="_blank" href={t("MobilitaetPage.oevlink2")} leftIcon={<ExternalLink size={14} />}>
                  {t("MobilitaetPage.oevbutton2")}
                  </Button>

                </Grid.Col>
                        
                <Grid.Col sm ={4} md={3}>
                  <div>
                    <Image
                      maw = {480}
                      mx="auto"
                      radius="md"
                      src={zug}
                      caption={t("MobilitaetPage.oevcaption")}
                      alt={t("MobilitaetPage.oevalt")}
                    />
                  </div>
                </Grid.Col>
              </Grid>

            </div>
          </div>
        </SafeAreaContainer>

    </div>
  );
}
