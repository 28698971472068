import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { List } from "@mantine/core";

import { useStyles} from "../styles"
import { useTranslation } from "react-i18next";

export default function MensenPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <SafeAreaContainer>
          <div className={classes.container}>

            <h1 className={classes.title}>{t("MensenPage.title")}</h1>

            <div className={classes.content}>

              <Paragraph>
              {t("MensenPage.para")}
              </Paragraph>

              <List className={classes.content}>
                <List.Item>{t("MensenPage.list1")}</List.Item>
                <List.Item>{t("MensenPage.list2")}</List.Item>
                <List.Item>{t("MensenPage.list3")}</List.Item>
                <List.Item>{t("MensenPage.list4")}</List.Item>
              </List>

              <h2>{t("MensenPage.zentrumtitle")}</h2>

              <h3>{t("MensenPage.polymensatitle")}</h3>

              <Paragraph>
              {t("MensenPage.polymensapara1")}
              </Paragraph>

              <Paragraph>
              {t("MensenPage.polymensapara2")}
              </Paragraph>

              <h3>{t("MensenPage.foodlabtitle")}</h3>

              <Paragraph>
              {t("MensenPage.foodlabpara")}
              </Paragraph>

              <h3>{t("MensenPage.clausiustitle")}</h3>

              <Paragraph>
              {t("MensenPage.clausiuspara")}
              </Paragraph>

              <h3>{t("MensenPage.tannenbartitle")}</h3>

              <Paragraph>
              {t("MensenPage.tannenbarpara")}
              </Paragraph>

              <h3>{t("MensenPage.archimedestitle")}</h3>

              <Paragraph>
              {t("MensenPage.archimedespara")}
              </Paragraph>

              <h3>{t("MensenPage.polysnacktitle")}</h3>

              <Paragraph>
              {t("MensenPage.polysnackpara")}
              </Paragraph>

              <h3>{t("MensenPage.zweisteintitle")}</h3>

              <Paragraph>
              {t("MensenPage.zweisteinpara")}
              </Paragraph>

              <h3>{t("MensenPage.zweigradbistrotitle")}</h3>

              <Paragraph>
              {t("MensenPage.zweigradbistropara")}
              </Paragraph>

              <h3>{t("MensenPage.unimensatitle")}</h3>

              <Paragraph>
              {t("MensenPage.unimensapara")}
              </Paragraph>

              <h3>{t("MensenPage.raemi59title")}</h3>

              <Paragraph>
              {t("MensenPage.raemi59para")}
              </Paragraph>

              <h3>{t("MensenPage.plattetitle")}</h3>

              <Paragraph>
              {t("MensenPage.plattepara")}
              </Paragraph>

              <h2>{t("MensenPage.hoenggtitle")}</h2>

              <h3>{t("MensenPage.fusionmealtitle")}</h3>

              <Paragraph>
              {t("MensenPage.fusionmealpara")}
              </Paragraph>

              <h3>{t("MensenPage.foodmarkettitle")}</h3>

              <Paragraph>
              {t("MensenPage.foodmarketpara")}
              </Paragraph>

              <h4>{t("MensenPage.foodmarketgrilltitle")}</h4>

              <Paragraph>
              {t("MensenPage.foodmarketgrillpara")}
              </Paragraph>

              <h4>{t("MensenPage.foodmarketpizzatitle")}</h4>

              <Paragraph>
              {t("MensenPage.foodmarketpizzapara")}
              </Paragraph>

              <h4>{t("MensenPage.foodmarketgreentitle")}</h4>

              <Paragraph>
              {t("MensenPage.foodmarketgreenpara")}
              </Paragraph>

              <h3>{t("MensenPage.riceuptitle")}</h3>

              <Paragraph>
              {t("MensenPage.riceuppara")}
              </Paragraph>

              <h3>{t("MensenPage.alumniloungetitle")}</h3>

              <Paragraph>
              {t("MensenPage.alumniloungepara")}
              </Paragraph>

              <h3>{t("MensenPage.fusioncoffeetitle")}</h3>

              <Paragraph>
              {t("MensenPage.fusioncoffeepara1")}
              </Paragraph>

              <Paragraph>
              {t("MensenPage.fusioncoffeepara2")}
              </Paragraph>

              <h3>{t("MensenPage.hpibistrotitle")}</h3>

              <Paragraph>
              {t("MensenPage.hpibistropara")}
              </Paragraph>

            </div>
  
          </div>
        </SafeAreaContainer>

    </div>
  );
}
