import { SetStateAction } from "react";
import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Grid } from "@mantine/core";
import PageGrid from "../PageGridHomePage";
import { useTranslation } from "react-i18next";

import {useStyles} from "../styles"

export default function Startpage() {
    const { t } = useTranslation();
	const { classes } = useStyles();
	
	return (
		<div>
			
			<SafeAreaContainer>
        		<div className={classes.container}>
          			<h1 className={classes.title}>{t("HomePage.title")}</h1>

					<div className={classes.content}>

						<Grid grow gutter="lg" justify="space-around" align="stretch">

							<Grid.Col sm ={6} md={4}>
								<div className={classes.content}>
									<Paragraph>
									{t("HomePage.para1")}
									</Paragraph>
									<Paragraph>
									{t("HomePage.para2")}
									</Paragraph>
									<Paragraph>
									{t("HomePage.para3")}
									</Paragraph>
								</div>
							</Grid.Col>

							<Grid.Col sm ={6} md={4}>
								<div>
									<iframe
										width="500"
										height="275"
										className={classes.videoright}
										src={t("HomePage.video")}
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/> {""}
								</div>
							</Grid.Col>
							
						</Grid>
					</div>
					
					<PageGrid primaryColor={""} onPrimaryColorChange={function (value: SetStateAction<string>): void {
						throw new Error("Function not implemented.");
					} }></PageGrid>
				</div>
			</SafeAreaContainer>

		</div>
	);
}
