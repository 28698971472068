import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button, Image } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";
import { useTranslation } from "react-i18next";

import { linkClassName, useStyles } from "../styles"

import mystudiesfunktionen from "../images/mystudiesfunktionen.png"

export default function EinschreibungPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
            <div className={classes.container}>

                <h1 className={classes.title}>{t("EinschreibungPage.title")}</h1>

                <div className={classes.content}>

                    <Paragraph>
                    <b>{t("EinschreibungPage.para_1")}</b>{t("EinschreibungPage.para_2")}
                    </Paragraph>

                    <h2>{t("EinschreibungPage.vvztitle")}</h2>

                    <Paragraph>
                    {t("EinschreibungPage.vvzpara_1")}<b>{t("EinschreibungPage.vvzpara_2")}</b>{t("EinschreibungPage.vvzpara_3")}<a href={t("EinschreibungPage.vvzlink1_1")} className={linkClassName}>{t("EinschreibungPage.vvzlink1_2")}</a>{t("EinschreibungPage.vvzpara_4")}
                    </Paragraph>

                </div>

                <Button className={classes.headerbutton} component="a" href={t("EinschreibungPage.vvzlink1_1")} target="_blank" leftIcon={<ExternalLink size={14} />}>
                {t("EinschreibungPage.vvzbutton")}
                </Button>

                <div className={classes.content}>

                    <h2>{t("EinschreibungPage.einschreibungtitle")}</h2>

                    <Paragraph>
                    {t("EinschreibungPage.einschreibungpara1_1")}<b>myStudies</b>{t("EinschreibungPage.einschreibungpara1_2")}
                    </Paragraph>

                </div>

                <Button className={classes.headerbutton} component="a" href="./lehrveranstaltungen" target="_blank">
                {t("EinschreibungPage.einschreibungbutton1")}
                </Button>

                <div className={classes.content}>

                    <Paragraph>
                    <b>{t("EinschreibungPage.einschreibungpara2_1")}</b>{t("EinschreibungPage.einschreibungpara2_2")}
                    </Paragraph>

                    <Image
                        mx="auto"
                        radius="md"
                        src={mystudiesfunktionen}
                        caption={t("EinschreibungPage.einschreibungcaption")}
                        alt={t("EinschreibungPage.einschreibungalt")}
                    />

                    <Paragraph>
                    {t("EinschreibungPage.einschreibungpara3")}
                    </Paragraph>

                </div>

                <Button className={classes.headerbutton} component="a" href={t("EinschreibungPage.einschreibunglink")} target="_blank">
                {t("EinschreibungPage.einschreibungbutton2")}
                </Button>

                <div className={classes.content}>
                    
                    <h2>{t("EinschreibungPage.belegungtitle")}</h2>

                    <Paragraph>
                    {t("EinschreibungPage.belegungpara")}
                    </Paragraph>

                    <h2>{t("EinschreibungPage.pruefungentitle")}</h2>

                    <Paragraph>
                    <b>{t("EinschreibungPage.pruefungenpara_1")}</b>{t("EinschreibungPage.pruefungenpara_2")}
                    </Paragraph>
                    
                </div>
            </div>
        </SafeAreaContainer>
    </div>
  );
}
