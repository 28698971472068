import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button, List } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";


import {useStyles} from "../styles"
import { useTranslation } from "react-i18next";

export default function StudierenPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
          <div className={classes.container}>

            <h1 className={classes.title}>{t("StudierenPage.title")}</h1>

            <div className={classes.content}>
              
              <h2>{t("StudierenPage.laptoptitle")}</h2>

              <Paragraph>
              <b>{t("StudierenPage.laptoppara1_1")}</b>{t("StudierenPage.laptoppara1_2")}<b>{t("StudierenPage.laptoppara1_3")}</b>{t("StudierenPage.laptoppara1_4")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" target="_blank" href={t("StudierenPage.laptoplink1")} leftIcon={<ExternalLink size={14} />}>
            {t("StudierenPage.laptopbutton1")}
            </Button>
            
            <div className={classes.content}>

              <Paragraph>
              {t("StudierenPage.laptoppara2_1")}<b>Projekt Neptun</b>{t("StudierenPage.laptoppara2_2")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" target="_blank" href={t("StudierenPage.laptoplink2")} leftIcon={<ExternalLink size={14} />}>
            {t("StudierenPage.laptopbutton2")}
            </Button>
            
            <div className={classes.content}>
              
              <h2>{t("StudierenPage.buechertitle")}</h2>

              <Paragraph>
              {t("StudierenPage.buecherpara1_1")}<b>{t("StudierenPage.buecherpara1_2")}</b>{t("StudierenPage.buecherpara1_3")}
              </Paragraph>

              <Paragraph>
              {t("StudierenPage.buecherpara2_1")}<b>SpringerLink</b>{t("StudierenPage.buecherpara2_2")}
              </Paragraph>

              <Paragraph>
              {t("StudierenPage.buecherpara3")}
              </Paragraph>

            </div>

            <div className={classes.content}>

              <h2>{t("StudierenPage.materialtitle")}</h2>

              <Paragraph>
              {t("StudierenPage.materialpara1_1")}<b>ETH Store</b>{t("StudierenPage.materialpara1_2")}
              </Paragraph>

              <Paragraph>
              {t("StudierenPage.materialpara2")}
              </Paragraph>

              <List className={classes.content}>
                <List.Item>{t("StudierenPage.materiallist1")}</List.Item>
                <List.Item>{t("StudierenPage.materiallist2")}</List.Item>
                <List.Item>{t("StudierenPage.materiallist3")}</List.Item>
                <List.Item>{t("StudierenPage.materiallist4")}</List.Item>
                <List.Item>{t("StudierenPage.materiallist5")}</List.Item>
                <List.Item>{t("StudierenPage.materiallist6")}</List.Item>
                <List.Item>{t("StudierenPage.materiallist7")}</List.Item>
                <List.Item>{t("StudierenPage.materiallist8")}</List.Item>
              </List>

              <Paragraph>
              {t("StudierenPage.materialpara3")}
              </Paragraph>

            </div>    
            
            <div className={classes.content}>

              <h2>{t("StudierenPage.druckentitle")}</h2>
              
              <Paragraph>
              {t("StudierenPage.druckenpara")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" href="./drucken">
            {t("StudierenPage.druckenbutton")}
            </Button>

          </div>
        </SafeAreaContainer>
    </div>
  );
}
