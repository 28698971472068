import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button, List } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";
import { useTranslation } from "react-i18next";

import {useStyles} from "../styles"

export default function WLANPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
            <div className={classes.container}>

                <h1 className={classes.title}>{t("WLANPage.title")}</h1>

                <div className={classes.content}>

                    <h2>{t("WLANPage.wlantitle")}</h2>

                    <Paragraph>
                    {t("WLANPage.wlanpara1_1")} <b>eduroam</b> {t("WLANPage.wlanpara1_2")} <b>eduroam-5</b> {t("WLANPage.wlanpara1_3")}
                    </Paragraph>

                    <List className={classes.content}>
                        <List.Item><b>{t("WLANPage.wlanlist1_1")}</b> <i>{t("WLANPage.wlanlist1_2")}</i>@student-net.ethz.ch</List.Item>
                        <List.Item><b>{t("WLANPage.wlanlist2_1")}</b> {t("WLANPage.wlanlist2_2")}</List.Item>
                    </List>
                    
                    <Paragraph>
                    {t("WLANPage.wlanpara2")}
                    </Paragraph>

                    <Button className={classes.headerbutton} component="a" href="./passwort">
                    {t("WLANPage.wlanbutton")}
                    </Button>

                </div>

                <div className={classes.content}>
                    
                    <Paragraph>
                    <b>Fun Fact:</b>{t("WLANPage.wlanpara3")}
                    </Paragraph>

                </div>

                <div className={classes.content}>

                    <h2>{t("WLANPage.vpntitle")}</h2>

                    <Paragraph>
                    {t("WLANPage.vpnpara1_1")}<b>VPN</b>{t("WLANPage.vpnpara1_2")}<b>Virtual Private Network</b>{t("WLANPage.vpnpara1_3")}
                    </Paragraph>

                    <List className={classes.content}>
                        <List.Item>{t("WLANPage.vpnlist1")}</List.Item>
                        <List.Item>{t("WLANPage.vpnlist2")}</List.Item>
                    </List>
                    
                    <Paragraph>
                    {t("WLANPage.vpnpara2")}
                    </Paragraph>

                </div>

                <Button className={classes.headerbutton} component="a" href={t("WLANPage.vpnlink")} target="_blank" leftIcon={<ExternalLink size={14} />}>
                {t("WLANPage.vpnbutton")}
                </Button>

            </div>
        </SafeAreaContainer>
    </div>
  );
}
                    