import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";

import { useStyles } from "../styles"
import { useTranslation } from "react-i18next";

export default function DruckenPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return ( 
    <div>
        <SafeAreaContainer>
          <div className={classes.container}>

            <h1 className={classes.title}>{t("DruckenPage.title")}</h1>

            <div className={classes.content}>

              <h2>{t("DruckenPage.wiewotitle")}</h2>

              <Paragraph>
              {t("DruckenPage.wiewopara1")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" target="_blank" href={t("DruckenPage.wiewolink1")} leftIcon={<ExternalLink size={14} />}>
            {t("DruckenPage.wiewobutton1")}
            </Button>

            <div className={classes.content}>

              <Paragraph>
              {t("DruckenPage.wiewopara2")}
              </Paragraph>

            </div> 

            <Button className={classes.headerbutton} component="a" target="_blank" href={t("DruckenPage.wiewolink2")} leftIcon={<ExternalLink size={14} />}>
            {t("DruckenPage.wiewobutton2")}
            </Button>

            <div className={classes.content}>

              <Paragraph>
              {t("DruckenPage.wiewopara3")}
              </Paragraph>

            </div>

            <div className={classes.content}>

              <h2>{t("DruckenPage.spezielletitle")}</h2>

              <Paragraph>
              {t("DruckenPage.speziellepara_1")}<b>Print + Publish</b>{t("DruckenPage.speziellepara_2")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" href={t("DruckenPage.speziellelink")} leftIcon={<ExternalLink size={14} />}>
				    {t("DruckenPage.speziellebutton")}
			      </Button>

            <div className={classes.content}>
              
                <h2>{t("DruckenPage.kopierentitle")}</h2>
                
                <Paragraph>
                {t("DruckenPage.kopierenpara")}
                </Paragraph>

                <h2>{t("DruckenPage.bindentitle")}</h2>

                <Paragraph>
                {t("DruckenPage.bindenpara")}
                </Paragraph>
              
            </div>
            
          </div>
        </SafeAreaContainer>

    </div>
  );
}
