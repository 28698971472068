import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button, List } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";
import { useTranslation } from "react-i18next";

import { useStyles } from "../styles"

export default function FreizeitPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
          <div className={classes.container}>

            <h1 className={classes.title}>{t("FreizeitPage.title")}</h1>

            <div className={classes.content}>

              <h2>{t("FreizeitPage.vsethtitle")}</h2>

              <Paragraph>
              {t("FreizeitPage.vsethpara1_1")}<b>{t("FreizeitPage.vsethpara1_2")}</b>{t("FreizeitPage.vsethpara1_3")}
              </Paragraph>

              <Paragraph>
              {t("FreizeitPage.vsethpara2")}
              </Paragraph>

              <List className={classes.content}>
                <List.Item>{t("FreizeitPage.vsethlist1_1")}<b>{t("FreizeitPage.vsethlist1_2")}</b>{t("FreizeitPage.vsethlist1_3")}</List.Item>
                <List.Item><b>{t("FreizeitPage.vsethlist2_1")}</b>{t("FreizeitPage.vsethlist2_2")}</List.Item>
                <List.Item>{t("FreizeitPage.vsethlist3_1")}<b>{t("FreizeitPage.vsethlist3_2")}</b>{t("FreizeitPage.vsethlist3_3")}</List.Item>
              </List>

              <Paragraph>
              {t("FreizeitPage.vsethpara3")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" target="_blank" href={t("FreizeitPage.vsethlink")}>
            {t("FreizeitPage.vsethbutton1")}
            </Button>

            <div className={classes.content}>
              
              <Paragraph>
              {t("FreizeitPage.vsethpara4")}
              </Paragraph>

              <Paragraph>
              {t("FreizeitPage.vsethpara5")}
              </Paragraph>

              <Paragraph>
              {t("FreizeitPage.vsethpara6")}
              </Paragraph>

            </div>
            
            <Button className={classes.headerbutton} component="a" href="./vorstellung">
            {t("FreizeitPage.vsethbutton2")}
            </Button>

            <div className={classes.content}>

              <h2>{t("FreizeitPage.fvtitle")}</h2>

              <Paragraph>
              {t("FreizeitPage.fvpara")}
              </Paragraph>

              <h2>{t("FreizeitPage.asvztitle")}</h2>

              <Paragraph>
              {t("FreizeitPage.asvzpara1_1")}<b>{t("FreizeitPage.asvzpara1_2")}</b>{t("FreizeitPage.asvzpara1_3")}
              </Paragraph>

              <Paragraph>
              {t("FreizeitPage.asvzpara2")}
              </Paragraph>

            </div>
            
            <Button className={classes.headerbutton} component="a" target="_blank" href={t("FreizeitPage.asvzlink")} leftIcon={<ExternalLink size={14} />}>
            {t("FreizeitPage.asvzbutton")}
            </Button>

            <div className={classes.content}>

              <h2>{t("FreizeitPage.stadttitle")}</h2>

              <Paragraph>
              {t("FreizeitPage.stadtpara")}
              </Paragraph>
                
            </div>

            <Button className={classes.headerbutton} component="a" target="_blank" href="./zuerich">
            {t("FreizeitPage.stadtbutton")}
            </Button>
            
          </div>
        </SafeAreaContainer>

    </div>
  );
}
