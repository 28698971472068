import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button, List } from "@mantine/core";
import { ExternalLink} from "tabler-icons-react";


import {linkClassName, useStyles} from "../styles"
import { useTranslation } from "react-i18next";

export default function ZuerichPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
          <div className={classes.container}>

            <h1 className={classes.title}>{t("ZuerichPage.title")}</h1>

            <div className={classes.content}>

                <Paragraph>
                {t("ZuerichPage.para")}
                </Paragraph>
            
                <h2>{t("ZuerichPage.tippstitle")}</h2>
                <Paragraph>
                {t("ZuerichPage.tippspara_1")}<a href={t("ZuerichPage.tippslink")} className={linkClassName}>{t("ZuerichPage.tippslinktext")}</a>{t("ZuerichPage.tippspara_2")}
                </Paragraph>

                <h2>{t("ZuerichPage.sonnetitle")}</h2>

                <h3>{t("ZuerichPage.schwimmentitle")}
                <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.schwimmenlink")} leftIcon={<ExternalLink size={14} />}>
                {t("ZuerichPage.schwimmenbutton")}
                </Button>
                </h3>

                <Paragraph>
                {t("ZuerichPage.schwimmenpara")}
                </Paragraph>

                <h3>{t("ZuerichPage.velotitle")}         
                <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.velolink1")} leftIcon={<ExternalLink size={14} />}>
                {t("ZuerichPage.velobutton1")}
                </Button>
                <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.velolink2")} leftIcon={<ExternalLink size={14} />}>
                {t("ZuerichPage.velobutton2")}
                </Button>
                </h3>

                <Paragraph>
                {t("ZuerichPage.velopara")}
                </Paragraph>

                <h3>{t("ZuerichPage.bergetitle")}
                <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.bergelink")} leftIcon={<ExternalLink size={14} />}>
                {t("ZuerichPage.bergebutton")}
                </Button>
                </h3>

                <Paragraph>
                {t("ZuerichPage.bergepara")}
                </Paragraph>

                <h3>{t("ZuerichPage.maerktetitle")}
                    <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.maerktelink")} leftIcon={<ExternalLink size={14} />}>
                    {t("ZuerichPage.maerktebutton")}
                    </Button>
                </h3>

                <Paragraph>
                {t("ZuerichPage.maerktepara")}
                </Paragraph>

                <h3>{t("ZuerichPage.sporttitle")}
                    <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.sportlink1")} leftIcon={<ExternalLink size={14} />}>
                    {t("ZuerichPage.sportbutton1")}
                    </Button>
                    <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.sportlink2")} leftIcon={<ExternalLink size={14} />}>
                    {t("ZuerichPage.sportbutton2")}
                    </Button>
                </h3>

                <Paragraph>
                {t("ZuerichPage.sportpara")}
                </Paragraph>

                <h2>{t("ZuerichPage.regentitle")}</h2>

                <h3>{t("ZuerichPage.brockititle")}
                <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.brockilink")} leftIcon={<ExternalLink size={14} />}>
                {t("ZuerichPage.brockibutton")}
                </Button>
                </h3>

                <Paragraph>
                {t("ZuerichPage.brockipara")}
                </Paragraph>

                <h3>{t("ZuerichPage.zootitle")}
                <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.zoolink")} leftIcon={<ExternalLink size={14} />}>
                {t("ZuerichPage.zoobutton")}
                </Button>
                </h3>

                <Paragraph>
                {t("ZuerichPage.zoopara")}
                </Paragraph>
                
                <h3>{t("ZuerichPage.landestitle")}
                <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.landeslink")} leftIcon={<ExternalLink size={14} />}>
                {t("ZuerichPage.landesbutton")}
                </Button>
                </h3>

                <Paragraph>
                {t("ZuerichPage.landespara")}
                </Paragraph>

                <h3>{t("ZuerichPage.museumtitle")}
                <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.museumlink")} leftIcon={<ExternalLink size={14} />}>
                {t("ZuerichPage.museumbutton")}
                </Button>
                </h3>

                <Paragraph>
                {t("ZuerichPage.museumpara")}
                </Paragraph>

                <h3>{t("ZuerichPage.voltairetitle")}
                <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.voltairelink")} leftIcon={<ExternalLink size={14} />}>
                {t("ZuerichPage.voltairebutton")}
                </Button>
                </h3>

                <Paragraph>
                {t("ZuerichPage.voltairepara")}
                </Paragraph>

                <h3>{t("ZuerichPage.sukkulententitle")}
                <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.sukkulentenlink")} leftIcon={<ExternalLink size={14} />}>
                {t("ZuerichPage.sukkulentenbutton")}
                </Button>
                </h3>

                <Paragraph>
                {t("ZuerichPage.sukkulentenpara")}
                </Paragraph>

                <h3>{t("ZuerichPage.kunsttitle")}
                    <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.kunstlink")} leftIcon={<ExternalLink size={14} />}>
                    {t("ZuerichPage.kunstbutton")}
                    </Button>
                </h3>

                <Paragraph>
                {t("ZuerichPage.kunstpara")}
                </Paragraph>

                <h3>{t("ZuerichPage.einkaufentitle")}</h3>

                <Paragraph>
                {t("ZuerichPage.einkaufenpara")}
                </Paragraph>

                <List className={classes.content}>
                    <List.Item>{t("ZuerichPage.einkaufenlist1")}</List.Item>
                    <List.Item>{t("ZuerichPage.einkaufenlist2")}</List.Item>
                    <List.Item>{t("ZuerichPage.einkaufenlist3")}</List.Item>
                </List>

                <h2>{t("ZuerichPage.abendtitle")}
                    <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.abendlink")} leftIcon={<ExternalLink size={14} />}>
                    {t("ZuerichPage.abendbutton")}
                    </Button>
                </h2>

                <Paragraph>
                {t("ZuerichPage.abendpara")}
                </Paragraph>

                <h3>{t("ZuerichPage.theatertitle")}
                <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.theaterlink1")} leftIcon={<ExternalLink size={14} />}>
                {t("ZuerichPage.theaterbutton1")}
                </Button>
                <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.theaterlink2")} leftIcon={<ExternalLink size={14} />}>
                {t("ZuerichPage.theaterbutton2")}
                </Button></h3>

                <Paragraph>
                {t("ZuerichPage.theaterpara")}
                </Paragraph>

                <h3>{t("ZuerichPage.kinotitle")}
                <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.kinolink")} leftIcon={<ExternalLink size={14} />}>
                {t("ZuerichPage.kinobutton")}
                </Button>
                </h3>

                <Paragraph>
                {t("ZuerichPage.kinopara")}
                </Paragraph>

                <h3>{t("ZuerichPage.barstitle")}</h3>

                <Paragraph>
                {t("ZuerichPage.barspara")}
                </Paragraph>

                <h3>{t("ZuerichPage.clubstitle")}</h3>

                <Paragraph>
                {t("ZuerichPage.clubspara")}
                </Paragraph>
    
                <h2>{t("ZuerichPage.wochenendetitle")}
                    <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.link1title")} leftIcon={<ExternalLink size={14} />}>
                    {t("ZuerichPage.wochenendebutton1")}
                    </Button>
                    <Button className={classes.headerbutton} component="a" target="_blank" href={t("ZuerichPage.link2")} leftIcon={<ExternalLink size={14} />}>
                    {t("ZuerichPage.wochenendebutton2")}
                    </Button>
                </h2>

                <Paragraph>
                {t("ZuerichPage.wochenendepara")}
                </Paragraph>

                <h2>{t("ZuerichPage.ausflugtitle")}</h2>

                <Paragraph>
                {t("ZuerichPage.ausflugpara")}
                </Paragraph>

            </div>
          </div>
        </SafeAreaContainer>

    </div>
  );
}
