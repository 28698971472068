import { Dispatch, SetStateAction } from "react";
import { Card, Group, Image, Text, Grid} from "@mantine/core";
import { Link } from "react-router-dom";

import hg from "./images/hg.jpg"
import essen from "./images/essen.jpg"
import unterstuetzung from "./images/unterstuetzung.jpg"
import it from "./images/it.jpg"
import vorlesung from "./images/vorlesung.jpg"
import international from "./images/international.jpg"
import vseth from "./images/vseth.jpg"
import { useStyles} from "./styles"
import { useTranslation } from "react-i18next";
interface Cards{
    title: string,
    teaser: string,
    image_path: string,
    alt_image: string,
    page_link: string
    image: string
}

export function CardsFunction() {
    const { t } = useTranslation();
    const cards: Cards[] = [
        {
            title : "Wie verbinde ich mich mit dem WLAN? Wie verbinde ich mich mit dem ETH-VPN?",
            teaser : "Lorem ipsum",
            image_path: "./GB3.png",
            alt_image: "numero uno",
            page_link: "/erstewoche",
            image: hg
        },
        {
            title : "Wie funktioniert meine ETH-Mailbox? Wie kann ich sie mir einrichten?",
            teaser : "Lorem ipsum",
            image_path: "./image.png",
            alt_image: "numero due",
            page_link: "/essen",
            image: essen
        },
        {
            title : "Unterstützung",
            teaser : "Falls du mal auf Probleme, egal ob aufs Studium bezogen oder persönliche, triffst, findest du rund um die ETH verschiedene Anlaufstellen, welche dir ein offenes Ohr bieten und beratend zur Seite stehen.",
            image_path: "./image.png",
            alt_image: "numero due",
            page_link: "/unterstuetzung",
            image: unterstuetzung
        },
        {
            title : "Computer, IT und Drucken" ,
            teaser : "Die Informatikdienste stellen ein ganzes Arsenal an Services, von einem E-Mail-Postfach über einen kostenfreien «Dropbox-Klon» namens polybox, bis zu vielen kostenlosen Softwarelizenzen.",
            image_path: "./image.png",
            alt_image: "numero due",
            page_link: "/it",
            image: it
        },
        {
            title : "Sonstige Angebote",
            teaser : "Suchst du das richtige Buch für deine Vorlesung oder ist dir die Tinte ausgegangen? Dann bist du bei den Bibliotheken und ETH Store genau richtig. Wenn du eine sportliche Abwechslung suchst, dann kannst du dich im ASVZ austoben",
            image_path: "./image.png",
            alt_image: "numero due",
            page_link: "/standorte",
            image: vorlesung
        },
        {
            title : "International",
            teaser : "Während deiner Zeit an der ETH soll der Austausch zu anderen Kulturen auch nicht zu kurz kommen. So kannst du einen Teil deines Studiums im Ausland verbringen oder am Sprachenzentrum Sprachkurse belegen. ",
            image_path: "./image.png",
            alt_image: "numero due",
            page_link: "/international",
            image: international
        },
        {
            title : "Der VSETH",
            teaser : "Der Verband der Studierenden an der ETH ist deine Anlaufstelle für hochschulpolitische Anlegen. Aber auch die Events kommen bei uns dank der vielen Kommissionen und Fachvereinen nicht zu kurz.",
            image_path: "./image.png",
            alt_image: "numero due",
            page_link: "/vorstellung",
            image: vseth
        }
    ]
    return cards;
}

export default function PageGridListPage({
    primaryColor,
	onPrimaryColorChange,
}: {
	primaryColor: string;
	onPrimaryColorChange: Dispatch<SetStateAction<string>>;
}) {
	const { classes } = useStyles();
	return (
		<div className={classes.content}>
            <Grid grow gutter="lg" justify="space-around" align="stretch">
                
                {CardsFunction().map((c) => (
                    
                    <Grid.Col sm ={6} md={4}>
                        <div>
                         <Card shadow="lg" p="lg" component={Link} to={c.page_link}>
                             
                             <Card.Section>                
                                 <Image src={c.image} height={200} alt={c.alt_image} />
                             </Card.Section>
 
                             <Group position="apart" style={{ marginBottom: 5, marginTop: 5 }}>
                                 <Text weight={500}>{c.title}</Text>
                             </Group>
 
                             <Text size="sm" style={{ color: '#183059', lineHeight: 1.5 }}>
                                 {c.teaser}
                             </Text>
                         </Card>
                         </div>
                    </Grid.Col>
                     
        
                ))}
            </Grid>                                  
		</div>
	);
}
