export const i18n_english = {
    translation: {
        lang: "en",
        HomePage: {
            title: "Welcome to ETH Zürich",
            para1: "We, the Students' Association of ETH (or VSETH for short), would like to welcome you warmly to ETH Zurich. However, all beginnings are difficult. ETH is huge and has tons of offers, support and cool stuff for you. But how do I do things? And what should I do? And where? Answering these questions is exactly why we've put together this website full of information for you. You'll find all the important information and links about ETH here, as well as lots of tips to make your start at ETH as easy as possible!",
            para2: "We wish you lots of success and fun for your studies.",
            para3: "Your VSETH",
            video: "https://www.youtube.com/embed/Kr6uyVFkjZg"
        },
        PageGridHomePage: {
            title1: "The First Week",
            teaser1: "The first days of your new studies have begun and the first thing you have to do is to find your way around ETH Zurich. Here you will find out everything you need to know about everyday life at ETH.",
            alt1: "A picture of the main building of ETH",
            title2: "Nachschlagewerk",
            teaser2: "You have already arrived at ETH, but you have forgotten how to log on to the Wi-Fi? Or where to print? Or where to eat? Here you will find all the important information again.",
            alt2: "A picture of the main building of ETH"
        },
        WochePage: {
            title: "The First Week",
            para1: "First of all, congratulations on making it to ETH! Now you're probably thinking: Wow, what a huge university, so many buildings, so many rooms, so many things I have to remember. Don't worry, we will guide you through all the important things you will need at ETH. First of all, we'll show you the basics about how ETH works.",
            para2: "The content of this website was created in good conscience by VSETH in September 2023 to help new ETH students get started. We do not claim complete accuracy and objectivity of the content. Feedback gladly to kommunikation@vseth.ethz.ch.",
            legititle: "Your ETH Identity",
            legipara1: "First of all, who are you at ETH? What personal data do you need to remember that you will need again and again during your studies and at ETH? And what is a Legi, and what can it do? We will explain all this to you in this section.",
            legipara2: "Feel free to click on the button to read through the section. When you're done, come back to this page and continue reading below.",
            legicaption: "An ETH Legi",
            legialt: "An ETH Legi",
            legibutton: "Your ETH Identity",
            passworttitle: "Set / Change passwords",
            passwortpara: "Now you know what your ETH identity is. But now you probably have urgent questions, such as: \"How do I log into the Wi-Fi?\", \"How can I get access to the lecture websites?\". Don't worry, these questions will be answered soon. But at ETH many sites are not public, and you have to log in for them. At least you now have your identity to log in with, but you haven't set any passwords yet. This is the next step you need to take. So in this section we will show you how to set/change your passwords.",
            passwortbutton: "Set / Change passwords",
            wlantitle: "Wi-Fi",
            wlanpara: "Now you have the basics for logging into sites down. So here's probably the most important thing: How can you log into the Wi-Fi? Also: What is a VPN? And how do I log into the ETH VPN?",
            wlanbutton: "How do I log into the ETH Wi-Fi?",
            mailtitle: "ETH Mailbox",
            mailpara: "The most important thing, namely logging into the Wi-Fi, has now been clarified. Up to now, ETH has probably contacted you mainly with letters and via your personal e-mail. In the future, however, ETH will direct all communication to your personal mailbox. You will even get your own ETH e-mail address. This will be used for all ETH administrative matters such as enrolments and bookings as well as for all information from lecturers about your lectures and other events. How to log in to your mailbox, how to integrate it into your mail program or how to forward it, you can read all about it here.",
            mailbutton: "ETH Mailbox",
            lehrtitle: "Courses",
            lehrpara: "With this, you have learned all the basics for ETH and can now fully start your actual studies! From the second day of the semester, you will of course be fully immersed in lectures, exercises and labs. Generally, self-organisation is required at ETH. This includes checking the lecture websites regularly and always checking your e-mails. Don't worry, the lecturers will mention all of this again in their lectures. But it is worth to have a look at the websites. We explain where you can find them and how to find your way around ETH here.",
            lehrcaption: "A typical lecture at ETH",
            lehralt: "A picture of a typical full lecture hall at ETH",
            lehrbutton: "Courses",
            essentitle: "Food",
            essenpara: "So, all this reading must have made you pretty hungry. Actually, there are many places at ETH and around ETH where you can eat. You can eat in one of the many dining halls. If you like to cook or want to learn how to cook, there are some microwaves where you can heat up your food. And if you just want a snack, you have some options for that as well. We have tried to list all the available options for you on the following page.",
            essencaption: "Eating in a cafeteria",
            essenalt: "Students eating in a cafeteria",
            essenbutton: "Food",
            studierentitle: "What do you need for studying?",
            studierenpara: "In school or in your old Bachelor's program, you probably had to buy some books and maybe even laptops. At ETH, you mainly work with lecture websites and scripts, but, depending on your study programme, also with books. You will also need to print out some of the scripts and lecture notes. You will also need a laptop. You can find out how to do all this here.",
            studierenbutton: "Studying at ETH",
            lernplaetzetitle: "Study spaces at ETH",
            lernplaetzepara: "You now have enough knowledge to survive your first days at ETH. But now your lectures, exercises and labs are coming up. You will receive a lot of information and you can slowly get adjusted to the daily routine of studying. Now the series of exercises that you have to solve and the lectures that you have to prepare and follow up are coming. For this, you need a quiet place where you can concentrate and have space. Some of them even have power sockets. ETH has a few study spaces where you can study in peace and quiet. You can find them here.",
            lernplaetzebutton: "Study spaces ETH",
            mobilitaettitle: "Mobility at ETH",
            mobilitaetpara: "Of course, another aspect of your studies and life in Zurich is mobility. How do you get from one campus to another? How can you get around in and around Zurich? You can walk certain distances, of course, but Zurich offers many other ways to get around. These include bicycles, trams, buses and trains. You can find out more details here.",
            mobilitaetcaption: "The ETH eLink",
            mobilitaetalt: "The ETH eLink, a fully electric bus.",
            mobilitaetbutton: "Mobility at ETH",
            einschreibungtitle: "Enrollment and course registrations",
            einschreibungpara: "If you are in the first year, you were automatically enrolled in a degree programme and the corresponding lectures of the first semester when you registered. However, you have to do this yourself from the second semester onwards. At ETH, you have to do three things every semester to ensure that you have really completed registration for your courses: Register for the semester, register for the subjects and register for the exams. We'll show you how to do these things here.",
            einschreibungbutton: "Enrollment and course registrations",
            sonstigestitle: "Other offers of ETH",
            sonstigespara: "With that, we are through with all vital information about ETH. However, ETH also has other offers and information of all kinds. You can find them here.",
            sonstigesbutton: "Other offers of ETH",
            freizeittitle: "Leisure activities",
            freizeitpara: "Now you have read a lot about ETH and how it works. But what can you do in your free time? Maybe you want to try a new sport, continue an old sport, play in an orchestra, or just see the city of Zurich? Where can you meet new people? How do you get to know your new surroundings? Don't worry, the VSETH and the city of Zurich have lots of offers for you!",
            freizeitbutton: "Leisure activities on and around campus",
            wohnentitle: "Housing and financial matters",
            wohnenpara: "Housing in Zurich is expensive and rare. If you haven't found a flat or shared flat yet, we'll give you some tips here. If you are worried about your financial situation, we also have tips here for financial support.",
            wohnenbutton: "Housing and financial matters",
            unterstuetzungtitle: "Support",
            unterstuetzungpara: "In general: If you don't understand something or don't know something: Go to your fellow students or your study association, ask them, talk to them, write them an email, you will surely get an answer. All older students have been in the same situation as you and are willing to help you. We have put together a website for you with further support options.",
            unterstuetzungbutton: "Support",
        },
        LegiPage: {
            title: "Your ETH identity",
            para: "Your your identity at ETH Zürich there are exactly three things you need to memorize:",
            list1: "Your student ID / Legi",
            list2: "Your matriculation number oder Legi number",
            list3: "Your ETH user name",
            title1: "Your Legi",
            para1: "Your Legi is your key to all the student benefits you receive at ETH. You should have received your student ID / Legi by post and always have it with you. With it, you can get food at a lower student price in almost all cafeterias (yes, even in cafeterias at other universities), have access to certain buildings, borrow books from various libraries and have discounts all over the city. It also says whether you are a VSETH member and can therefore benefit from all the advantages we offer you!",
            caption: "An ETH Legi",
            alt: "An ETH Legi",
            title2: "Your matriculation number oder Legi number",
            para2: "Your matriculation number, sometimes also called Legi number, is a number that uniquely identifies you at ETH. You will need it especially for exams to identify yourself. The matriculation number is made up of the year of your first matriculation and six additional random digits. It has the format 24-XXX-XXX.",
            title3: "Your ETH user name",
            para3_1: "You should have received your ETH user name in a document before the semester. It is a combination of letters from your first and last name and will accompany you throughout your time at ETH. On all websites and services where you have to log in for ETH, you use the ETH user name for identification. Your e-mail address is also based on the abbreviation:",
            para3_2: "ETH user name",
        },
        PasswortPage: {
            title: "How do I set or change my passwords?",
            para1: "Everything pertaining to passwords happens on the site ",
            link1_1: "https://www.password.ethz.ch/authentication/login_en.html",
            link1_2: "password.ethz.ch",
            button: "password.ethz.ch",
            caption1: "The homepage of password.ethz.ch (screenshot in German, but the page looks very similar in English)",
            alt1: "The homepage of password.ethz.ch",
            para2: "In an email/letter you received before you started your studies, you will find both your ETH user name and a password. Log in with these.",
            para3: "If you have already changed your password once and want to change it again, simply log in with ETH user name and your set LDAPS password.",
            caption2: "The site \"Change password\" under the register \"Self Service\" (screenshot in German, but the page looks very similar in English)",
            alt2: "The site \"Change password\" under the register \"Self Service\"",
            para4: "Then, on the top left, click on the site \"Change password\" under the register \"Self Service\".",
            caption3: "The page \"Change password \". (screenshot in German, but the page looks very similar in English)",
            alt3: "The page \"Change password\"",
            para5: "Now you can set passwords for the following services:",
            list1: "This is the one you will use most often. You will use this whenever you need to log in to ETH sites with switchAAI or Moodle.",
            list2: "You use this mainly for your mailbox, but also for other protected services such as SharePoint or printing.",
            list3: "You use this for Wi-Fi and VPN.",
            para6: "For maximum security set a different passwort for each service.",
        },
        WLANPage: {
            title: "Wi-Fi and VPN",
            wlantitle: "How do I log into the ETH Wi-Fi?",
            wlanpara1_1: "Select the Wi-Fi network ",
            wlanpara1_2: " or ",
            wlanpara1_3: " on your device. Then, you will be asked for an user name and a password.",
            wlanlist1_1: "Username:",
            wlanlist1_2: "Your ETH user name",
            wlanlist2_1: "Password:",
            wlanlist2_2: "Your set password \"Radius\"",
            wlanpara2: "If you don't know how to set the password \"Radius\", check the site \"How do I set or change my passwords?\":",
            wlanbutton: "Set / Change passwords",
            wlanpara3: " Many universities around the world also use eduroam, which you can also log into with the credentials mentioned above. Therefore, if you visit a university somewhere in the world, you may well be able to make use of free and fast Wi-Fi there.",
            vpntitle: "VPN",
            vpnpara1_1: "The abbreviation ",
            vpnpara1_2: " stands for ",
            vpnpara1_3: ". There are a few services at ETH that can only be accessed from the ETH network (i.e. only if you are connected to eduroam). These include:",
            vpnlist1: "The webprint page where you upload documents to print. More details on printing will come later.",
            vpnlist2: "Possibily some lecture websites or websites where you have to upload documents.",
            vpnpara2: "In order to be able to access these services from home, you need to install a client with which you can access the ETH VPN. To do this, click on the tutorial below.",
            vpnlink: "https://unlimited.ethz.ch/display/itkb/VPN",
            vpnbutton: "Tutorial for VPN"
        },
        MailPage: {
            title: "ETH mailbox",
            adressetitle: "The mail address",
            adressepara1: "ETH Zürich provides you with your own mailbox for all communication with and from ETH Zurich and the lecturers. This is where all the important things for your studies are sent, for example:",
            adresselist1: "our VSETH newsletter",
            adresselist2: "information about lectures",
            adresselist3: "information about enrollment in exercise lessons",
            adresselist4: "your semester bill (!)",
            adresselist5: "the confirmation of matriculation",
            adresselist6: "everything that's important at ETH",
            adressepara2: "So check it regularly!",
            adressepara3_1: "The ETH mail address that you get from ETH is ",
            adressepara3_2: "ETH user name",
            adressepara3_3: ". The same mail address will be reached by ",
            adressepara3_4: "ETH user name",
            mailboxtitle: "How do I access the ETH mailbox?",
            mailboxpara1: "You can access the mailbox via webmail on",
            mailboxlink1_1: "https://outlook.office.com",
            mailboxlink1_2: "outlook.office.com",
            mailboxbutton1: "Webmail",
            mailboxpara2: "You can also integrate your mailbox into your mail program, e.g. Outlook, Apple Mail, Thunderbird etc. Tutorials can be found on the following page (click on \"Mailclient\" in the table of contents):",
            mailboxlink2: "https://unlimited.ethz.ch/display/itkb/Mailbox",
            mailboxbutton2: "Tutorials for mail clients",
            weiterleitungtitle: "Can I also forward my mails to another mail address?",
            weiterleitungpara1: "Yes, that's possible. Go to the site ",
            weiterleitunglink1_1: "https://password.ethz.ch",
            weiterleitunglink1_2: "password.ethz.ch",
            weiterleitungbutton: "password.ethz.ch",
            weiterleitungpara2: "Then, on the top left, click on the site \"Manage forward\" under the register \"Self Service\".",
            weiterleitungcaption1: "The site \"Change password\" under the register \"Self Service\". (screenshot in German, but the page looks very similar in English)",
            weiterleitungalt1: "The site \"Change password\" under the register \"Self Service\"",
            weiterleitungpara3: "There, you can set up a forward to another mail address.",
        },
        LehrveranstaltungenPage: {
            title: "Courses",
            para1_1: "Your schedule and course materials can be found on ",
            para1_2: ". This is also the place where you do all administrative tasks pertaining to your studies. Go to the website:",
            link: "https://www.lehrbetrieb.ethz.ch/myStudies/login.view?lang=en",
            button: "myStudies",
            para2: "Click on the button \"Start\".",
            para3: "Then you get a login screen. Log in with:",
            list1: "ETH user name:",
            list2_1: "ETH password for web applications (LDAP):",
            list2_2: "Your password (LDAPS)",
            caption1: "The button \"Start\" on the myStudies homepage (screenshot in German, but the page looks very similar in English).",
            alt1: "Der Knopf \"Start\" auf der myStudies-Startseite.",
            para4: "Now you get to the homepage of myStudies.",
            caption2: "The homepage of myStudies(screenshot in German, but the page looks very similar in English)",
            alt2: "The homepage of myStudies",
            para5: "At the top you have a category called \"Courses\". Here you can find everything about the courses of your current semester.",
            stundenplantitle: "Schedule",
            stundenplanpara1: "Here you will find a tabular form of your schedule. Each course is marked with the following data:",
            stundenplanlist1: "Type of course:",
            stundenplanlist1v: "stands for lecture",
            stundenplanlist1u: "stands for exercise",
            stundenplanlist1p: "stands for practical/laboratory course",
            stundenplanlist1s: "stands for seminar",
            stundenplanlist1g: "stands for lecture with exercise",
            stundenplanlist2: "Course name",
            stundenplanlist3: "Lecturer name",
            stundenplanlist4: "Location",
            stundenplanpara2: "The most important point here is the location of the event. ETH uses a special system to name its rooms and buildings.",
            stundenplanbutton: "Orientation at ETH Zürich",
            stundenplanpara3: "If you switch to \"List\", you can also remove courses from the tabular view. This is especially useful if you have overlapping exercise lessons or similar where you only have to select one.",
            lernmaterialientitle: "Learning materials",
            lernmaterialienpara: "Here you will find all the learning materials for the lectures that the lecturers publish. It's best to bookmark the individual pages so that you can find them again quickly. If you have already studied for several semesters, you can also change the semesters here.",
            eduapptitle: "EduApp",
            eduapppara_1: "There is a very useful app for your smartphone with the name ",
            eduapppara_2: ". It's an official ETH app, where you can also see your timetable again, find a campus map and answer clicker questions that might be asked during lectures. Make sure to download it!",
            freiwilligetitle: "Voluntary contributions",
            freiwilligepara: "At the bottom of the screen there is another tab called \"Voluntary Contributions\". When you open it, you will find three different voluntary contributions that you can pay. Make sure you have selected VSETH. This will make you a VSETH member and automatically a member of your student association and you will be able to participate in many events and offers of VSETH and your student association.",
            freiwilligecaption: "The membership fee for VSETH in the voluntary contributions",
            freiwilligealt: "The membership fee for VSETH in the voluntary contributions",
        },
        OrientierungPage: {
            title: "Orientation at ETH Zürich",
            campustitle: "Campus locations",
            campuspara1_1: "ETH Zürich has two main campuses: The ",
            campuspara1_2: " and the ",
            campuscaptionzentrum: "The campus ETH Zentrum, with the main building (HG) in the center",
            campusaltzentrum: "An aerial view of ETH Zentrum",
            campuscaptionhoenggerberg: "The campus ETH Hönggerberg",
            campusalthoenggerberg: "An aerial view of ETH Hönggerberg",
            campuscaptionlink: "The ETH eLink",
            campusaltlink: "The ETH eLink, a fully electric Bus",
            campuspara2_1: "Both are connected by a shuttle bus, the ",
            campuspara2_2: ", that departs every 20 minutes and gets you from one campus to another in approximately 15 minutes. A timetable can be found in the EduApp. Alternatively, you can also change campus in about 20 minutes by trams and buses.",
            gebaeudetitle: "Naming of the buildings",
            gebaeudepara1: "Each building at ETH is uniquely named by a two- or three-digit letter combination.",
            gebaeudepara2_1: "Important:",
            gebaeudepara2_2: " If you have to go to a building which has three letters and begins with an ",
            gebaeudepara2_3: ", you almost surely have to go to the Hönggerberg campus. There are exceptions, but normally there are no lectures in those.",
            raeumetitle: "Naming of the rooms",
            raeumepara1_1: "Each room is named with a letter and two to four numbers. The letter designates the floor and the numbers the room. As a rule, the ground floor is the floor ",
            raeumepara1_2: " and if you go to the upper floors, you go to the floors ",
            raeumepara1_3: " , and if you go to the lower floors, you go to the floors ",
            raeumepara1_4: " Caution: Some buildings have exceptions.",
            raeumepara2_1: "So, let's for example take the room ",
            raeumepara2_2: ". The ",
            raeumepara2_3: " is, according to the remark above, a building on the Hönggerberg campus. The letter ",
            raeumepara2_4: " comes after ",
            raeumepara2_5: ", hence the room is situated in one of the upper floors - in this case, even in the top floor. And the ",
            raeumepara2_6: " is the room number.",
            eduapptitle: "EduApp",
            eduapppara_1: "Important:",
            eduapppara_2: " To make it much easier for you to find your way around the room, the EduApp contains a ",
            eduapppara_3: ". There you can simply enter your room number and it will show you on the map where that room is - even with a live location. Be sure to download it.",
        },
        EssenPage: {
            title: "Food",
            para: "You have several options for food. On the right you will find the official ETH gastronomy page, but we have also compiled a list ourselves below.",
            link: "https://ethz.ch/en/campus/getting-to-know/cafes-restaurants-shops/gastronomy.html",
            button: "Official gastronomy page of ETH",
            mensentitle: "Cafeterias",
            mensenpara: "You can eat in one of the cafeterias. Click on the button for a list of them.",
            mensenbutton: "List of cafeterias",
            mikrowellentitle: "Microwaves",
            mikrowellenpara: "You can also cook something at home and take it with you. There are several places around ETH where you can find microwaves. However, it is best to take your own cutlery with you. Unfortunately, there is no official list, but here are a few places we know of:",
            mikrowellenlist1: "In the Einstein cafe in Zentrum",
            mikrowellenlist2: "In CAB E 33 (Common room)",
            mikrowellenlist3: "In the Food / Lab cafeteria in CAB",
            mikrowellenlist4: "In the food market on Hönggerberg",
            mikrowellenlist5: "In the Fusion cafeteria on Hönggerberg",
            truckstitle: "Food Trucks on Campus",
            truckspara1: "Both on the Hönggerberg campus and in Zentrum there are various food trucks where you can get something to eat over lunch. The food trucks are usually more expensive than a menu in a canteen. The trucks are always on site on the same day of the week for each semester.",
            truckspara2: "The Äss Bar truck deserves special mention. There you can get bakery items from the day before at reduced prices. The Äss Bar is at each campus twice a week.",
            truckslink: "https://ethz.ch/en/campus/getting-to-know/cafes-restaurants-shops/gastronomy/street-food.html",
            trucksbutton: "List of the current Food Truck offers",
            sonstigestitle: "Other offers",
            sonstigespara: "There are several other options near the campuses, especially in the city centre, outside of the dining halls or food trucks. These usually don't have student prices, but they still offer a good alternative. Just take a look around and discover the offers for yourself!",
        },
        MensenPage: {
            title: "Cafeterias",
            para: "Around ETH there are quite a few options where you can eat. But first a few general tips on where to eat:",
            list1: "Don't forget to bring your Legi with you!",
            list2: "Most cafeterias are quite full between 12:00 and 13:00. If you want to avoid long lines, try to eat before 11:45 or after 13:15.",
            list3: "There are a few ways to get an overview of what the cafeterias have to offer: There are several (unofficial) apps in various app stores that list offerings from various dining halls. Just search for \"Mensa Zürich\" or similar search terms and try them out.",
            list4: "If you use Telegram, then you can subscribe to a Mensabot: Open the search box and type @zurimensen_bot.",
            zentrumtitle: "Zentrum",
            polymensatitle: "Polymensa",
            polymensapara1: "The Polymensa (officially Mensa Polyterrasse) is a very large canteen and usually has plenty of space. It is also referred to as \"the main restaurant\" by the Mensa operating company. There are four menu categories in the semester: Home, Garden, Street and Local. The Home and Garden are the cheaper options and Street and Local are slightly more expensive. Either Garden or Street should usually be vegan. So it doesn't always have a \"cheap menu\" as a vegan choice. In addition to the four menus, there is also a - rather smaller - salad buffet where you pay by weight. You can get seconds of the Garden and Home menus.",
            polymensapara2: "Polymensa also has an evening menu.",
            foodlabtitle: "Food & Lab",
            foodlabpara: "The food&lab (although most students don't pronounce the &) is somewhat hidden on the CAB H floor. It is rather small, but since not too many people come, there are few queues. There are two menus and salads. The menus are slightly more expensive on average than at Polymensa.",
            clausiustitle: "Clausiusbar",
            clausiuspara: "The Clausius Mensa specialises in lunch menus from different cuisines in Asia. It is slightly more expensive than, for example, Polymensa, but offers a different selection. There are two categories of menus: the cheaper scooped menus and the wok menus, which are prepared to order. There is one vegetarian or vegan dish per category and one with meat. Gluten-free is very well possible here and this canteen is also generally good for allergies.",
            tannenbartitle: "Tannenbar",
            tannenbarpara: "In the Tannenbar you will find coffee and drinks, sandwiches and soups, snacks and pastries, and at lunchtime one veggie and one meat dish each. These tend to be slightly more expensive.",
            archimedestitle: "Archimedes",
            archimedespara: "Archimedes is a brand new restaurant opening in the GLC this semester! So we don't really have any information for you yet, but we are excited about the new canteen.",
            polysnacktitle: "Polysnack",
            polysnackpara: "Small cafeteria in the main building (F floor). There is not too much space here, but since not many people come, it still works out well. The menus are slightly more expensive than in many other canteens, but they are prepared individually. There is always a pizza, a pasta dish and a salad dish. There are also sandwiches, coffee and drinks. There are days when there is little vegetarian choice, and it is rarely vegan-friendly.",
            zweisteintitle: "Zweistein",
            zweisteinpara: "This cafeteria offers baked goods, cakes and sandwiches as well as plenty of space.",
            zweigradbistrotitle: "Zwei Grad Bistro",
            zweigradbistropara: "This café is located at the Grüner Boden in CHN. In addition to coffee and drinks, they also serve sandwiches and pastries.",
            unimensatitle: "Uni Mensa (lower and upper)",
            unimensapara: "The Uni Mensa is next to the main building of the UZH and therefore slightly further away. It consists of two parts: the lower canteen and the upper canteen. Both have a variety of menus, and there is certainly always a veggie option and a meat option. The menus offered differ in part between the upper and lower canteens, but there is also overlap. Both also have a buffet, where you pay by weight. The Uni Mensa also has an evening menu.",
            raemi59title: "Rämi 59 (vegan)",
            raemi59para: "This rather small and completely vegan canteen is a bit further away, but it's well worth it. It can be reached from the ETH buildings in the Zentrum over lunch. It always has two menus, you can get seconds and everything is vegan.",
            plattetitle: "Platte",
            plattepara: "A little further away is the Platte Mensa at UZH. There are two menus and sandwiches. One of the menus is always vegan and the second vegan or with meat. You can get second helpings at this canteen.",
            hoenggtitle: "Hönggerberg",
            fusionmealtitle: "Fusion Meal",
            fusionmealpara: "The Fusion Meal is located in the HCI on the F floor, directly above Fusion Coffee. There are three menus per day and a free-choice buffet. The daily menus include at least one veggie, vegan and meat menu. The free-choice buffet has a large salad bar and a large selection of hot dishes. You can combine both buffets and then pay by weight at the register.",
            foodmarkettitle: "Food Market",
            foodmarketpara: "You can reach the Food Market through the HPH. The lunch offer is divided into three sections: Grill & BBQ, Pizza & pPasta and Green Day. You queue up at the section, get a menu and pay directly there. The Food Market also has an evening offer from 17.30 to 19.15.",
            foodmarketgrilltitle: "Grill & BBQ",
            foodmarketgrillpara: "Here you can find meat and sometimes fish, one cheaper (Fire) and one more expensive menu (Grill). Recently, you can sometimes find meat alternatives like Planted.",
            foodmarketpizzatitle: "Pizza & Pasta",
            foodmarketpizzapara: "Here, as the name suggests, you can find pizza and pasta. With the pizzas there is always Margherita and a more special one. For pasta, there are also two offers (one cheaper and one more expensive one). The expensive is more unusual but even the cheaper one is not only basic pasta with tomato sauce but even Älplermaggronen or Thai noodles.",
            foodmarketgreentitle: "Green Day",
            foodmarketgreenpara: "This is not a punk band from the 90s. The Green Day counter offers a veggie/vegan daily menu and a salad counter where you can pick and choose 5 different items. Small insider tip: If you don't like the side dish of the daily menu, you can ask to exchange it with one of the salads of the buffet.",
            riceuptitle: "Rice Up!",
            riceuppara: "Rice Up! is a restaurant chain and also has a branch on the Hönggerberg. Since it is on campus, this branch also has a student discount and an employee discount. Here you can put together your own bowl. You choose a base (two kinds of rice, rice noodles or salad), a protein, a vegetable, a sauce and toppings. You can also ask for \"a little more\" for the base. The prices then depend on the selected protein. The food is filling, the quality is consistent and one knows what one will be getting.",
            alumniloungetitle: "Alumni Lounge",
            alumniloungepara: "The Alumni Lounge offers several menus in its Pots, both over lunch and in the evening. Otherwise, the Alumni Lounge is a popular place to have a drink or play a game of billiards in the evening at Höngg. You can also get coffee or something else throughout the day and just relax for a while. There are also events that take place there in the evening from time to time.",
            fusioncoffeetitle: "Fusion Coffee",
            fusioncoffeepara1: "Fusion Coffee is located on the ground floor of the HCI. At lunchtime there is a counter with curry dishes and one with sandwiches and bowls. The counters have reinvented themselves from time to time in recent years and perhaps the offer will soon look a little different again. From early in the morning until around 16:00, there is also inexpensive coffee, pastries and desserts.",
            fusioncoffeepara2: "There is also a small Coop to go branch where you can find sandwiches, salads, snacks and drinks at supermarket prices. However, they tend to offer the more expensive types of sandwiches than the Coop supermarket next door.",
            hpibistrotitle: "HPI Bistro",
            hpibistropara: "A small café that serves a choice of two types of ramen over lunch from Tuesday to Thursday. One of them is vegetarian. The ramen is a little more expensive than many other lunch menus in cafeterias.",
        },
        StudierenPage: {
            title: "What do you need for studying?",
            laptoptitle: "Do you need a laptop?",
            laptoppara1_1: "Yes",
            laptoppara1_2: ", from autumn semester 2023 there is a ",
            laptoppara1_3: "strong recommendation",
            laptoppara1_4: " for bringing a technical device. Information on the regulations and where to get equipment can be found here:",
            laptoplink1: "https://ethz.ch/en/studies/bachelor/beginning-your-studies/BYOD.html",
            laptopbutton1: "Bring Your Own Device (BYOD) at ETH",
            laptoppara2_1: "If you want to look around for cheap laptops or iPads in general, then ",
            laptoppara2_2: " is a good place. They offer good equipment at student prices and have an exhibition at the beginning of each semester where you can buy equipment on the spot.",
            laptoplink2: "https://projektneptun.ch/en/",
            laptopbutton2: "Projekt Neptun",
            buechertitle: "Which books should I buy?",
            buecherpara1_1: "Generally, this depends on your degree programme. However, we would generally recommend: ",
            buecherpara1_2: "Only buy books if they are explicitly compulsory for the lecture.",
            buecherpara1_3: " Most of the time, the lecture materials are offered directly on the lecture website.",
            buecherpara2_1: "With your ETH account you have full access to ",
            buecherpara2_2: ". They are a book publisher, and on their website you can download entire books as PDFs for free. Depending on your course of study, you will find many books from the lecture there. So search SpringerLink first before you buy a book.",
            buecherpara3: "If you need to buy a book or want to buy a book, VSETH offers you a book exchange. There you can check if one of the books you want to buy is available.",
            materialtitle: "Where can I buy office supplies and books?",
            materialpara1_1: "At several locations directly on campus, the ",
            materialpara1_2: " supplies the entire ETH community. In the ETH Store you can cover your needs for study materials, recommended books and articles with ETH and VSETH logos.",
            materialpara2: "In the ETH store you find:",
            materiallist1: "various textbooks and non-fiction books, illustrated books, fiction",
            materiallist2: "stationery, Backpacks, Accessories",
            materiallist3: "model making materials, artists' supplies",
            materiallist4: "lab coats",
            materiallist5: "merchandise",
            materiallist6: "a self-service corner for binding scripts and portfolios",
            materiallist7: "a multi-function copier with which you can print on special paper from the memory stick",
            materiallist8: "and a lot more!",
            materialpara3: "Students benefit from special offers and discounts!",
            druckentitle: "How and where can I print at ETH?",
            druckenpara: "During your studies, you will receive many documents digitally, depending on the degree programme. Often you will want to print these out yourself, especially longer lecture notes. ETH has several printers for this purpose.",
            druckenbutton: "How and where can I print at ETH?",
        },
        DruckenPage: {
            title: "Printing",
            wiewotitle: "How and where can I print?",
            wiewopara1: "To print something, you must first upload it to the ETH webprint platform. For instructions on how to do this, see:",
            wiewolink1: "https://unlimited.ethz.ch/display/itkb/Webprint+for+students",
            wiewobutton1: "Printing tutorial",
            wiewopara2: "Now all you have to do is go to a printer and print there. To find a printer, search for the building name of the building you are in on this link:",
            wiewolink2: "https://ethz.ch/staffnet/en/it-services/catalogue/printing/self-service/printer-locations.html",
            wiewobutton2: "How and where can I print?",
            wiewopara3: "Now go to the printer and hold your Legi up to the beacon. Then you have to set a PIN for printing. Remember this PIN, you will need it every time you print! Now select \"Print Release\", choose your order and tap \"Print\". Voilà, your stuff is printed.",
            spezielletitle: "Special printing jobs",
            speziellepara_1: "If you want to do more specialised print jobs on thicker paper, special paper, etc., you can do this via ",
            speziellepara_2: " make. The self-service webshop allows you to print posters or CAD plans on site. Simply upload your PDF document to the webshop and then select the location and printer. Print+Publish has self-service rooms in the main building, at Hönggerberg and in Oerlikon.",
            speziellelink: "https://www.print-publish-sb.ethz.ch/",
            speziellebutton: "Print+Publish Self Service",
            kopierentitle: "Copying and scanning up to A3",
            kopierenpara: "To copy or scan, log in to one of the multifunctional devices with Legi or ETH Login and press \"Use device functions\". Then select whether you want to copy or scan your document. Important: The costs for copying are the same as for printing, while scanning and emailing are free of charge. If you want to scan a document larger than A3, go to the Print+Publish webshop. Here you will find help.",
            bindentitle: "Binding",
            bindenpara: "If you have printed out a script yourself, but don't want all the sheets of paper flying around in your bag, you can order your script bound right away in the web shop at Print+Publish. Alternatively, you can go to the ETH Store and bind your script or paper yourself.",
        },
        LernplaetzePage: {
            title: "Studying spaces",
            uebersichttitle: "Overview of the studying spaces",
            uebersichtpara1: "In order to be able to study well, you need good studying spaces. There is a good overview of this at ETH:",
            uebersichtlink1: "https://ethz.ch/content/dam/ethz/common/docs/gmis/arbeitsplaetze-studierende.pdf",
            uebersichtbutton1: "Overview of the studying spaces (only in German)",
            uebersichtpara2: "The list is from 2018, so some information may be out of date. If you want an up-to-date overview, go to the following page and click on the link in the middle of the page at \"List of learning environments\".",
            uebersichtlink2: "https://ethz.ch/students/en/campus/workplaces-for-students.html",
            uebersichtbutton2: "Site for studying spaces",
            uebersichtpara3_1: "In the ",
            uebersichtpara3_2: " you will also find the above information and have it quickly at hand under \"Study Environments\".",
            zugrifftitle: "Access to buildings",
            zugriffpara1_1: "In general, you can go into all ETH buildings during opening hours. Outside of these, however, they are not open to the public. With your ",
            zugriffpara1_2: " However, you have access to some buildings outside opening hours and can use the study rooms in them, especially at weekends and during the study phase. These buildings include the ",
            zugriffpara1_3: " in the Zentrum campus as well as the ",
            zugriffpara1_4: " on the Hönggerberg campus. Depending on your degree programme, you may also have access to other buildings.",
            zugriffpara2: "To get into a building outside opening hours, you have to hold your Legi up to such a device and enter a code defined by you.",
            zugriffcaption1: "A card reader for your Legi.",
            zugriffalt1: "A card reader for your Legi.",
            zugriffpara3: "You still have to define this code. Go to the site ",
            zugrifflink1_1: "https://www.bi.id.ethz.ch/eAdressen/loginPre.do;lang=en",
            zugrifflink1_2: "adressen.ethz.ch",
            zugriffbutton: "adressen.ethz.ch",
            zugriffpara4: "Log in there. Then scroll down to the section \"ETH-card\". There you click on \"Change admission PIN-code\". There you can set a PIN with which you can open the doors as described above.",
            zugriffcaption2: "The section \"ETH-card\" where you can set/change the PIN. (screenshot in German, but the page looks very similar in English)",
            zugriffalt2: "The section \"ETH-card\", where you can set/change the PIN.",
        },
        MobilitaetPage: {
            title: "Mobility at ETH Zürich",
            campustitle: "From campus to campus",
            campuspara_1: "Both the Zentrum and Hönggerberg campuses are connected by the ",
            campuspara_2: ". The ETH eLink is a shuttle bus connection between the two locations with an intermediate stop at Haldenegg. It is free of charge for ETH members. The bus stop in the centre is somewhat hidden under the Polyterrasse. On the Hönggerberg, the bus leaves to the right of the large brown building (HIL) - at the information board about the solar plant. It runs four times an hour during the semester, and three times during the semester break. The first connections in the morning (from 07.06 hrs) and the last connections in the evening (until 18.54 hrs) run from and to Zurich main station to connect with train services. You can find the timetable on the ETH website or in the Edu app.",
            campuscaption: "The ETH eLink",
            campusalt: "The ETH eLink, a fully electric Bus",
            fahrraedertitle: "Bicicles",
            fahrraederpara_1: "A good and sporty way to get around Zurich is by velo (that's what bikes are called here). There are some city bike offers like ",
            fahrraederpara_2: ", where you simply have to download an app and then rent bikes at any time with a subscription and ride from charging station to charging station. Alternatively, you can also buy a bike. The association ",
            fahrraederpara_3: " organises a bike exchange on Polyterasse, where you can buy a bike at a reasonable price. VELOVE also has a bike workshop on the Hönggerberg where you can repair your bike yourself.",
            fahrraederlink1: "https://velovezh.ch",
            fahrraederbutton1: "VELOVE website",
            fahrraederlink2: "https://www.publibike.ch/en/home",
            fahrraederbutton2: "PubliBike website",
            oevtitle: "Public transport",
            oevpara1_1: "But Zurich also has excellent public transport. The trams, buses and trains in Zurich are operated by the ",
            oevpara1_2: ". Depending on the situation, it might be worthwhile for you to take out a subscription, the ",
            oevpara1_3: ". There is also a discount for you if you are under 25. See their website.",
            oevlink1: "https://www.zvv.ch/zvv/en/travelcards-and-tickets/travelcards/networkpass.html",
            oevbutton1: "ZVV website: NetzPass",
            oevpara2_1: "If you want to explore Switzerland outside of Zurich, the ",
            oevpara2_2: "Swiss Federal Railways (SBB)",
            oevpara2_3: " has a number of offers. Explicitly mentioned should be the ",
            oevpara2_4: ". This gives you free travel from 7-5pm at night - for only 99.00 francs per year. A list of tickets and subscriptions can also be found on their website.",
            oevlink2: "https://www.sbb.ch/en/travelcards-and-tickets.html",
            oevbutton2: "SBB website: Travelcards and tickets",
            oevcaption: "By train, you can get anywhere in Switzerland",
            oevalt: "A train in Switzerland",
        },
        EinschreibungPage: {
            title: "Enrollment and registration for courses",
            para_1: "Caution:",
            para_2: " If you are in the first semester of the first year, you are automatically enrolled in the semester and in your courses. However, from the 2nd semester onwards, you have to do this yourself.",
            vvztitle: "The VVZ",
            vvzpara_1: "If you want to check what courses you want to take next semester, you can look it up in the ",
            vvzpara_2: "Course Catalogue (VVZ)",
            vvzpara_3: " on ",
            vvzlink1_1: "https://www.vvz.ethz.ch/Vorlesungsverzeichnis/sucheLehrangebotPre.view?lang=en",
            vvzlink1_2: "vvz.ethz.ch",
            vvzpara_4: "",
            vvzbutton: "VVZ",
            einschreibungtitle: "Enrolling into a semester",
            einschreibungpara1_1: "The administration of the enrolment and the booking of the courses happens via ",
            einschreibungpara1_2: ". So log in there. If you don't know how to do this, click on the button and read the first part of the article.",
            einschreibungbutton1: "How do I log into myStudies?",
            einschreibungpara2_1: "Important:",
            einschreibungpara2_2: " You must enrol in this semester at the beginning of each semester. Click on \"Functions\" in the middle of the page next to your semester. You can now enrol for the semester there.",
            einschreibungcaption: "The button \"Functions\" on myStudies (screenshot in German, but the page looks very similar in English)",
            einschreibungalt: "Der Knopf \"Funktionen\" auf myStudies.",
            einschreibungpara3: "You have to have your Legi validated after semester enrolment. There are special machines for this purpose all over campus. See the list of them.",
            einschreibunglink: "https://ethz.ch/staffnet/en/service/eth-card/validity-period-validation/validation-terminal-locations.html",
            einschreibungbutton2: "List of validation terminals",
            belegungtitle: "Course registrations",
            belegungpara: "Now you are enrolled and can take courses. You have probably already looked up in advance in VVZ which lectures you want to take. To do this, click on \"Course registrations\" on the right and then on \"Add learning units\". You will now see the same search mask as in the VVZ and can enrol for courses. Here you can also drop lectures.",
            pruefungentitle: "Exams",
            pruefungenpara_1: "Important:",
            pruefungenpara_2: " When you are enrolled in a course, you are not automatically enrolled in the exam, you have to do this manually for each course. During the semester you will receive a reminder that you can enrol for exams. This happens again via myStudies, at \"Functions\" under \"Examinations\". There you can register for exams and also deregister.",
        },
        SonstigesPage: {
            title: "Other offers of ETH",
            ittitle: "IT services",
            itpara_1: "The IT services provide a whole arsenal of services, from your e-mail box to a free \"Dropbox clone\" called ",
            itpara_2: " to many free software licences. Here you will find the most important information about the IT office and other IT services:",
            polyboxtitle: "polybox",
            polyboxlink: "http://polybox.ethz.ch",
            polyboxbutton: "polybox",
            polyboxpara1: "polybox works like Dropbox, except that it is an ETH-owned, secure product where the data is stored exclusively on servers of ETH Zurich's IT Services. Collaborative work such as sharing with (external) guests is also possible. The polybox is available to every student with 50 GB of free storage space.",
            polyboxpara2: "There is also an app for polybox so that you can access your data anytime and anywhere.",
            personensuchetitle: "People search",
            personensuchelink: "https://www.bi.id.ethz.ch/personensuche/personenFormular.view?lang=en",
            personensuchebutton: "People search",
            personensuchepara: "If you need the e-mail address, telephone number or office address of an ETH member, you can search for them in this internal address book. To be able to search for students, you have to log in at the top right under \"Login\".",
            apptitle: "ETH Zürich app",
            apppara: "This ETH app offers news and events as well as building plans and maps of the campus. In addition, this app offers an overview of all ETH cafeterias and their daily offerings.",
            computertitle: "Computer classrooms",
            computerlink: "https://ethz.ch/content/dam/ethz/associates/services/Service/IT-Services/files/catalogue/workplace/computerraeume-en.pdf",
            computerbutton: "Computer classrooms",
            computerpara: "You can find a list of all supervised computer rooms on the IT Services website. There are computer workstations in all libraries and many unattended computer rooms. You will find the operating systems Windows and Linux (Fedora) installed on the computers at ETH.",
            supporttitle: "Computer Support",
            supportlink: "https://ethz.ch/staffnet/en/it-services/service-desk.html",
            supportbutton: "Service Desk",
            supportpara1_1: "If you have a problem with your computer and don't know what to do, you can contact the Service Desk. They are sure to be able to help you, because the competent people have probably seen every problem before. Again, the service is free of charge for all students. You can get answers to your questions around the clock at the ",
            supportlink1: "https://ethz.ch/staffnet/en/it-services/service-desk/support.html",
            supportlink1text: "FAQ for students",
            supportpara1_2: " and with the chat bot.",
            supportpara2_1: "You may also want to attend one of the various IT courses specifically for students at ETH. The number of participants is limited, so make sure you register early with the ",
            supportlink2: "https://ethz.ch/staffnet/en/it-services/catalogue/support-training/it-training/compicampus.html",
            supportlink2text: "Compicampus",
            supportpara2_2: ".",
            itshoptitle: "IT Shop",
            itshoplink: "https://ethz.ch/staffnet/en/it-services/catalogue/procurement/it-shop.html",
            itshopbutton: "IT Shop",
            itshoppara: "The IT Shop is the electronic ordering system of the IT Services for all ETH members. There you can purchase various software products (such as Microsoft Office), most of which are available to ETH students free of charge. You can log in there as usual with your ETH user data.",
            multimediatitle: "Multimedia equipment lending service",
            multimedialink: "https://ethz.ch/staffnet/en/it-services/catalogue/multimedia/loans/shop.html",
            multimediabutton: "Multimedia Shop",
            multimediapara: "ETH offers a lending service for multimedia equipment for your studies at both the Zentrum and Hönggerberg locations. For example, projectors, screens, active speakers, microphones and audio recorders can be borrowed. The equipment can be reserved via the webshop or by e-mail. All orders must be placed at least 48 hours in advance. The devices must be picked up or returned at the counter of the corresponding location. This service is free of charge for ETH students.",
            austauschtitle: "Exchange semesters",
            austauschlink: "https://ethz.ch/students/en/studies/study-abroad.html",
            austauschbutton: "Student Exchange Office",
            austauschpara1: "ETH students can spend one or two semesters or even a project at a partner university. The best thing is that ETH maintains partnerships in Switzerland, Europe and also worldwide. Even if the start of the semester is too early to deal with this topic, we would like to make all students aware of the versatile options - also because the preparation for the exchange can take up to a year. So, check out the website of the Mobility Office, plan your visit to the information market in autumn and start dreaming about your exchange!",
            austauschpara2: "If you want to do a semester abroad, it will work out in most cases - just inform yourself in good time! To get a taste of what it's like to study abroad or for inspiration, check out the website of the International Knowledge Base. In addition to all possible destinations of the various exchange programmes, you will also find reports on the experiences of students who have already been abroad.",
            sprachentitle: "Language Center",
            sprachenlink: "https://www.sprachenzentrum.uzh.ch/en.html",
            sprachenbutton: "Language Center",
            sprachenpara: "The joint Language Centre of the University of Zurich and ETH Zurich offers you language modules to accompany your studies. Some of these can be taken as compulsory electives of the D-GESS. The modules support your studies in German or English and prepare you for international contacts and study visits. The course programme includes courses in over 10 languages. In addition to the language modules, the Language Centre's self-study centre offers you various options for independent language learning, either as a supplement to a course or for individual autonomous learning. Click on the link above for the current course programme and online registration.",
            sphtitle: "ETH Student Project House",
            sphlink: "https://ethz.ch/en/the-eth-zurich/education/sph.html",
            sphbutton: "ETH Student Project House",
            sphpara1: "Here you can realise your own hobby or study projects. 3D printing, laser cutting or working with wood and textiles. Build apps, software or a robot. Everything is possible and there is always help. Become part of this lively and interdisciplinary community today. The unique and creative spaces are open to all ETH students who want to explore a specific topic or idea. SPH offers machine training, organises workshops and provides advice so you can develop ideas and build prototypes without pressure. Take the first step and drop by. They look forward to meeting you!",
            sphpara2: "Zentrum: ML/FHK, Clausiusstrasse, 8092 Zürich ",
            sphpara3: "Hönggerberg: HPZ, John-von-Neumann Weg 9, 8093 Zürich",
            campustitle: "Campus Info",
            campuslink: "https://ethz.ch/en/campus/getting-to-know/learning-and-working/campusinfo.html",
            campusbutton: "Campus Info",
            campuspara: "Campus Info is the first point of contact for all questions concerning ETH, whether you are looking for a lecture hall, the nearest cash machine or information about parking on the ETH campus. On the Hönggerberg there is also a counter of the Swiss Post, the car rental Europcar and an ETH cash desk.",
            auskunfttitle: "Information 11",
            auskunftpara: "General questions about ETH by phone? The staff at the switchboard will be happy to advise you or connect you further. Simply call: internal 111 / external +41 44 632 11 11.",
            bibtitle: "ETH Library",
            biblink1: "https://library.ethz.ch/en/",
            bibbutton1: "ETH Library",
            biblink2: "https://eth.swisscovery.slsp.ch/discovery/search?vid=41SLSP_ETH:ETH&lang=en",
            bibbutton2: "swisscovery",
            bibpara1: "The ETH-Bibliothek is the main library of ETH Zurich and is located in the main building (H floor). Here you will find books, journals and digital textbooks as well as courses and tips on research, literature management and scientific writing. Our other special libraries offer you subject-specific information for your studies.",
            bibpara2: "Are you looking for a suitable place to learn and work? You can find information about the locations on our website. Why not visit our course \"Getting Started - ETH-Bibliothek in a nutshell\" to get a crisp introduction with all the important information.",
            bibpara3: "The research platform ETH-Bibliothek @ swisscovery gives you access to over 490 scientific libraries in Switzerland and thus to more than 30 million books, series, journals and non-book materials as well as billions of electronic articles. Register now to equip yourself with the best knowledge for your studies!",
            bibpara4: "All courier orders from the swisscovery network are free of charge when collected from the ETH Zurich libraries. Access to e-journals, e-books and databases online, on-site at ETH or via VPN is also free of charge for you.",
        },
        FreizeitPage: {
            title: "Leisure activities on and around campus",
            vsethtitle: "VSETH",
            vsethpara1_1: "To make your daily study life more relaxed and better, we, the ",
            vsethpara1_2: "Association of Students at ETH (VSETH)",
            vsethpara1_3: ", have lots of offers for you.",
            vsethpara2: "On one hand, we have several events for you:",
            vsethlist1_1: "The ",
            vsethlist1_2: "Fresher's Party (ESF)",
            vsethlist1_3: ", right at the beginning of the semester",
            vsethlist2_1: "Nik's Hütte",
            vsethlist2_2: ", a mulled wine tent in the last weeks of the autumn semester",
            vsethlist3_1: "The ",
            vsethlist3_2: "Summerbar",
            vsethlist3_3: ", an outdoor bar in the last weeks of the spring semester",
            vsethpara3: "On the other hand, we have many committees, associated and recognised student organisations that have a lot of offers for you. Have a look at our website, maybe you will find something for you there:",
            vsethlink: "https://vseth.ethz.ch/organisations/",
            vsethbutton1: "List of organisations of VSETH",
            vsethpara4: "We also represent you and all students at ETH Zurich in university politics towards ETH and the outside world.",
            vsethpara5: "With so many events coming up, it's worth keeping an eye on the VSETH newsletter. You can also find out about many events by following us on Instagram (@vsethz). For committees and organizations that are of particular interest to you, it is worth taking a quick look at what communication methods they use to share their offerings. These can be their own newsletters or social media, but sometimes also Discord servers or similar.",
            vsethpara6: "If you want to know more, please click the button.",
            vsethbutton2: "More about the VSETH",
            fvtitle: "Student Associations",
            fvpara: "If you are a member of VSETH, you will automatically be assigned to and a member of your student association. Your student association will have already introduced itself on your first day. The people sitting next to you in the lectures are probably also in the same student association as you. You will find a lot of like-minded people in these clubs! Your student association offers you study-specific services such as platforms where you can find all relevant documents such as old exams, summaries and other tips and tricks for your studies. Just ask your student association where you can find this information. You are NEVER alone at ETH with your problems. If you want to get some variety in your studies, there is also a suitable offer: Every Tuesday and Thursday there is a bar at Loch Ness in the HXE on the Hönggerberg and every Wednesday at the PapperlaPub in the CAB (Zentrum). Here you can end the day in a cosy atmosphere.",
            asvztitle: "ASVZ",
            asvzpara1_1: "Have you done a sport that you want to continue? Are you looking for a sporting activity but don't know which one yet? Then the ",
            asvzpara1_2: "Academic Sports Association Zürich (ASVZ)",
            asvzpara1_3: " is just the right thing for you. The ASVZ offers all university members (especially ETH students) in Zurich an attractive and varied range of sports, in which there are always new things to discover and tried and tested things to maintain. They offer around 600 lessons a week in over 120 sports and almost 1,200 training courses/camps a year. In addition, you will find ideal training conditions for your individual training on strength/cardio equipment or in the functional zones in their 7 sports facilities in Zurich, Winterthur and Wädenswil. With ASVZ@home, you also have an alternative for a workout at home or on the road. ",
            asvzpara2: "When you pay your tuition fees, you are automatically entitled to ASVZ access. The free ASVZ app is your personal access to the ASVZ sports centre, as you can check in at the check-in terminal on site using the QR code in the app. Also take a sports towel and a lock with you (also available at the ASVZ info desk for CHF 10) so that you can deposit your sports bag in the locker.",
            asvzlink: "https://asvz.ch/en/634-welcome-asvz",
            asvzbutton: "ASVZ website",
            stadttitle: "Zürich city",
            stadtpara: "Otherwise, feel free to explore the city of Zurich - it has something for everyone with its waters, its mountains and hills, and its beautiful forests, as well as many other things to do in the city!",
            stadtbutton: "The city of Zürich and its surroundings",
        },
        VorstellungPage: {
            title: "The VSETH",
            para: "We at the VSETH and the student associations take care of everything that enriches your studies outside the lecture hall: first-year and ski weekends, a book exchange where you can get second-hand books at a reasonable price, a weekly free programme cinema, legal advice, music rooms and band rooms where you can practise undisturbed, dance courses, convivial fondues, legendary parties to get to know people, helping to shape ETH through the students' representation in higher education policy, and, and, and, ...",
            wertitle: "Who we are",
            werpara: "We, the VSETH, represent you in university politics towards ETH and Switzerland, organise many events from the poly fair to film evenings and parties, take care of the perfect start for first-semester students, offer you inexpensive music rooms, a band room, dance courses and much more. In addition to services for your studies, such as the exam preparation courses of the subject clubs, we offer something for almost every hobby. We represent interests in most ETH committees and many cooperatives and can therefore bring your concerns directly to the right place. Be it praise or criticism of the catering situation in the Gastrokommission, missing or poorly equipped study rooms in the Rectorate or suboptimal lectures in the various teaching committees of the departments. We will either take up your concerns ourselves or support you in raising them in the right places.",
            wotitle: "Where we are",
            zentrumtitle: "Zentrum",
            zentrumpara: "You will find us in the left wing of the CAB, the red and white brick building at Universitätstrasse 6, where you will find our secretariat, the two event rooms CAB F21 and D21, various offices and the common room of AMIV, VMP and VIS with billiards, table games, coffee machine and beer vending machine.",
            hoenggtitle: "Hönggerberg",
            hoenggpara: "There is a lounge with coffee machine and sofas, and some student association offices in the HXE building.",
            erreichsttitle: "How you can reach us",
            erreichstpara: "Drop by for a coffee at CAB or send an email to ",
            mitgliedtitle: "How you become a member",
            mitgliedpara: "To become a member, tick the box \"Membership VSETH\" when registering on mystudies.ethz.ch and you are already a member. The membership fee is 10 CHF per semester. Your advantages: Discounted or free admission to all events, you will receive the VSETH newsletter, the Polykum will be waiting in your letterbox every month and most importantly: you can become active in your student association and help shape student life!",
            engagierentitle: "How you can get involved",
            engagierenpara: "If you would like to become active and get involved in something meaningful alongside your studies, there are many opportunities for you. Your student association as well as the VSETH have many projects in which you can participate.",
            fachvereintitle: "Student association",
            fachvereinpara: "Your student association offers you an easy way to get involved in VSETH. It has the advantage that you can exchange ideas with students from similar fields of study.",
            kommissiontitle: "Committee",
            kommissionpara: "The VSETH has a wide variety of committees where students from all fields of study can get involved. There is a wide range of activities on offer, and you are sure to find something that interests you.",
            kommissionlink: "https://vseth.ethz.ch/organisations/#committees",
            kommissionbutton: "Committees of VSETH",
            vorstandtitle: "Board",
            vorstandpara: "The association could not exist without its board, of course. This is a task that most people only decide to take on after passing their basic exam. However, even as a first-year student, you can get information from the board members or even take on smaller tasks. From short, one-off helping shifts to working on the board, there is something for every time profile. You can find a rough overview of the board's work under ",
            vorstandlink: "https://vseth.ethz.ch/recruiting/",
            vorstandlinktext: "vseth.ethz.ch/recruiting/ (only in German)",
            sonstigestitle: "Other offers",
            sonstigespara: "VSETH offers many other services that are not organised as a commission. Here is a small list:",
            musiktitle: "Music rooms",
            musiklink: "https://vseth.ethz.ch/offers/",
            musikbutton: "Music rooms",
            musikpara: "The VSETH offers you music rooms with pianos and grand pianos as well as a well-equipped band rehearsal room with drums, amplifiers, stage piano, mixer and much more at reasonable prices. There you can practise undisturbed at a reserved time. You can find more information on the website or in the VSETH office.",
            buechertitle: "Book exchange",
            buecherlink: "http://bb.vseth.ethz.ch",
            buecherbutton: "Book exchange (page is in German)",
            buecherpara: "Some student associations offer a book exchange for their students or integrate the VSETH book exchange into their website. Here you can easily find textbooks or get rid of old ones. This can save you a lot of money, means little work and is therefore a win-win situation!",
            euletitle: "Golden Owl",
            eulelink: "http://eule.vseth.ethz.ch",
            eulebutton: "Golden Owl",
            eulepara: "The Golden Owl is a sympathy prize from the students to the lecturers at ETH Zurich, awarded by VSETH for particularly good teaching.",
        },
        ZuerichPage: {
            title: "The city of Zurich and its surroundings",
            para: "This guide is for all the people new to Zurich, so if you‘ve living here already you can probably skip this part. We think you made a good choice with this place. You can live very well in Zurich and we hope that you will soon think so, too. Zurich, or Züri, is the biggest cities in Switzerland – with around 400 000 inhabitants.The surrounding countryside is populous, adding up to around 1.19 million people living in the agglomeration of Zurich and around 1.83 million in the metropolitan region of Zurich (ok, it’s 2012 data that we got from Wikipedia, just read it yourself). Zurich is the most important economic, scientific and societal center of Switzerland. The city is located in the eastern middleland of Switzerland, right at the Limmat, the river leading out of Lake Zurich. Its inhabitants are called Zürcher (respectively Stadtzürcher for differentiating from the inhabitants of the rest of the canton).",
            tippstitle: "Advice for your first weekend in Zurich",
            tippspara_1: "You have now lived through your first week! We hope you have become a little accustomed with ETH, and have met your first lifelong college friends. Since there are no exercises during the first week of the semester, you should still have some free time to discover Zurich and its surroundings. You have moved into a versatile and great city. We now want to prove the prejudice wrong that you need to spend a lot of money in order to have fun in Zurich, by providing you with the following pieces of advice and recommendations. If you want to find further free activities, it is always worth having a look at ",
            tippslink: "https://zuerichunbezahlbar.ch",
            tippslinktext: "zuerichunbezahlbar.ch",
            tippspara_2: "",
            sonnetitle: "In case of good weather",
            schwimmentitle: "Swimming",
            schwimmenlink: "https://sportamt.ch",
            schwimmenbutton: "sportamt.ch",
            schwimmenpara: "One of the many advantages of Zurich is that it has both a lake and a river. The swimming places Oberer and Unterer Letten, Werdinsel and Saffainsel are perfect for a short refreshment. Furthermore, you‘re allowed to jump right into the lake wherever there is no boating zone. You can also go to one of the 30 public pools of the city of Zurich and take a cooling leap into the water there. Tap water is generally very good everywhere in Zurich. Even the water from the lake is drinkable (we don‘t recommend it though).",
            velotitle: "Bike",
            velolink1: "https://www.veloland.ch",
            velobutton1: "veloland.ch",
            velolink2: "https://www.stadt-zuerich.ch/aoz/de/index/shop/veloverleih.html",
            velobutton2: "www.stadt-zuerich.ch",
            velopara: "A nice way to discover the city center and the lakeside is by bike. If you haven’t brought your own bike along (yet) you can get bikes for free at „Züri rollt“. As an ETH Student you can get a special discounted plan for the (e-)bike sharing service “PubliBike”. On Veloland Schweiz you can find regional bike routes. After you return, buy a sausage or a corncob and put it on one of the public barbecue grills of Grünstadt Zürich.",
            bergetitle: "\"Mountains\"",
            bergelink: "https://szu.ch",
            bergebutton: "szu.ch",
            bergepara: "Although Zurich isn’t a big-big city, it may still be the case that you want to get a broader view of it. In fact, all of the surrounding bigger or smaller hills are suitable for this purpose, but you enjoy the best view from Uetliberg. The Uetliberg is a hill in the west of the city, easily recognizable by the television tower placed on top of it. You can reach the top by bike, on foot or via the Üetlibergbahn. In the winter, when there is enough snow, you can also slide down on a sledge. 60 % of Switzerland is in the Alps. Zurich is located in the so-called Mittelland where there are only a few hills. If you want to get some mountain-feeling instantly, you can do that by taking the cable railway Rigiblick or a rack railway up to the Dolder (going from Rö- merhof). For owners of Zone 110 tickets, these rides are already included in your ticket. The view of the city is for free.",
            maerktetitle: "Markets",
            maerktelink: "https://zuercher-maerkte.ch",
            maerktebutton: "zuercher-maerkte.ch",
            maerktepara: "If you like strolling around markets on the weekend, you can find a good overview of markets on the website below. Lovers of flea markets will be amazed by the flea markets at Kanzlei and on Bürkliplatz.",
            sporttitle: "Sports",
            sportlink1: "https://helsana.ch/trails",
            sportbutton1: "helsana.ch/trails",
            sportlink2: "https://www.zurichvitaparcours.ch" ,
            sportbutton2: "www.zurichvitaparcours.ch",
            sportpara: "But perhaps you are a sporty kind of person. ASVZ is open on the weekends and also offers courses then. Otherwise you can find nice jogging routes under the links above.",
            regentitle: "In case of bad weather",
            brockititle: "Brockenhaus",
            brockilink: "https://zuercher-brockenhaus.ch",
            brockibutton: "zuercher-brockenhaus.ch",
            brockipara: "Brockenhäuser are the indoor version of flea markets. There you find used furniture, tableware and everything else at low prices.",
            zootitle: "Zoo",
            zoolink: "https://zoo.ch",
            zoobutton: "zoo.ch",
            zoopara: "Not quite for free, but a guarantee to bring sunshine to your heart at least is a visit to the Zürich Zoo with a look at the Masoala Hall. Here, the rainforest of Madagascar is growing and flourishing in a big hall.",
            landestitle: "Landesmuseum",
            landeslink: "https://nationalmuseum.ch",
            landesbutton: "nationalmuseum.ch",
            landespara: "If you are interested in the history of Switzerland, then the exhibits at the Landesmusem are right for you. The different important epochs, battles and most formative events of Switzerland’s recent and long-ago past are presented in an interactive way.",
            museumtitle: "Museum for Design",
            museumlink: "https://museum-gestaltung.ch/en/",
            museumbutton: "museum-gestaltung.ch",
            museumpara: "Good, practical and beautiful design is very prominent in Switzerland. The museum showing it is the “Museum für Gestaltung”. From time to time, they even have excellent photography exhibitions. Additionally, you get to know the stunning, modern campus of Zurich’s school of arts (secret tip: gorgeous roof-top terrace).",
            voltairetitle: "Cabaret Voltaire",
            voltairelink: "https://cabaretvoltaire.ch",
            voltairebutton: "cabaretvoltaire.ch",
            voltairepara: "In 1916 at Cabaret Voltaire, the myth of the arts and philosophy era “Dada” was born. The building still exists. You can even find expositions and a café here. They probably have the cheapest beer in Niederdörfli.",
            sukkulententitle: "Succulent Collection",
            sukkulentenlink: "https://stadt-zuerich.ch/sukkulenten",
            sukkulentenbutton: "stadt-zuerich.ch/sukkulenten",
            sukkulentenpara: "A real insider-recommendation is the collection of succulents in Zürich since it is one of the largest and most varied worldwide.",
            kunsttitle: "Kunsthaus",
            kunstlink: "https://museen-zuerich.ch",
            kunstbutton: "museen-zuerich.ch",
            kunstpara: "On Wednesdays, entrance to the collection at Kunsthaus Zürich is for free. All museums can be found under the link above.",
            einkaufentitle: "Grocery Shopping",
            einkaufenpara: "What you generally can’t do on Sundays in Switzerland is shopping. Here is a list of places where you can at least buy some milk and Muesli:",
            einkaufenlist1: "At the train stations Stadelhofen, Enge, Oerlikon and Hauptbahnhof, supermarkets are open on Sundays.",
            einkaufenlist2: "Many bakeries are open for a while on Sundays.",
            einkaufenlist3: "The airport is open every day including the shops inside as well.",
            abendtitle: "In the evening",
            abendlink: "https://usgang.ch",
            abendbutton: "usgang.ch",
            abendpara: "When Swiss people go out, they say „in den Ausgang gehen“. Best advice and recommendations for the “Ausgang” can be found under this link. For Thursdays/Fridays you can check the VSETH-Newsletter or Instragram page.",
            theatertitle: "Theater",
            theaterlink1: "https://www.opernhaus.ch/en/jung/club-jung/",
            theaterbutton1: "Club Jung of the Opera House Zurich",
            theaterlink2: "https://kulturstelle.ch/en/",
            theaterbutton2: "The Kulturstelle of VSETH",
            theaterpara: "Via Kulturstelle of VSETH you get theater tickets at reduced prices. But for all pieces there are last minute tickets available directly at the theater box-office. Same applies for the opera house. If you are lucky and the show is not sold-out, you have a good chance to get the best category (normally 300 CHF) for 20 CHF. But don’t forget to bring your Legi along!",
            kinotitle: "Open air cinema",
            kinolink: "https://kino-openair.ch",
            kinobutton: "kino-openair.ch",
            kinopara: "In summer there are additional outdoor-movies to our own Open-Air Cinema „FLiK“. A list of outdoor-cinemas can be found under this link.",
            barstitle: "Bars",
            barspara: "In Zurich, there are generally lots of great bars, many of them also close to the inner city. Take a look at the bars yourself and find out which you think is the best one. A low-priced alternative (but not on weekends) is BQM right next to Polyterrasse, and of course our student bars: the LochNess on Tuesdays and Thursdays at Höngg and PapperlaPub on Wednesdays at CAB.",
            clubstitle: "Clubs",
            clubspara: "Entrance to clubs is generally reduced on Thursdays, especially the student parties at StuZ. Otherwise clubs are quite expensive with entrance fees starting at 20 CHF.",
            wochenendetitle: "A weekend with your parents",
            wochenendelink1: "https://zuerich.ch",
            wochenendebutton1: "zuerich.ch",
            wochenendelink2: "https://ronorp.net",
            wochenendebutton2: "ronorp.net",
            wochenendepara: "When your parents are coming over, you need to have a good program at hand. How about a stroll through Niederdorf or the old city center including Lindenhof? A real bourgeois and thereby quite nice café is Café Schober at Napfgasse. You should show them the beautiful (well, as if you could find anything else here) main building of ETH. The view of the Alps from Polyterrasse will certainly sweep them off their feet. Then take a walk through urban Zürich and visit the viaduct and the sun lovers in the Letten. Afterwards you could dine at Clouds, the restaurant in the Prime Tower, and enjoy the fantastic view. Or go to Hönggerberg by bus and take a walk on the hill for a little. Take you parents to the restaurant “Zur Waid”, and when taking a look at the city from there – at last – they will understand and see why Zürich is the most beautiful city in the world. We have given everything and collected our absolute highlights for you. In case you have already tried out everything within two days, you will find further recommendations on how and where to have a great time in Zurich on the websites on top.",
            ausflugtitle: "Regional trips",
            ausflugpara: "The closest large city to Zurich is Winterthur. Winterthur has a nice old town and a fantastic photo museum. You also find the Technorama in Winterthur. It’s a museum where scientific phenomena are explained in an easy, understandable way. Best to visit it about half a year into your studies. After one semester of physics you can explain all effects presented there on your own. Yippie! ETH assessment year is useful for something at least! Further north you find the Rheinfall; one of the biggest waterfalls in Europe. It is the perfect destination for an extended bike trip. And of course, there are also the mountains. You could, for example, hike up the „Grosser Mythen“ or further into the canton Glarus. There you find beautiful hiking routes. Or go to Graubünden, or or or... There are sooo many websites on the internet to be discovered for an inspiration!",
        },
        WohnenPage: {
            title: "Living and financial matters",
            wohnentitle: "Living in Zurich",
            wohnenpara: "Finding a flat in Zurich is not easy. Especially on the free housing market, students often have a bad situation due to high demand and high prices. Some therefore move to cheaper communities outside the city of Zurich. There are a few platforms where you can search for (and hopefully find) flats. Low-cost housing in the city of Zurich is offered by",
            wohnenlist1_1: "WoKo:",
            wohnenlist1_2: " The Housing Commission offers small flats and rooms in student residences at affordable prices. See ",
            wohnenlist1link: "https://www.woko.ch/en/static/page",
            wohnenlist1linktext: "woko.ch",
            wohnenlist2_1: "Juwo:",
            wohnenlist2_2: " The Jugendwohnnetz foundation offers affordable housing for young people on low incomes. See ",
            wohnenlist2link: "https://www.juwo.ch/en/",
            wohnenlist2linktext: "juwo.ch",
            wohnenlist3_1: "ETH:",
            wohnenlist3_2: " ETH also offers a platform for finding accommodation. See ",
            wohnenlist3link: "https://www.wohnen.ethz.ch/en",
            wohnenlist3linktext: "wohnen.ethz.ch",
            wohnenlist4_1: "Student Village and Living Science:",
            wohnenlist4_2: " There are two student complexes on the Hönggerberg: The Student Village and the Living Science. However, these flats are mostly given away under the table via Facebook groups. Look for the groups on Facebook, maybe you'll get lucky.",
            finanziellestitle: "Financial matters",
            finanziellespara1: "Life in Switzerland, and especially in Zurich, is quite expensive. With rent, food and cultural activities, more than 1500 francs a month are soon gone. ETH has compiled a list of living costs during your studies here:",
            finanzielleslink1: "https://ethz.ch/en/the-eth-zurich/working-teaching-and-research/welcome-center/services-and-downloads/cost-of-living.html",
            finanziellesbutton1: "Cost of living",
            finanziellespara2: "ETH offers supplementary scholarships for all students who already receive financial support from other sources due to their situation. For this reason, studying at ETH should certainly not fail due to financial hurdles.",
            finanzielleslink2: "https://ethz.ch/students/en/studies/financial/scholarships.html",
            finanziellesbutton2: "Scholarships at ETH Zürich",
        },
    }
};