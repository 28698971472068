import { ReactNode } from "react";
import { makeVsethTheme, VSETHThemeProvider, VSETHExternalApp, useConfig} from "vseth-canine-ui";
import { MantineProvider } from "@mantine/core";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Startpage from "./pages/HomePage";
import ListPage from "./pages/ListPage";
import PageNotFound from "./pages/PageNotFound";
import WochePage from "./pages/WochePage";
import LegiPage from "./pages/LegiPage";
import PasswortPage from "./pages/PasswortPage";
import WLANPage from "./pages/WLANPage";
import MailPage from "./pages/MailPage";
import LehrveranstaltungenPage from "./pages/LehrveranstaltungenPage";
import OrientierungPage from "./pages/OrientierungPage";
import EssenPage from "./pages/EssenPage";
import MensenPage from "./pages/MensenPage";
import StudierenPage from "./pages/StudierenPage";
import DruckenPage from "./pages/DruckenPage";
import LernplaetzePage from "./pages/LernplaetzePage";
import MobilitaetPage from "./pages/MobilitaetPage";
import EinschreibungPage from "./pages/EinschreibungPage";
import FreizeitPage from "./pages/FreizeitPage";
import VorstellungPage from "./pages/VorstellungPage";
import ZuerichPage from "./pages/ZuerichPage";
import SonstigesPage from "./pages/SonstigesPage";
import WohnenPage from "./pages/WohnenPage";
import ScrollToTop from "./ScrollToTop";


function App() {
    const { i18n } = useTranslation();

	const theme = makeVsethTheme();

    let {data, } = useConfig(
        "https://static.vseth.ethz.ch/assets/vseth-0000-vseth/config.json"
        );
	
	return (
		<>
			<MantineProvider theme={theme}>
				<VSETHThemeProvider theme={theme}>
					<VSETHExternalApp
						organizationNav={data.externalNav}
                        selectedLanguage={i18n.language}
                        onLanguageSelect={(lang) => i18n.changeLanguage(lang)}
                        languages={data?.languages}
						title="How to ETH"

						makeWrapper={(url: string | undefined, child: ReactNode) => (
							<Link to={url!} style={{ textDecoration: "none", color: "inherit" }}>
								{child}
							</Link>
						)}
						privacyPolicy="https://account.vseth.ethz.ch/impressum"
                        activeHref={useLocation().pathname}
						appNav={[]}
					>
					<ScrollToTop>
					<Routes>
						<Route
							path="/"
							element={
								<Startpage/>
							}
						/>
						<Route
							path="/erstewoche"
							element={
								<WochePage/>
							}
						/>
						<Route
							path="/list"
							element={
								<ListPage/>
							}
						/>
						<Route
							path="/legi"
							element={
								<LegiPage/>
							}
						/>
						<Route
							path="/passwort"
							element={
								<PasswortPage/>
							}
						/>
						<Route
							path="/wlan"
							element={
								<WLANPage/>
							}
						/>
						<Route
							path="/mail"
							element={
								<MailPage/>
							}
						/>
						<Route 
							path="/lehrveranstaltungen"
							element={
								<LehrveranstaltungenPage/>
							}
						/>
						<Route 
							path="/orientierung"
							element={
								<OrientierungPage/>
							}
						/>
						<Route 
							path="/essen"
							element={
								<EssenPage/>
							}
						/>
						<Route 
							path="/mensen"
							element={
								<MensenPage/>
							}
						/>
						<Route 
							path="/studieren"
							element={
								<StudierenPage/>
							}
						/>
						<Route 
							path="/drucken"
							element={
								<DruckenPage/>
							}
						/>
						<Route 
							path="/lernplaetze"
							element={
								<LernplaetzePage/>
							}
						/>
						<Route 
							path="/mobilitaet"
							element={
								<MobilitaetPage/>
							}
						/>
						<Route 
							path="/einschreibung"
							element={
								<EinschreibungPage/>
							}
						/>
						<Route 
							path="/freizeit"
							element={
								<FreizeitPage/>
							}
						/>
						<Route 
							path="/vorstellung"
							element={
								<VorstellungPage/>
							}
						/>
						<Route 
							path="/zuerich"
							element={
								<ZuerichPage/>
							}
						/>
						<Route 
							path="/sonstiges"
							element={
								<SonstigesPage/>
							}
						/>
						<Route 
							path="/wohnen"
							element={
								<WohnenPage/>
							}
						/>
						<Route
							path="*"
							element={
								<PageNotFound/>
							}
						/>
					</Routes>
					</ScrollToTop>
					</VSETHExternalApp>
				</VSETHThemeProvider>
			</MantineProvider>
		</>
	);
}

export default App;
