export const i18n_german = {
    translation: {
        lang: "de",
        HomePage: {
            title: "Willkommen an der ETH Zürich",
            para1: "Wir, der Verband der Studierenden an der ETH (oder kurz der VSETH), möchten dich ganz herzlich an der ETH Zürich willkommen heissen. Aller Anfang ist schwer. Die ETH ist riesig und enthält Unmengen an Angeboten, Unterstützung und coolen Sachen. Aber wie mache ich was? Und was soll ich machen? Und wo? Genau dafür haben wir dir diese informative Webseite zusammengestellt. Du findest hier alle wichtigen Informationen und Links sowie eine Menge Tipps für dich, um dir den Einstieg ins Studium so einfach wie möglich zu machen!",
            para2: "Wir wünschen dir viel Erfolg und Spass beim Studium.",
            para3: "Dein VSETH",
            video: "https://www.youtube.com/embed/Kr6uyVFkjZg"
        },
        PageGridHomePage: {
            title1: "Die Erste Woche",
            teaser1: "Die ersten Tage deines neuen Studiums haben angefangen und du musst dich erstmal an der ETH Zürich zurechtfinden. Hier erfährst du alles, was du für den Alltag an der ETH wissen musst.",
            alt1: "Ein Bild des Hauptgebäude der ETH",
            title2: "Nachschlagewerk",
            teaser2: "Du bist bereits an der ETH angekommen, hast aber vergessen, wie man sich ins WLAN einloggt? Oder wo man Drucken kann? Oder wo man essen kann? Hier findest du alle wichtigen Informationen nochmal.",
            alt2: "Ein Bild des Hauptgebäude der ETH"
        },
        WochePage: {
            title: "Die erste Woche",
            para1: "Erstmal: Gratulation, dass du es an die ETH geschafft hast! Nun denkst du dir sicher: Boah, was für eine riesige Uni, so viele Gebäude, so viele Räume, so viele Sachen, an die ich mich erinnern muss. Keine Sorge, wir werden dich hier durch alle wichtigen Sachen führen, die du an der ETH brauchen wirst. Zuerst mal zeigen wir dir die Grundlagen darüber, wie die ETH funktioniert.",
            para2: "Der Inhalt dieser Webseite wurde in bestem Gewissen vom VSETH im September 2023 erstellt, um den neuen Studierenden der ETH den Einstieg zu erleichtern. Wir erheben keinen Anspruch auf komplette Korrektheit und Objektivität des Inhaltes. Feedback gerne an kommunikation@vseth.ethz.ch.",
            legititle: "Deine ETH-Identität",
            legipara1: "Dazu gehört als allererstes: Wer bist du überhaupt? Welche persönlichen Daten, die du immer wieder im Studium und an der ETH brauchen wirst, musst du dir merken? Und was ist eine Legi, und was kann sie alles? All dies werden wir dir in diesem Abschnitt näherbringen.",
            legipara2: "Klicke gerne auf den Button, um dir den Abschnitt durchzulesen. Wenn du damit fertig bist, komm wieder auf diese Seite und lies unten weiter.",
            legicaption: "Eine Legi der ETH",
            legialt: "Eine Legi der ETH",
            legibutton: "Deine ETH-Identität",
            passworttitle: "Passwörter setzen / ändern",
            passwortpara: "Nun weisst du, was deine ETH-Identität ist. Jetzt hast du aber sicher dringende Fragen, wie zum Beispiel: \"Wie logge ich mich ins WLAN ein?\", \"Wie kann ich Zugriff auf die Vorlesungswebseiten kriegen?\" Keine Sorge, diese Fragen werden bald beantwortet. Aber an der ETH ist vieles nicht öffentlich, und du musst dich dafür einloggen. Du hast nun immerhin deine Identität, mit der du dich einloggst, aber du hast noch keine Passwörter gesetzt. Dies ist der nächste Schritt, den du durchführen musst. In diesem Abschnitt zeigen wir dir also, wie du deine Passwörter setzen / ändern kannst.",
            passwortbutton: "Passwörter setzen / ändern",
            wlantitle: "WLAN",
            wlanpara: "Jetzt hast du die Grundlagen drin, wie du dich auf Seiten einloggen kannst. Hier kommt also wahrscheinlich das Wichtigste: Wie kannst du dich ins WLAN einloggen? Ausserdem: Was ist ein VPN? Und wie logge ich mich ins ETH-VPN ein?",
            wlanbutton: "Wie logge ich mich ins ETH-WLAN ein?",
            mailtitle: "ETH-Mailbox",
            mailpara: "Das Wichtigste, nämlich das WLAN, hätten wir nun also geklärt. Die ETH hat dich wahrscheinlich bisher vor allem mit Briefen und über deine persönliche E-Mail kontaktiert. Zukünftig wird die ETH aber alle Kommunikation auf deine persönliche Mailbox richten. Du erhältst sogar eine eigene ETH-E-Mail-Adresse. Über diese werden alle administrativen Sachen der ETH wie Einschreibungen und Belegungen laufen sowie alle Informationen von Dozierenden über deine Vorlesungen und andere Veranstaltungen kommen. Wie du dich in deine Mailbox einloggst, wie du sie dir in dein Mailprogramm integrieren kannst oder weiterleiten kannst, das kannst du alles hier nachlesen.",
            mailbutton: "ETH-Mailbox",
            lehrtitle: "Lehrveranstaltungen",
            lehrpara: "Damit hast du alle Grundlagen für die ETH erlernt und kannst nun voll mit dem tatsächlichen Studium beginnen! Du wirst ab dem zweiten Tag des Semesters dann natürlich voll in Vorlesungen, Übungen und Praktika starten. Generell wird an der ETH Selbstorganisation vorausgesetzt. Dies beinhaltet auch, dass du die Vorlesungswebseiten regelmässig anschaust und deine Mails immer checkst. Keine Sorge, die Dozierenden werden in ihrer Vorlesung nochmal darauf hinweisen. Aber es lohnt sich für dich, schon mal einen Blick auf die Webseiten geworfen zu haben. Wo du diese findest und wie du dich an der ETH zurechfindest, erklären wir dir hier.",
            lehrcaption: "Eine typische Vorlesung an der ETH",
            lehralt: "Ein Bild eines typischen gefüllten Vorlesungssaals an der ETH",
            lehrbutton: "Lehrveranstaltungen",
            essentitle: "Essen",
            essenpara: "So, dieses ganze Lesen hat dich bestimmt ziemlich hungrig gemacht. Tatsächlich gibt es sehr viele Orte an der ETH und rund um die ETH, an denen du essen kannst. Du kannst in einer der vielen Mensen essen. Wenn du gerne kochst oder kochen lernen willst, gibt es einige Mikrowellen, an denen du dein Essen aufwärmen kannst. Und falls du nur einen kleinen Snack willst, dann hast du dafür auch einige Möglichkeiten. Wir haben versucht, möglichst alle Angebote, die es so gibt, für dich auf der folgenden Seite aufzulisten.",
            essencaption: "Essen in einer Mensa",
            essenalt: "Studierende in einer Mensa, die essen",
            essenbutton: "Essen",
            studierentitle: "Was braucht man zum Studieren?",
            studierenpara: "In der Schule bzw. deinem alten Bachelor-Studium hast du vielleicht einige Bücher und vielleicht sogar Laptops kaufen müssen. An der ETH arbeitet man vor allem mit Vorlesungswebseiten und Skripten, je nach Studiengang aber auch mit Büchern. Manche Skripte willst / musst du auch ausdrucken. Einen Laptop wirst du auch brauchen. Wie man das alles macht, findest du hier erklärt.",
            studierenbutton: "Studieren an der ETH",
            lernplaetzetitle: "Lernplätze an der ETH",
            lernplaetzepara: "Du hast nun genug Wissen, um die ersten Tage an der ETH zu überleben. Nun kommen aber deine Vorlesungen, Übungen und Praktika auf dich zu. Du wirst viele Informationen erhalten und du kannst dich langsam auf den Alltag im Studium einstellen. Jetzt kommen die Übungsserien, die du lösen musst, und die Vorlesungen, die du vor- und nachbereiten musst. Dafür brauchst du am besten ein stilles Örtchen, an dem du dich konzentrieren kannst und Platz hast. Einige davon haben sogar Steckdosen. Die ETH hat dafür einige Lernplätze, an denen du in Ruhe lernen kannst. Diese findest du hier.",
            lernplaetzebutton: "Lernplätze an der ETH",
            mobilitaettitle: "Mobilität an der ETH",
            mobilitaetpara: "Natürlich ist ein weiterer Aspekt von deinem Studium und deinem Leben in Zürich die Mobilität. Wie kommst du von einem Campus auf den anderen? Wie kannst du dich in und um Zürich bewegen? Du kannst natürlich gewisse Strecken zu Fuss laufen, aber Zürich bietet jede Menge weiterer Bewegungsmöglichkeiten an. Dazu gehören insbesondere Fahrräder sowie Trams, Busse und Züge. Mehr Details erfährst du hier.",
            mobilitaetcaption: "Der ETH eLink",
            mobilitaetalt: "Der ETH eLink, ein vollkommen elektrifizierter Bus.",
            mobilitaetbutton: "Mobilität an der ETH",
            einschreibungtitle: "Einschreibung und Belegung",
            einschreibungpara: "Falls du im Basisjahr bist, wurdest du bei deiner Anmeldung bereits automatisch in einen Studiengang und die zugehörigen Vorlesungen des ersten Semesters eingeschrieben. Du musst das aber ab dem 2. Semester selbst machen. An der ETH musst du jedes Semester drei Sachen tun, damit du dein Semester wirklich vollständig belegt hast: Dich ins Semester einschreiben, die Fächer belegen und dich für die Prüfungen anmelden. Wie man diese Sachen macht, zeigen wir dir hier.",
            einschreibungbutton: "Einschreibung und Belegung",
            sonstigestitle: "Sonstige Angebote der ETH",
            sonstigespara: "Damit sind wir mit allen lebenswichtigen Informationen zur ETH durch. Die ETH hat aber auch noch andere Angebote und Informationen aller Art. Diese kannst du hier finden.",
            sonstigesbutton: "Sonstige Angebote der ETH",
            freizeittitle: "Freizeitangebote",
            freizeitpara: "Jetzt hast du sehr viel über die ETH und ihre Funktionsweise gelesen. Aber was kannst du in der Freizeit machen? Vielleicht willst du eine neue Sportart ausprobieren, eine alte Sportart weiterführen, in einem Orchester spielen, oder einfach die Stadt Zürich anschauen? Wo kannst du neue Leute kennen lernen? Wie lernst du deine neue Umgebung kennen? Keine Sorge, der VSETH und die Stadt Zürich haben viele Angebote für dich!",
            freizeitbutton: "Freizeitangebote am Campus und in der Umgebung",
            wohnentitle: "Wohnen und Finanzielles",
            wohnenpara: "In Zürich ist Wohnraum teuer und rar. Falls du noch keine Wohnung oder WG gefunden hast, geben wir dir hier ein paar Hinweise. Falls dir deine finanzielle Situation Sorgen bereitet, haben wir hier auch noch Hinweise für finanzielle Unterstützung.",
            wohnenbutton: "Wohnen und Finanzielles",
            unterstuetzungtitle: "Unterstützung",
            unterstuetzungpara: "Generell gilt: Wenn du irgendwas nicht verstehst oder etwas nicht weisst: Geh zu deinen Mitstudierenden oder deinem Fachverein, frag sie, sprich sie an, schreib ihnen eine Mail, du wirst sicher eine Antwort erhalten. Alle älteren Studierenden waren einmal in genau der gleichen Situation wie du und sind gerne bereit, dir zu helfen. Wir haben dir eine Webseite zu weiteren Unterstützungsmöglichkeiten zusammengestellt.",
            unterstuetzungbutton: "Unterstützung",
        },
        LegiPage: {
            title: "Deine ETH-Identität",
            para: "Für die Identifikation an der ETH Zürich gibt es genau drei Sachen, die du dir merken musst:",
            list1: "Deine Legi",
            list2: "Deine Matrikelnummer oder Legi-Nummer",
            list3: "Dein ETH Username",
            title1: "Deine Legi",
            para1: "Deine Legi ist dein Schlüssel für alle studentischen Vorteile, die du an der ETH erhältst. Die Legi solltest du per Post erhalten haben und immer dabei haben. Mit ihr bekommst du in fast allen Mensen (ja, auch in Mensen anderer Hochschulen) Essen zum günstigeren Studi-Preis, hast je nach Studiengang Zutritt zu bestimmten Gebäuden, kannst Bücher in den verschiedenen Bibliotheken ausleihen und hast Vergünstigungen überall in der Stadt. Ausserdem steht darauf, ob du Mitglied des VSETH bist und somit von all den Vorteilen, die wir dir bieten, profitieren kannst!",
            caption: "Eine Legi der ETH",
            alt: "Eine Legi der ETH",
            title2: "Deine Matrikelnummer bzw. Legi-Nummer",
            para2: "Deine Matrikelnummer, manchmal auch Legi-Nummer genannt, ist eine Zahl, die dich an der ETH eindeutig identifiziert. Du wirst sie vor allem an Prüfungen brauchen, um dich dort zu identifizieren. Die Matrikelnummer setzt sich aus dem Jahr deiner ersten Immatrikulation zusammen und sechs weiteren zufälligen Ziffern. Sie hat also das Format 24-XXX-XXX.",
            title3: "Dein ETH Username",
            para3_1: "Den ETH Username solltest du in einem Dokument vor dem Semester erhalten haben. Es ist eine Kombination aus Buchstaben aus deinem Vor- und Nachnamen und wird dich die ganze ETH-Zeit begleiten. Auf allen Webseiten und -services, in denen du dich für die ETH einloggen musst, benutzt du den ETH Username zur Identifikation. Auch deine E-Mail-Adresse basiert auf dem Kürzel: ",
            para3_2: "ETH Username",
        },
        PasswortPage: {
            title: "Wie setze oder ändere ich meine Passwörter?",
            para1: "Alles zu Passwörtern geschieht auf der Seite ",
            link1_1: "https://password.ethz.ch",
            link1_2: "password.ethz.ch",
            button: "password.ethz.ch",
            caption1: "Die Startseite von password.ethz.ch",
            alt1: "Die Startseite von password.ethz.ch",
            para2: "In einer Mail / einem Brief, den du vor Beginn deines Studiums erhalten hast, steht sowohl dein ETH Username als auch ein Passwort drin. Logge dich damit ein.",
            para3: "Falls du dein Passwort bereits einmal geändert hast und es nochmal ändern willst, logge dich einfach mit ETH Username und deinem gesetzten LDAPS-Passwort ein.",
            caption2: "Die Seite \"Passwort ändern\" beim Register \"Self Service\".",
            alt2: "Die Seite \"Passwort ändern\" beim Register \"Self Service\".",
            para4: "Klickt dann oben links beim Register \"Self Service\" auf \"Passwort ändern\".",
            caption3: "Die Seite \"Passwort ändern \".",
            alt3: "Die Seite \"Passwort ändern \".",
            para5: "Jetzt kannst du für die folgenden Services Passwörter festlegen:",
            list1: "Dieses wirst du am häufigsten verwenden. Dieses benutzt du immer, wenn du dich auf ETH-Seiten mit switchAAI oder Moodle anmelden musst.",
            list2: "Dieses verwendest du vor allem für deine Mailbox, aber auch andere geschützte Services wie SharePoint oder Drucken.",
            list3: "Dieses verwendest du für das WLAN und VPNs.",
            para6: "Für maximale Sicherheit setze für jeden Service ein eigenes unterschiedliches Passwort.",
        },
        WLANPage: {
            title: "WLAN und VPN",
            wlantitle: "Wie logge ich mich ins WLAN der ETH ein?",
            wlanpara1_1: "Wähle auf deinem Gerät das WLAN-Netzwerk",
            wlanpara1_2: "oder",
            wlanpara1_3: "aus. Dort wirst du dann nach einem Benutzernamen und einem Passwort gefragt.",
            wlanlist1_1: "Benutzername:",
            wlanlist1_2: "Dein ETH Username",
            wlanlist2_1: "Passwort:",
            wlanlist2_2: "Dein gesetztes Passwort \"Radius\"",
            wlanpara2: "Falls du nicht (mehr) weisst, wie man das Passwort \"Radius\" setzt, siehe die Seite \"Wie setze / ändere ich meine Passwörter\":",
            wlanbutton: "Passwörter setzen / ändern",
            wlanpara3: " Viele Hochschulen weltweit verwenden auch eduroam, in dieses du dich auch mit den oben genannten Credentials einloggen kannst. Daher kann es gut sein, dass wenn du eine Hochschule irgendwo auf der Welt besuchst, du dort von kostenlosen und schnellem WLAN Gebrauch machen kannst.",
            vpntitle: "VPN",
            vpnpara1_1: "Die Abkürzung ",
            vpnpara1_2: " steht für ",
            vpnpara1_3: ". Es gibt ein paar Services an der ETH, auf die kann man nur vom ETH-Netz aus (also nur, wenn du mit eduroam verbunden bist) zugreifen. Dazu gehören:",
            vpnlist1: "Die Seite Webprint, auf die du Dokumente hochlädst, um sie zu drucken. Mehr Details zum Drucken kommen später.",
            vpnlist2: "Eventuell manche Vorlesungswebseiten oder Webseiten, auf die du Dokumente hochladen musst.",
            vpnpara2: "Um auch von zu Hause aus auf diese Services zugreifen zu können, musst du dir einen Client installieren, mit dem du auf das ETH-VPN zugreifen kannst. Für das klickt gerne auf das Tutorial unten.",
            vpnlink: "https://unlimited.ethz.ch/display/itwdb/VPN",
            vpnbutton: "Tutorial für VPN"
        },
        MailPage: {
            title: "ETH-Mailbox",
            adressetitle: "Die Mailadresse",
            adressepara1: "Für alle Kommunikation von und mit der ETH und den Dozierenden stellt dir die ETH Zürich eine eigene Mailbox zur Verfügung. Dort kommen alle für das Studium wichtige Sachen, wie zum Beispiel:",
            adresselist1: "unser VSETH-Newsletter",
            adresselist2: "Informationen zu Vorlesungen",
            adresselist3: "Informationen zur Einschreibung in Übungsgruppen",
            adresselist4: "deine Semesterrechnung (!)",
            adresselist5: "die Immatrikulationsbestätigung",
            adresselist6: "alles, was an der ETH wichtig ist",
            adressepara2: "Schaut also oft rein!",
            adressepara3_1: "Die Mailadresse, die du von der ETH erhältst, lautet ",
            adressepara3_2: "ETH Username",
            adressepara3_3: ". Die gleiche Mailadresse wird erreicht mit ",
            adressepara3_4: "ETH Username",
            mailboxtitle: "Wie greife ich auf die ETH-Mailbox zu?",
            mailboxpara1: "Auf die Mailbox kommst du über Webmail auf ",
            mailboxlink1_1: "https://outlook.office.com",
            mailboxlink1_2: "outlook.office.com",
            mailboxbutton1: "Webmail",
            mailboxpara2: "Du kannst aber auch deine Mailbox in dein Mailprogramm wie z. B. Outlook, Apple Mail, Thunderbird etc. einbinden. Tutorials findest du auf der folgenden Seite (im Inhaltsverzeichnis auf \"Mailclient\" klicken):",
            mailboxlink2: "https://unlimited.ethz.ch/display/itwdb/Mailbox",
            mailboxbutton2: "Tutorials für Mailclients",
            weiterleitungtitle: "Kann ich meine Mails auch auf eine andere E-Mail-Adresse weiterleiten lassen?",
            weiterleitungpara1: "Ja, das geht. Geht auf die Seite ",
            weiterleitunglink1_1: "https://password.ethz.ch",
            weiterleitunglink1_2: "password.ethz.ch",
            weiterleitungbutton: "password.ethz.ch",
            weiterleitungpara2: "Geht oben links bei der Registerkarte \"Self-Service\" auf \"Forward verwalten\".",
            weiterleitungcaption1: "Die Seite \"Forward verwalten\" beim Register \"Self Service\".",
            weiterleitungalt1: "Die Seite \"Forward verwalten\" beim Register \"Self Service\".",
            weiterleitungpara3: "Dort kannst du eine Weiterleitung an eine andere Mailadresse einrichten.",
        },
        LehrveranstaltungenPage: {
            title: "Lehrveranstaltungen",
            para1_1: "Deine Stundenpläne sowie Vorlesungsmaterialien findest du alle auf ",
            para1_2: ". Dies ist auch der Ort, an dem du alle administrativen Angelegenheiten zu deinem Studium erledigst. Gehe also auf die Webseite:",
            link: "http://mystudies.ethz.ch",
            button: "myStudies",
            para2: "Klicke oben auf den Button \"Start\". ",
            para3: "Dann kommt ein Anmeldefenster. Logge dich dort ein mit:",
            list1: "ETH Username:",
            list2_1: "ETH Passwort für Webapplikationen (LDAP):",
            list2_2: "Dein Passwort (LDAPS)",
            caption1: "Der Knopf \"Start\" auf der myStudies-Startseite.",
            alt1: "Der Knopf \"Start\" auf der myStudies-Startseite.",
            para4: "Nun kommst du zum Homebildschirm von myStudies.",
            caption2: "Der Homebildschirm von myStudies.",
            alt2: "Der Homebildschirm von myStudies.",
            para5: "Oben hast du eine Kategorie namens \"Lehrveranstaltungen\". Hier findest du alles rund um die Lehrveranstaltungen deines aktuellen Semesters.",
            stundenplantitle: "Stundenplan",
            stundenplanpara1: "Hier findest du eine tabellarische Form deines Stundenplans. Jede Vorlesung ist mit folgenden Daten gekennzeichnet:",
            stundenplanlist1: "Art der Veranstaltung:",
            stundenplanlist1v: "steht für Vorlesung",
            stundenplanlist1u: "steht für Übungsstunde",
            stundenplanlist1p: "steht für Praktikum",
            stundenplanlist1s: "steht für Seminar",
            stundenplanlist1g: "steht für Vorlesung mit Übung",
            stundenplanlist2: "Name der Veranstaltung",
            stundenplanlist3: "Name der dozierenden Person",
            stundenplanlist4: "Ort der Veranstaltung",
            stundenplanpara2: "Die wichtigste Angabe ist hierbei der Ort der Veranstaltung. Die ETH benutzt ein spezielles System, um seine Räume und Gebäude zu kennzeichnen.",
            stundenplanbutton: "Orientierung an der ETH Zürich",
            stundenplanpara3: "Wenn du auf \"Liste\" schaltest, kannst du Lehrveranstaltungen auch von der tabellarischen Ansicht entfernen. Das ist vor allem dann nützlich, wenn du überlappende Übungsstunden o.ä. hast, bei denen du nur eine auswählen musst.",
            lernmaterialientitle: "Lernmaterialien",
            lernmaterialienpara: "Hier findest du alle Lernmaterialien für die Vorlesungen, die dir die Dozierenden anbieten. Mache am besten Lesezeichen für die einzelnen Seiten, dann findest du sie schnell wieder. Wenn du schon mehrere Semester lang studiert hast, kannst du dort auch die Semester umstellen.",
            eduapptitle: "EduApp",
            eduapppara_1: "Es gibt eine sehr nützliche App für dein Smartphone namens ",
            eduapppara_2: ". Die App ist offiziell von der ETH, und dort kannst du auch nochmal deinen Stundenplan sehen, eine Campus Map finden, und Klickerfragen beantworten, die vielleicht während den Vorlesungen gestellt werden. Lade sie dir auf jeden Fall runter!",
            freiwilligetitle: "Freiwillige Beiträge",
            freiwilligepara: "Ganz unten gibt es noch einen aufklappbaren Reiter namens \"Freiwillige Beiträge\". Wenn du diesen aufklappst, findest du drei verschiedene freiwillige Beiträge, die du bezahlen kannst. Schaue auf jeden Fall, dass du VSETH ausgewählt hast. Denn damit wirst du VSETH-Mitglied und automatisch Mitglied deines Fachvereins und kannst an vielen Events und Angeboten vom VSETH und von deinem Fachverein teilnehmen.",
            freiwilligecaption: "Der Mitgliederbeitrag für den VSETH in den freiwilligen Beiträgen.",
            freiwilligealt: "Der Mitgliederbeitrag für den VSETH in den freiwilligen Beiträgen.",
        },
        OrientierungPage: {
            title: "Orientierung an der ETH Zürich",
            campustitle: "Campusstandorte",
            campuspara1_1: "Die ETH Zürich hat zwei Hauptcampusse: Das ",
            campuspara1_2: " und den ",
            campuscaptionzentrum: "Der Campus ETH Zentrum, mit dem Hauptgebäude (HG) in der Mitte",
            campusaltzentrum: "Ein Luftbild des Campus ETH Zentrum",
            campuscaptionhoenggerberg: "Der Campus ETH Hönggerberg",
            campusalthoenggerberg: "Ein Luftbild des Campus ETH Hönggerberg",
            campuscaptionlink: "Der ETH eLink",
            campusaltlink: "Der ETH eLink, ein vollkommen elektrifizierter Bus",
            campuspara2_1: "Beide sind durch einen Shuttlebus, den ",
            campuspara2_2: ", verbunden, der alle 20 Minuten fährt, und dich in etwa 15 Minuten von einem Campus zum anderen bringt. Ein Fahrplan ist in der EduApp zu finden. Alternativ kann man mit Trams und Bussen in etwa 20 Minuten ebenfalls den Campus wechseln.",
            gebaeudetitle: "Benennung der Gebäude",
            gebaeudepara1: "Jedes Gebäude an der ETH ist eindeutig durch eine zwei- oder dreistellige Buchstabenkombination benannt.",
            gebaeudepara2_1: "Wichtig:",
            gebaeudepara2_2: " Wenn du in ein Gebäude musst, das dreistellig ist und mit einem ",
            gebaeudepara2_3: " anfängt, musst du fast sicher auf den Hönggerberg-Campus. Es gibt Ausnahmen, aber in der Regel finden in diesen keine Vorlesungen statt.",
            raeumetitle: "Benennung der Räume",
            raeumepara1_1: "Jeder Raum ist mit einem Buchstaben und zwei bis vier Ziffern benannt. Dabei bezeichnet der Buchstabe das Stockwerk und die Ziffern den Raum. In der Regel ist das Erdgeschoss das Stockwerk ",
            raeumepara1_2: " und wenn du hinauf gehst, geht es mit ",
            raeumepara1_3: " weiter, und wenn du hinunter gehst, dann geht es mit ",
            raeumepara1_4: " weiter. Achtung: Es gibt Gebäude mit Ausnahmen.",
            raeumepara2_1: "Nehmen wir also zum Beispiel den Raum ",
            raeumepara2_2: ". Das ",
            raeumepara2_3: " ist, gemäss obigem Hinweis, ein Gebäude auf dem Hönggerberg. Der Buchstabe ",
            raeumepara2_4: " kommt nach ",
            raeumepara2_5: ", somit ist der Raum in einem der oberen Stockwerke - hier sogar im obersten. Und das ",
            raeumepara2_6: " ist noch die Raumnummer.",
            eduapptitle: "EduApp",
            eduapppara_1: "Wichtig:",
            eduapppara_2: " Um dir die Raumorientierung deutlich einfacher zu machen, gibt es in der EduApp eine ",
            eduapppara_3: ". Dort kannst du einfach deine Raumnummer eingeben und es wird dir auf der Karte zeigen, wo dieser Raum ist - sogar mit Livestandort. Lade sie dir unbedingt runter.",
        },
        EssenPage: {
            title: "Essen",
            para: "Für das Essen habt ihr mehrere Möglichkeiten. Rechts kommst du auf die offizielle Gastro-Seite der ETH, wir haben aber auch selbst eine Liste unten zusammengestellt.",
            link: "http://gastro.ethz.ch",
            button: "Offizielle Gastro-Seite der ETH",
            mensentitle: "Mensen",
            mensenpara: "Du kannst in einer der Mensen essen. Für eine Auflistung derer klicke auf den Button.",
            mensenbutton: "Liste von Mensen",
            mikrowellentitle: "Mikrowellen",
            mikrowellenpara: "Du kannst aber auch zu Hause etwas kochen und dann mitnehmen. Es gibt rund um die ETH einige Orte, an denen du Mikrowellen findest. Nimm dir aber am besten Besteck selbst mit. Leider gibt es keine offizielle Auflistung, aber hier sind ein paar Orte, die wir kennen:",
            mikrowellenlist1: "Im Einstein im Zentrum",
            mikrowellenlist2: "Im CAB E 33 (Aufenthaltsraum)",
            mikrowellenlist3: "Im Food / Lab im CAB",
            mikrowellenlist4: "Im food market auf dem Hönggerberg",
            mikrowellenlist5: "Im Fusion auf dem Hönggerberg",
            truckstitle: "Food Trucks auf dem Campus",
            truckspara1: "Sowohl auf dem Hönggerberg als auch im Zentrum gibt es unterschiedliche Food Trucks, bei denen man sich über Mittag etwas holen kann. Die Food Trucks sind in der Regel teurer als ein Menü in einer Mensa. Die Trucks sind pro Semester immer am gleichen Wochentag vor Ort.",
            truckspara2: "Besonders zu erwähnen wäre der Truck der Äss Bar. Dort kriegt man Bäckerei-Artikel vom Vortag zu reduzierten Preisen. Die Äss Bar ist jeweils zwei Mal pro Woche an jedem Campus.",
            truckslink: "https://ethz.ch/de/campus/erleben/gastronomie-und-einkaufen/gastronomie/street-food.html",
            trucksbutton: "Auflistung der aktuellen Food Truck-Angebote",
            sonstigestitle: "Sonstige Angebote",
            sonstigespara: "Es gibt in der Nähe der Campusse, vor allem im Zentrum, noch einige andere Angebote ausserhalb der Mensen oder Food Trucks. Diese haben meistens zwar keine Studierendenpreise, bieten aber trotzdem eine gute Alternative an. Schaue dich einfach mal ein bisschen um und entdecke die Angebote selbst!",
        },
        MensenPage: {
            title: "Mensen",
            para: "Rund um die ETH gibt es ziemlich viele Möglichkeiten, wo ihr essen könnt. Zuerst aber noch ein paar generelle Tipps zur Verpflegung:",
            list1: "Vergiss nicht, deine Legi mitzunehmen!",
            list2: "Die meisten Mensen sind zwischen 12:00 und 13:00 ziemlich voll. Wenn ihr langes Anstehen vermeiden wollt, versucht, vor 11:45 oder nach 13:15 essen zu gehen.",
            list3: "Um einen Überblick über die Angebote der Mensen zu erhalten, gibt es einige Möglichkeiten: Es gibt verschiedene (inoffizielle) Apps in verschiedenen App Stores, die Angebote verschiedener Mensen auflisten. Sucht einfach nach \"Mensa Zürich\" oder ähnlichen Suchbegriffen und probiert sie aus.",
            list4: "Wenn ihr Telegram verwendet, dann könnt ihr einen Mensabot abonnieren: Öffne das Suchfeld und gebe @zurimensen_bot ein.",
            zentrumtitle: "Zentrum",
            polymensatitle: "Polymensa",
            polymensapara1: "Die Polymensa (offiziell eigentlich Mensa Polyterrasse) ist eine sehr grosse Mensa und hat meistens gut Platz. Sie wird von den Mensa-Betreiber-Firma auch als “das Hauptrestaurant” bezeichnet. Es gibt im Voll-Semester vier Menükategorien: Home, Garden, Street und Local. Das Home und Garden sind die günstigeren Optionen und Street und Local leicht teurer. Entweder Garden oder Street sollte in der Regel vegan sein. Es hat also nicht immer ein “günstiges Menü” als vegane Auswahl. Neben den vier Menüs gibt es auch ein - eher kleineres - Salatbuffet, an dem nach Gewicht bezahlt wird.  Bei dieser Mensa könnt ihr bei den Menüs Home und Garden nachschöpfen.",
            polymensapara2: "Die Polymensa hat auch ein Abendangebot.",
            foodlabtitle: "Food & Lab",
            foodlabpara: "Das food&lab (wobei die meisten Studis das & nicht aussprechen), befindet sich etwas versteckt im CAB H-Stock. Es ist eher klein, aber da nicht allzu viele Leute kommen, hat es trotzdem kaum Warteschlangen. Es gibt zwei Menüs sowie Salate. Die Menüs sind im Schnitt leicht teurer als bei der Polymensa.",
            clausiustitle: "Clausiusbar",
            clausiuspara: "Die Clausius-Mensa spezialisiert sich auf Mittagsmenüs aus unterschiedlichen Küchen in Asien. Sie ist leicht teurer als zum Beispiel die Polymensa, aber bietet mal eine andere Auswahl. Es gibt zwei Kategorien von Menüs: die günstigeren geschöpften Menüs und die Wok-Menüs, welche auf Bestellung zubereitet werden. Es gibt je Kategorie ein vegetarisches oder veganes Gericht und eines mit Fleisch. Hier ist glutenfrei sehr gut möglich und diese Mensa ist auch allgemein gut geeignet bei Allergien.",
            tannenbartitle: "Tannenbar",
            tannenbarpara: "Dieses Café befindet sich am Grünen Boden im CHN. Neben Kaffee und Getränken gibt es auch Sandwiches und Gebäck.",
            archimedestitle: "Archimedes",
            archimedespara: "Das Archimedes macht dieses Semester ganz neu im GLC auf! Deshalb haben wir noch nicht wirklich Informationen für euch, aber sind gespannt auf die neue Mensa.",
            polysnacktitle: "Polysnack",
            polysnackpara: "Kleine Cafeteria im Hauptgebäude (F-Stock). Es hat nicht allzu viel Platz hier, aber da nicht viele Leute kommen geht es trotzdem gut auf. Die Menus sind leicht teurer als in vielen anderen Mensas aber werden einzeln zubereitet. Es hat immer eine Pizza, ein Pastagericht und ein Salatgericht. Des Weiteren gibt es Sandwiches, Kaffee und Getränke. Es gibt Tage mit eher wenig Vegi-Auswahl und vegan-freundlich ist es eher selten.",
            zweisteintitle: "Zweistein",
            zweisteinpara: "Diese Cafeteria bietet neben Backwaren, Kuchen und Sandwiches auch ausreichend Platz.",
            zweigradbistrotitle: "Zwei Grad Bistro",
            zweigradbistropara: "Dieses Café befindet sich am Grünen Boden im CHN. Neben Kaffee und Getränken gibt es auch Sandwiches und Gebäck.",
            unimensatitle: "Unimensa (oben und unten)",
            unimensapara: "Die Uni Mensa ist neben dem Hauptgebäude der UZH und somit leicht weiter entfernt. Sie besteht aus zwei teilen: die untere Mensa und die obere Mensa. Bei Beiden gibt es unterschiedliche Menüs und dabei sicherlich immer eine Vegi-Option und eine Fleisch-Option. Die angebotenen Menüs unterscheiden sich zum Teil zwischen der Oberen und Unteren Mensa, aber es gibt auch Überlappungen. Ebenfalls haben beide ein Buffet, bei dem dann nach Gewicht bezahlt wird. Die Uni Mensa hat auch ein Abendangebot.",
            raemi59title: "Rämi 59 (vegan)",
            raemi59para: "Diese eher kleine und komplett vegane Mensa ist doch etwas entfernt, aber es lohnt sich durchaus. Sie kann von den ETH Gebäuden im Zentrum doch über Mittag erreicht werden. Es hat immer zwei Menüs, man kann nachschöpfen und es ist alles vegan.",
            plattetitle: "Platte",
            plattepara: "Etwas weiter entfernt befindet sich die Platte Mensa von der UZH. Dort gibt es zwei Menüs und Sandwiches. Bei den Menüs ist immer eines Vegan und das zweite Vegi oder mit Fleisch. Bei dieser Mensa könnt ihr nachschöpfen.",
            hoenggtitle: "Hönggerberg",
            fusionmealtitle: "Fusion Meal",
            fusionmealpara: "Das Fusion Meal befindet sich im HCI im F Stock; also direkt über dem Fusion Coffee. Hier gibt es jeweils drei Menüs pro Tag sowie ein Free-Choice Buffet. Bei den Tagesmenüs gibt es jeweils mindestens ein Vegi-, Vegan- und Fleischmenü. Bei dem Free-Choice Buffet gibt es eine grosse Salatbar sowie eine grosse Auswahl an warmen Gerichten. Man kann von beiden Buffets kombinieren und zahlt dann nach Gewicht an der Kasse. Bei dieser Mensa könnt ihr nachschöpfen.",
            foodmarkettitle: "Food Market",
            foodmarketpara: "Der Food Market erreicht ihr durch das HPH. Das Mittagsangebot ist in drei Sektionen eingeteilt: Grill & BBQ, Pizza & Pasta sowie Green Day. Man stellt sich einfach zu der Sektion in die Schlange, holt ein Menu und bezahlt auch direkt dort. Der Food Market hat von 17.30 bis 19.15 Uhr auch ein Abendangebot.",
            foodmarketgrilltitle: "Grill & BBQ",
            foodmarketgrillpara: "Hier gibt es Fleisch und manchmal Fisch, jeweils ein billigeres (Fire) und ein teureres Menü (Grill). Neu gibts auch immer wieder auch Fleischalternativen wie Planted.",
            foodmarketpizzatitle: "Pizza & Pasta",
            foodmarketpizzapara: "Hier gibt es, wie der Name schon sagt, jeweils Pizza und Pasta. Bei den Pizzen gibts immer Margherita und eine spezielle. Pasta gibt es auch jeweils zwei Angebote (ein billigeres und ein teures). Das Teure ist ausgefallener aber auch das Billigere ist nicht nur 0815 Pasta mit Tomatensauce sondern gerne mal Älplermagronen oder Thai-Nudeln.",
            foodmarketgreentitle: "Green Day",
            foodmarketgreenpara: "Hierbei handelt es sich nicht um eine Punk-Band aus den 90ern. Der Green Day-Counter bietet jeweils ein vegatisch/veganes daily Menü und einen Salatcounter, wo ihr euch 5 Sachen zusammenstellen könnt. Kleiner Insider-Tipp: Wenn euch die Beilage des Daily Menüs nicht gefällt, könnt ihr fragen, diese mit einem der Salate des Buffets auszutauschen.",
            riceuptitle: "Rice Up!",
            riceuppara: "Rice Up! ist eine Restaurant-Kette und hat auch eine Filiale auf dem Hönggerberg. Da sie auf dem Campus ist, hat diese Filiale auch Studi-Rabatt und Mitarbeitenden-Rabatt. Hier stellt man sich eine eigene Bowl zusammen. Dabei wählt man eine Base aus (zweierlei Reis, Reisnudeln oder Salat), ein Protein, ein Gemüse, eine Sauce und dann noch Toppings. Man kann bei der Base gut auch nach “etwas mehr” fragen. Die Preise richten sich dann nach dem ausgewählten Protein. Man wird gut satt, die Qualität ist sehr konsistent und man weiss, was man bekommt.",
            alumniloungetitle: "Alumni Lounge",
            alumniloungepara: "Die Alumni Lounge bietet einige Menüs in ihren Pots an, dies sowohl über Mittag als auch am Abend. Ansonsten ist die Alumni Lounge beliebt, um am Abend auf dem Höngg noch etwas zu trinken oder eine Runde Billard zu spielen. Ebenfalls kann man den ganz Tag durch Kaffee oder anderes holen und einfach kurz ausruhen. Es gibt auch immer mal wieder Veranstaltungen, welche dort abends stattfinden.",
            fusioncoffeetitle: "Fusion Coffee",
            fusioncoffeepara1: "Im Erdgeschoss des HCI befindet sich das Fusion Coffee. Hier gibt es mittags einen Counter mit Curry-Gerichten und einen mit Sandwiches und Bowls. Die Counters haben sich in den letzten Jahren immer mal wieder neu erfunden und vielleicht sieht das Angebot auch bald wieder etwas anders aus. Von frühmorgens bis ca. 16:00 Uhr gibt es auch preiswerten Kaffee, Gebäck und Süssspeisen.",
            fusioncoffeepara2: "Ebenfalls hat es hier eine kleine Coop-To-Go-Filiale, bei welcher ihr Sandwiches, Salate, Snacks und Getränke zu Preisen wie im Supermarkt findet. Es hat hier jedoch eher die teureren Sandwiches im Angebot als im Coop-Supermarkt nebenan.",
            hpibistrotitle: "HPI Bistro",
            hpibistropara: "Kleines Café, bei dem es von Dienstag bis Donnerstag über den Mittag jeweils zweierlei Ramen zur Auswahl gibt. Davon ist eine vegetarisch. Die Ramen sind etwas teurer als viele andere Mittagsmenüs in Mensen.",
        },
        StudierenPage: {
            title: "Was braucht man für das Studium?",
            laptoptitle: "Braucht man einen Laptop?",
            laptoppara1_1: "Ja",
            laptoppara1_2: ", ab dem Herbstsemester 2023 gibt es eine ",
            laptoppara1_3: "starke Empfehlung",
            laptoppara1_4: " für das Mitbringen eines technischen Geräts. Informationen zu der Regelung und dem Ort, wo man sich Geräte beschaffen kann, findest du hier:",
            laptoplink1: "https://ethz.ch/de/studium/bachelor/studienstart/BYOD.html",
            laptopbutton1: "Bring Your Own Device (BYOD) an der ETH",
            laptoppara2_1: "Wenn du dich generell nach günstigen Laptop oder iPads umschauen willst, ist ",
            laptoppara2_2: " ein guter Ort. Sie bieten gute Geräte zu Studipreisen an und haben am Anfang des Semester jeweils eine Ausstellung, bei der man sich Equipment direkt vor Ort kaufen kann.",
            laptoplink2: "https://projektneptun.ch",
            laptopbutton2: "Projekt Neptun",
            buechertitle: "Welche Bücher sollte ich mir kaufen?",
            buecherpara1_1: "Generell hängt das von deinem Studiengang ab. Wir würden dir aber generell empfehlen: ",
            buecherpara1_2: "Kauft Bücher nur dann, wenn sie explizit verpflichtend für die Vorlesung sind.",
            buecherpara1_3: " Meistens werden die Vorlesungsmaterialien direkt auf der Vorlesungswebseite angeboten.",
            buecherpara2_1: "Mit deinem ETH-Account hast du vollen Zugriff auf ",
            buecherpara2_2: ". Dies ist ein Buchherausgeber, und auf deren Webseite kannst du ganze Bücher gratis als PDF runterladen. Je nach Studiengang wirst du viele Bücher aus der Vorlesung dort finden. Suche also zuerst auf SpringerLink, bevor du ein Buch kaufst.",
            buecherpara3: "Wenn du ein Buch kaufen musst oder ein Buch kaufen willst, bietet der VSETH dir eine Bücherbörse an. Dort kannst du schauen, ob eines der Bücher, die du kaufen willt, verfügbar ist.",
            materialtitle: "Wo kann ich mir Büromaterial sowie Bücher kaufen?",
            materialpara1_1: "An mehreren Standorten direkt auf dem Campus dient der ",
            materialpara1_2: " der gesamten ETH-Community. Im ETH Store kannst du deinen Bedarf an Studienmaterialien, empfohlenen Büchern und Artikeln mit ETH- und VSETH-Logo decken.",
            materialpara2: "Im ETH Store findest du:",
            materiallist1: "diverse Fach- und Sachbücher, Bildbände, Belletristik",
            materiallist2: "Schreibwaren, Rucksäcke, Accessoires",
            materiallist3: "Modellbaumaterialien, Künstlerbedarf",
            materiallist4: "Labormäntel",
            materiallist5: "Merchandise-Artikel",
            materiallist6: "eine Selbstbedienungsecke zum Binden von Skripten und Portfolios",
            materiallist7: "einen Multifunktionskopierer, mit welchem du Spezialpapier vom Memorystick bedrucken kannst",
            materiallist8: "und vieles mehr!",
            materialpara3: "Studierende profitieren von besonderen Angeboten und Rabatten!",
            druckentitle: "Wie und wo kann ich an der ETH drucken?",
            druckenpara: "Während des Studiums wirst du je nach Studiengang viele Unterlagen digital erhalten. Häufig will man diese und insbesondere längere Skripte selbst ausdrucken. Dafür hat die ETH einige Drucker.",
            druckenbutton: "Wie und wo kann ich an der ETH drucken?",
        },
        DruckenPage: {
            title: "Drucken",
            wiewotitle: "Wie und wo kann ich drucken?",
            wiewopara1: "Um etwas zu drucken, musst du es zuerst auf der Webprint-Plattform der ETH hochladen. Für eine Anleitung dazu siehe:",
            wiewolink1: "https://unlimited.ethz.ch/pages/viewpage.action?pageId=36247962",
            wiewobutton1: "Anleitung zum Drucken",
            wiewopara2: "Jetzt musst du nur noch zu einem Drucker hingehen und dort drucken. Um einen Drucker zu finden, suche das Kürzel vom Gebäude, in dem du gerade bist, unter diesem Link:",
            wiewolink2: "https://ethz.ch/staffnet/de/it-services/katalog/drucken/selbstbedienung/druckerstandorte.html",
            wiewobutton2: "Wie und wo kann ich drucken?",
            wiewopara3: "Jetzt gehst du zum Drucker hin und hältst deine Legi an den Beacon. Danach musst du dir einen PIN setzen für das Drucken. Merke dir diesen PIN, du wirst ihn jedes mal brauchen, wenn du druckst! Jetzt wählst du \"Print Release\" aus, wählst deinen Auftrag aus und tippst auf \"Print\". Voilà, deine Sachen werden gedruckt.",
            spezielletitle: "Spezielle Druckaufträge",
            speziellepara_1: "Wenn du speziellere Druckaufträge auf dickeres Papier, Spezialpapier etc. machen willst, kannst du das über ",
            speziellepara_2: " machen. Der Selfservice-Webshop ermöglicht es dir, Poster oder auch CAD-Pläne vor Ort eigenständig auszudrucken. Lade einfach dein PDF-Dokument in den Webshop und wähle dann den Ort und den Drucker aus. Print+Publish unterhält Selbstbedienungsräume im Hauptgebäude, am Hönggerberg und in Oerlikon.",
            speziellelink: "https://www.print-publish-sb.ethz.ch/",
            speziellebutton: "Print+Publish Self Service",
            kopierentitle: "Kopieren und Scannen bis A3",
            kopierenpara: "Um zu kopieren oder zu scannen, logge dich auf einem der Multifunktionsgeräte mit Legi oder ETH Login ein und drücke «Gerätefunktionen nutzen». Wähle dann aus, ob du dein Dokument kopieren oder scannen möchtest. Wichtig: Beim Kopieren gelten die gleichen Kosten wie beim Drucken, während scannen und sich mailen gratis ist. Wenn du ein Dokument grösser DIN A3 scannen willst, gehe zum Webshop von Print+Publish. Hier findest du Hilfe.",
            bindentitle: "Binden",
            bindenpara: "Falls du selbst ein Skript ausgedruckt hast, aber nicht alle Blätter einzeln in der Tasche herumfliegen sollen, dann kannst du bei Print+Publish dein Skript gleich im Webshop gebunden bestellen. Alternativ kannst du zum ETH-Store gehen und dort dein Skript oder deine Arbeit auch selbst binden.",
        },
        LernplaetzePage: {
            title: "Lernplätze",
            uebersichttitle: "Übersicht über die Lernplätze",
            uebersichtpara1: "Um gut lernen zu können, braucht es gute Studierendenarbeitsplätze. Dafür gibt es eine gute Übersicht der ETH:",
            uebersichtlink1: "https://ethz.ch/content/dam/ethz/common/docs/gmis/arbeitsplaetze-studierende.pdf",
            uebersichtbutton1: "Übersicht der Studierendenarbeitsplätze",
            uebersichtpara2: "Die Liste ist aus dem Jahr 2018, daher können gewisse Informationen veraltet sein. Wenn du eine ganz aktuelle Übersicht willst, dann gehe auf folgende Seite und klicke in der Mitte der Seite bei \"Liste Lernumgebungen\" auf den Link.",
            uebersichtlink2: "https://ethz.ch/studierende/de/campus/arbeitsplaetze-studierende.html",
            uebersichtbutton2: "Seite für Studierendenarbeitsplätze",
            uebersichtpara3_1: "In der ",
            uebersichtpara3_2: " findest du die obigen Informationen ebenfalls und hast sie schnell griffbereit unter \"Study Environments\".",
            zugrifftitle: "Gebäudezugriff",
            zugriffpara1_1: "Generell kannst du in alle Gebäude der ETH reingehen innerhalb der Öffnungszeiten. Ausserhalb von denen sind sie aber nicht öffentlich zugänglich. Mit deiner ",
            zugriffpara1_2: " hast du jedoch für einige Gebäude Zutritt auch ausserhalb der Öffnungzeiten und kannst die Lernräume dadrin dann auch benutzen, speziell am Wochenende und in der Lernphase. Zu diesen Gebäuden gehören das ",
            zugriffpara1_3: " im Zentrum sowie das ",
            zugriffpara1_4: " auf dem Hönggerberg. Je nach deinem Studiengang hast du vielleicht auch Zutritt zu anderen Gebäuden.",
            zugriffpara2: "Um in ein Gebäude ausserhalb der Öffnungszeiten reinzukommen, musst du deine Legi an ein solches Gerät ranhalten und einen von dir definierten Code eingeben.",
            zugriffcaption1: "Ein Kartenlesegerät für deine Legi.",
            zugriffalt1: "Ein Kartenlesegerät für deine Legi.",
            zugriffpara3: "Diesen Code musst du aber zuerst noch definieren. Gehe dafür auf ",
            zugrifflink1_1: "https://www.bi.id.ethz.ch/eAdressen/",
            zugrifflink1_2: "adressen.ethz.ch",
            zugriffbutton: "adressen.ethz.ch",
            zugriffpara4: "Logge dich dort ein. Dann scrolle runter zur Rubrik \"ETH-Karte\". Dort klickst du auf \"Zutritts-PIN-Code ändern\". Dort kannst du dann eine PIN setzen, mit der du die Türen wie oben beschrieben dann öffnen kannst.",
            zugriffcaption2: "Der Abschnitt \"ETH-Karte\", in dem man die PIN setzen/ändern kann.",
            zugriffalt2: "Der Abschnitt \"ETH-Karte\", in dem man die PIN setzen/ändern kann.",
        },
        MobilitaetPage: {
            title: "Mobilität an der ETH Zürich",
            campustitle: "Von Campus zu Campus",
            campuspara_1: "Die beiden Campusse Zentrum und Hönggerberg werden durch den ",
            campuspara_2: " verbunden. Der ETH eLink ist eine Shuttlebus-Verbindung zwischen den beiden Standorten mit Zwischenhalt Haldenegg. Er ist für ETH-Angehörige gratis. Die Bushaltestelle im Zentrum befindet sich etwas versteckt unter der Polyterrasse. Auf dem Hönggerberg fährt der Bus rechts des grossen braunen Gebäudes (HIL) – bei der Infotafel zur Solaranlage – ab. Er fährt während des Semesters viermal die Stunde, während den Semesterferien dreimal. Die ersten Verbindungen am Morgen (ab 07.06 Uhr) sowie die letzten Fahrten am Abend (bis 18.54 Uhr) verkehren ab und bis zum Hauptbahnhof Zürich, um den Anschluss an Zugverbindungen zu ermöglichen. Den Fahrplan findest du auf der ETH Webseite oder auch in der EduApp.",
            campuscaption: "Der ETH eLink",
            campusalt: "Der ETH eLink, ein vollkommen elektrifizierter Bus",
            fahrraedertitle: "Fahrräder",
            fahrraederpara_1: "Eine gute und sportliche Möglichkeit, sich in Zürich zu bewegen, ist das Velo (so nennt sich das Fahrrad hier). Dabei gibt es einige Citybike-Angebote wie ",
            fahrraederpara_2: ", bei denen du einfach eine App runterladen musst und dann mit einem Abo Velos jederzeit ausleihen und von Ladestation zu Ladestation fahren kannst. Alternativ kannst du dir auch ein Velo kaufen. Der Verein ",
            fahrraederpara_3: " organisiert dafür eine Velobörse auf der Polyterasse, bei der du kostengünstig ein Velo kaufen kannst. Ebenfalls hat VELOVE auf dem Hönggerberg eine Velowerkstatt, bei der du selbst dein Velo reparieren kannst. ",
            fahrraederlink1: "https://velovezh.ch",
            fahrraederbutton1: "Webseite von VELOVE",
            fahrraederlink2: "https://www.publibike.ch/de/home",
            fahrraederbutton2: "Webseite von PubliBike",
            oevtitle: "Öffentlicher Verkehr",
            oevpara1_1: "In Zürich gibt es aber auch einen exzellenten öffentlichen Verkehr. Die Trams, Busse und Züge in Zürich werden vom ",
            oevpara1_2: " verwaltet. Je nachdem könnte es sich für dich lohnen, dort ein Abonnement, den ",
            oevpara1_3: ", abzuschliessen. Es gibt auch Rabatt für dich, wenn du unter 25 Jahre alt bist. Siehe ihre Webseite.",
            oevlink1: "https://www.zvv.ch/zvv/de/abos-und-tickets/abos/netzpass.html",
            oevbutton1: "ZVV-Webseite: NetzPass",
            oevpara2_1: "Wenn du auch ausserhalb von Zürich die Schweiz erkunden willst, gibt es von den ",
            oevpara2_2: "Schweizerische Bundesbahnen (SBB)",
            oevpara2_3: " einige Angebote. Explizit zu erwähnen sei das ",
            oevpara2_4: ". Damit hat man von 19-5 Uhr nachts freie Fahrt - für nur 99.00 Franken pro Jahr. Eine Liste der Tickets und Abonnemente findest du ebenfalls auf ihrer Webseite.",
            oevlink2: "https://www.sbb.ch/de/abos-billette.html",
            oevbutton2: "SBB-Webseite: Abos und Tickets",
            oevcaption: "Mit den Zügen könnt ihr überall hin in der Schweiz",
            oevalt: "Ein Zug in der Schweiz",
        },
        EinschreibungPage: {
            title: "Einschreibung und Belegung von Kursen",
            para_1: "Achtung:",
            para_2: " Wenn du im Basisjahr im 1. Semester bist, bist du bereits automatisch in das Semester und in deine Kurse eingeschrieben. Jedoch musst du das ab dem 2. Semester dann selbst machen.",
            vvztitle: "Das VVZ",
            vvzpara_1: "Wenn du nachschauen willst, was für Kurse du nächstes Semester belegen willst, schaust du das im ",
            vvzpara_2: "Vorlesungsverzeichnis (VVZ)",
            vvzpara_3: " unter ",
            vvzlink1_1: "https://www.vorlesungen.ethz.ch/Vorlesungsverzeichnis/",
            vvzlink1_2: "vvz.ethz.ch",
            vvzpara_4: " nach.",
            vvzbutton: "VVZ",
            einschreibungtitle: "Einschreibung ins Semester",
            einschreibungpara1_1: "Das Verwalten der Einschreibung und der Belegung der Kurse passiert über ",
            einschreibungpara1_2: ". Logge dich also dort ein. Falls du nicht (mehr) wisst, wie das geht, klicke auf den Knopf und lese den ersten Teil des Artikels.",
            einschreibungbutton1: "Wie logge ich mich auf myStudies ein?",
            einschreibungpara2_1: "Wichtig:",
            einschreibungpara2_2: " Du musst dich zu Beginn jedes Semesters in dieses Semester einschreiben. Klicke etwa in der Mitte der Seite neben deinem Semester auf \"Funktionen\". Die Einschreibung ins Semester kannst du nun dort machen.",
            einschreibungcaption: "Der Knopf \"Funktionen\" auf myStudies.",
            einschreibungalt: "Der Knopf \"Funktionen\" auf myStudies.",
            einschreibungpara3: "Du musst nach der Semestereinschreibung deine Legi validieren lassen. Dafür gibt es spezielle Automaten überall auf dem Campus. Siehe die Liste davon.",
            einschreibunglink: "https://ethz.ch/staffnet/de/service/eth-karte/gueltigkeit-validierung/standorte-validierungsterminals.html",
            einschreibungbutton2: "Liste der Validierungsterminals",
            belegungtitle: "Belegung von Kursen",
            belegungpara: "Jetzt bist du eingeschrieben und kannst nun Kurse belegen. Wahrscheinlich hast du bereits im Vorfeld im VVZ nachgeschaut, welche Vorlesungen du belegen willst. Dafür klickst du rechts auf \"Belegungen\" und dann auf \"Lerneinheiten hinzufügen\". Dort erhältst du jetzt die gleiche Suchmaske wie im VVZ und kannst Kurse belegen. Hier kannst du dich auch wieder aus Vorlesungen ausschreiben.",
            pruefungentitle: "Prüfungen",
            pruefungenpara_1: "Wichtig:",
            pruefungenpara_2: " Wenn du in einen Kurs eingeschrieben bist, bist du nicht automatisch in die Prüfung eingeschrieben, das musst du manuell für jeden Kurs machen. Während des Semesters wirst du einen Reminder erhalten, dass du dich für Prüfungen einschreiben kannst. Dies passiert wieder über myStudies, bei \"Funktionen\" unter \"Prüfungen\". Dort kannst du Prüfungen anmelden und auch wieder abmelden.",
        },
        SonstigesPage: {
            title: "Sonstige Angebote der ETH",
            ittitle: "IT-Dienstleistungen",
            itpara_1: "Die Informatikdienste stellen ein ganzes Arsenal an Services, von eurem E-Mail-Postfach über einen kostenfreien «Dropbox-Klon» namens ",
            itpara_2: " bis zu vielen kostenlosen Softwarelizenzen. Hier findest du das Wichtigste der Informatikstelle und anderer Dienstleistungen rund um IT:",
            polyboxtitle: "polybox",
            polyboxlink: "http://polybox.ethz.ch",
            polyboxbutton: "polybox",
            polyboxpara1: "Die polybox funktioniert wie Dropbox, nur ist sie ein ETH-eigenes, sicheres Produkt, bei dem die Daten ausschliesslich auf Servern der Informatikdienste der ETH Zürich gespeichert werden. Kollaboratives Arbeiten wie Teilen mit (externen) Gästen ist auch möglich. Die polybox steht jedem Studierenden mit 50 GB kostenlosem Speicherplatz zur Verfügung.",
            polyboxpara2: "Zur polybox gibt es auch eine App, damit du immer und überall auf deine Daten zugreifen kannst.",
            personensuchetitle: "Personensuche",
            personensuchelink: "http://www.personen.ethz.ch",
            personensuchebutton: "Personensuche",
            personensuchepara: "Wenn du die Mailadresse, Telefonnummer oder Büroadresse eines/r ETH-Angehörigen brauchst, kannst du in diesem internen Adressbuch nachschauen. Um auch Studierende suchen zu können, musst du dich oben rechts unter \"Login\" anmelden.",
            apptitle: "ETH Zürich-App",
            apppara: "Diese App ist von der ETH und bietet neben News und Events auch Gebäudepläne, sowie Geländekarten. Ausserdem bietet diese App eine Übersicht über alle Mensen der ETH und deren tägliche Angebote.",
            computertitle: "Computerräume",
            computerlink: "https://ethz.ch/content/dam/ethz/associates/services/Service/IT-Services/files/catalogue/workplace/computerraeume-en.pdf",
            computerbutton: "Computerräume",
            computerpara: "Eine Liste aller betreuten Computerräume findest du auf der Website der Informatikdienste. Es gibt in allen Bibliotheken Computerarbeitsplätze und daneben viele unbetreute Computerräume. Auf den Computern der ETH findest du die Betriebssysteme Windows und Linux (Fedora) installiert.",
            supporttitle: "Computer Support",
            supportlink: "http://www.id.ethz.ch/servicedesk",
            supportbutton: "Service Desk",
            supportpara1_1: "Falls du mal ein Problem mit deinem Computer haben solltest und selbst nicht weiter weisst, kannst du dich beim Service Desk melden. Dort kann man dir sicher weiterhelfen, denn die kompetenten Menschen haben vermutlich jedes Problem schon einmal gesehen. Auch hier gilt, dass das Angebot für alle Studierenden kostenlos ist. Antworten auf deine Fragen rund um die Uhr bekommst du beim ",
            supportlink1: "http://www.id.ethz.ch/faq",
            supportlink1text: "FAQ für Studierende",
            supportpara1_2: " und beim Chatbot.",
            supportpara2_1: "Vielleicht möchtest du auch einen von diversen IT-Kursen speziell für Studierende an der ETH besuchen. Die Teilnehmendenzahl ist beschränkt, darum melde dich genug früh beim ",
            supportlink2: "http://www.compicampus.ethz.ch",
            supportlink2text: "Compicampus",
            supportpara2_2: " an.",
            itshoptitle: "IT Shop",
            itshoplink: "https://ethz.ch/staffnet/de/it-services/katalog/beschaffung/it-shop.html",
            itshopbutton: "IT Shop",
            itshoppara: "Der IT Shop ist das elektronische Bestellsystem der Informatikdienste für alle ETH Angehörige. Dort kannst du diverse Software-Produkte (wie zum Beispiel Microsoft Office) beziehen, von denen die meisten für Studierende an der ETH kostenlos zur Verfügung stehen. Du kannst dich dort wie gewohnt mit deinen ETH Benutzerdaten anmelden.",
            multimediatitle: "Ausleihdienst Multimediageräte",
            multimedialink: "http://ethz.ch/multimedia-shop",
            multimediabutton: "Multimedia Shop",
            multimediapara: " Die ETH bietet an den beiden Standorten Zentrum und Hönggerberg einen Ausleihdienst für Multimediageräte für dein Studium. Es werden z. B. Projektoren, Leinwände, Aktiv-Lautsprecher, Mikrofone und Audio-Aufzeichnungsgeräte verliehen. Die Geräte können über den Webshop oder per E-Mail reserviert werden. Alle Bestellungen müssen mindestens 48 Stunden im Voraus getätigt werden. Die Geräte müssen am Schalter des entsprechenden Standortes abgeholt bzw. zurückgebracht werden. Dieser Service ist für Studierende der ETH kostenlos.",
            austauschtitle: "Austausch während dem Studium",
            austauschlink: "http://www.outgoing.ethz.ch",
            austauschbutton: "Mobilitätstelle",
            austauschpara1: "ETH-Studierende können ein oder zwei Semester oder auch ein Projekt an einer Partnerhochschule absolvieren. Das Beste: Partnerschaften unterhält die ETH in der Schweiz, Europa und auch weltweit. Auch wenn der Semesterstart zu früh ist, um sich mit diesem Thema zu beschäftigen, möchten wir alle Studierenden auf die vielseitigen Optionen aufmerksam machen – auch weil die Vorbereitung auf den Austausch bis zu einem Jahr dauern kann. Also, informiere dich doch schon mal auf der Webseite der Mobilitätsstelle, plan dir den Besuch am Infomarkt im Herbst ein und fang an, von deinem Austausch zu träumen!",
            austauschpara2: "Wenn du ein Auslandssemester machen willst, klappt das in den meisten Fällen - informiere dich nur rechtzeitig! Um schon mal ein wenig reinzuschnuppern oder um sich inspirieren zu lassen, hilft die Website der International Knowledge Base. Darauf findest du neben allen möglichen Destinationen der verschiedenen Austauschprogramme auch Erfahrungsberichte von Studierenden, die schon im Ausland gewesen sind.",
            sprachentitle: "Sprachenzentrum",
            sprachenlink: "http://sprachenzentrum.uzh.ch",
            sprachenbutton: "Sprachenzentrum",
            sprachenpara: "Das gemeinsame Sprachenzentrum der Universität Zürich und der ETH Zürich bietet dir studienbegleitende Sprachmodule an. Einige davon können als Pflichtwahlfach des D-GESS belegt werden. Die Module unterstützen dich bei einem Studium auf Deutsch bzw. Englisch und bereiten dich auf internationale Kontakte und Studienaufenthalte vor. Das Kursprogramm enthält Kurse in über 10 Sprachen. Als Ergänzung zu den Sprachmodulen bietet dir das Selbstlernzentrum des Sprachenzentrums verschiedene Angebote zum selbständigen Sprachenlernen, sei es als Ergänzung zu einem Kurs oder für das individuelle autonome Lernen. Zum aktuellen Kursangebot und der Onlineanmeldung gehts über den oberen Link.",
            sphtitle: "ETH Student Project House",
            sphlink: "https://ethz.ch/de/die-eth-zuerich/lehre/sph.html",
            sphbutton: "ETH Student Project House",
            sphpara1: "Hier kannst du deine eigenen Hobby- oder Studienprojekte realisieren. 3D-Drucken, Laserschneiden oder Arbeiten mit Holz und Textil. Baue Apps, Software oder einen Roboter. Alles ist möglich und es ist immer Hilfe da. Werde noch heute Teil dieser lebendigen und interdisziplinären Community. Die einzigartigen und kreativen Räume stehen allen ETH-Studierenden offen, die ein bestimmtes Thema oder eine Idee erforschen wollen. SPH bietet Maschinentrainings an, organisieren Workshops und bietet Beratung, damit du ohne Druck Ideen entwickeln und Prototypen bauen kannst. Mach den ersten Schritt und geh vorbei. Sie freuen sich auf dich!",
            sphpara2: "Zentrum: ML/FHK, Clausiusstrasse, 8092 Zürich ",
            sphpara3: "Hönggerberg: HPZ, John-von-Neumann Weg 9, 8093 Zürich",
            campustitle: "Campus Info",
            campuslink: "http://ethz.ch/campusinfo",
            campusbutton: "Campus Info",
            campuspara: "Die Campus Info ist die erste Anlaufstelle bei allen Fragen rund um die ETH, egal ob ein Hörsaal gesucht wird, der nächste Geldautomat oder eine Auskunft zu Parkmöglichkeiten auf dem ETH-Areal. Auf dem Hönggerberg gibt es zusätzlich auch einen Schalter der Schweizerischen Post, die Autovermietung Europcar sowie einen ETH-Kassenschalter.",
            auskunfttitle: "Auskunft 11",
            auskunftpara: "Allgemeine Fragen zur ETH per Telefon? Die Mitarbeitenden der Telefonzentrale stehen dir beratend zur Seite oder verbinden dich weiter. Einfach anrufen: intern 111 / extern +41 44 632 11 11.",
            bibtitle: "ETH-Bibliothek",
            biblink1: "http://library.ethz.ch",
            bibbutton1: "ETH-Bibliothek",
            biblink2: "http://eth.swisscovery.slsp.ch",
            bibbutton2: "swisscovery",
            bibpara1: "Die ETH-Bibliothek ist die Hauptbibliothek der ETH Zürich und befindet sich im Hauptgebäude (H-Stock). Hier findest du neben Büchern, Zeitschriften und digitalen Lehrbüchern auch Kurse und Tipps zu Recherche, Literaturmanagement und wissenschaftlichem Schreiben. Unsere weiteren Spezialbibliotheken bieten dir fachspezifische Informationen für dein Studium.",
            bibpara2: "Suchst du einen passenden Lern- und Arbeitsplatz? Informationen zu den Standorten findest du auf unserer Webseite. Besuche doch unseren Kurs «Getting Started – ETH-Bibliothek in Kürze», um einen knackigen Einstieg mit allen wichtigen Informationen zu erlangen.",
            bibpara3: "Mit der Rechercheplattform ETH-Bibliothek @ swisscovery erhältst du Zugang zu über 490 wissenschaftlichen Bibliotheken in der Schweiz und somit zu mehr als 30 Millionen Büchern, Serien, Zeitschriften und Non-Book-Materialien sowie Milliarden elektronischer Artikel. Registriere dich jetzt, um dich in deinem Studium bestens mit Wissen auszurüsten!",
            bibpara4: " Alle Kurierbestellungen aus dem swisscovery-Netzwerk sind bei Abholung an den Bibliotheken der ETH Zürich kostenlos. Der Zugriff auf E-Journals, E-Books und Datenbanken online, vor Ort an der ETH oder über VPN ist ebenso kostenlos für dich.",
        },
        FreizeitPage: {
            title: "Freizeitangebote am Campus und in der Umgebung",
            vsethtitle: "VSETH",
            vsethpara1_1: "Um euren Studienalltag etwas entspannter und besser zu gestalten, haben wir, der ",
            vsethpara1_2: "Verband der Studierenden an der ETH (VSETH)",
            vsethpara1_3: ", viele Angebote für euch.",
            vsethpara2: "Einerseits haben wir einige Events für euch, nämlich:",
            vsethlist1_1: "Das ",
            vsethlist1_2: "Erstsemestrigenfest (ESF)",
            vsethlist1_3: ", gleich zu Beginn des Semesters",
            vsethlist2_1: "Nik's Hütte",
            vsethlist2_2: ", ein Glühweinzelt in den letzten Wochen des Herbstsemesters",
            vsethlist3_1: "Die ",
            vsethlist3_2: "Summerbar",
            vsethlist3_3: ", eine Outdoor-Bar in den letzten Wochen des Sommersemesters",
            vsethpara3: "Andererseits haben wir viele Kommissionen, assoziierte und anerkannte studentische Organisationen, die viele viele Angebote für euch bereithalten. Schau sicher mal auf unsere Webseite, vielleicht findest du dort ja was für dich:",
            vsethlink: "https://vseth.ethz.ch/organisationen/",
            vsethbutton1: "Organisationsliste des VSETH",
            vsethpara4: "Wir vertreten auch dich und alle Studierenden an der ETH Zürich hochschulpolitisch gegenüber der ETH und gegen aussen.",
            vsethpara5: "Bei der grossen Menge an anstehenden Events lohnt es sich, den VSETH-Newsletter immer gut im Blick zu halten. Über viele Events erfährt ihr auch, wenn ihr uns auf Instagram folgt (@vsethz). Bei Kommissionen und Organisationen, welche euch besonders interessieren, lohnt es sich, kurz zu schauen, welche Kommunikationsmittel sie jeweils verwenden, um ihre Angebote mitzuteilen. Dies können eigene Newsletter oder Social Media sein, aber zum Teil auch Discord-Server oder ähnliches.",
            vsethpara6: "Wenn du mehr über uns erfahren willst, klicke gerne auf den Button.",
            vsethbutton2: "Mehr über den VSETH",
            fvtitle: "Fachvereine",
            fvpara: "Wenn du Mitglied des VSETH bist, wirst automatisch eurem Fachverein zugeordnet und bist davon Mitglied. Dein Fachverein wird sich auch bereits an deinem Erstitag vorgestellt haben. Die Leute, die neben dir in den Vorlesungen sitzen, sind wahrscheinlich auch im gleichen Fachverein wie du. In diesen findest du eine grosse Menge Gleichgesinnte! Dein Fachverein bietet dir studienspezifische Leistungen wie Plattformen, auf denen du alle relevanten Unterlagen wie alte Prüfungen, Zusammenfassungen und sonstige Tipps und Tricks fürs Studium findest. Frag doch einfach deinen Fachverein, wo du diese Informationen bekommen kannst. Du bist mit Problemen nämlich NIE alleine an der ETH. Wenn du über den Tellerrand hinausschauen willst, gibt es auch dafür das passende Angebot: Jeden Dienstag und Donnerstag ist Barbetrieb im Loch Ness im HXE auf dem Hönggerberg und jeden Mittwoch im PapperlaPub im CAB (Zentrum). Hier kannst du in gemütlicher Runde den Tag ausklingen lassen.",
            asvztitle: "ASVZ",
            asvzpara1_1: "Hast du eine Sportart gemacht, die du weiterführen willst? Suchst du nach einer sportlichen Betätigung, aber weisst noch nicht, welche? Dann hat der ",
            asvzpara1_2: "Akademische Sportverbund Zürich (ASVZ)",
            asvzpara1_3: " genau das richtige für dich. Der ASVZ bietet allen Hochschulangehörigen (insbesondere ETH-Studierenden) in Zürich ein attraktives und vielfältiges Sportangebot, in welchem es immer wieder Neues zu entdecken gibt und Bewährtes erhalten bleibt. In über 120 Sportarten bieten sie rund 600 Lektionen wöchentlich und knapp 1'200 Ausbildungskurse/-camps jährlich an. Daneben findest du in ihren 7 Sportanlagen in Zürich, Winterthur und Wädenswil ideale Trainingsbedingungen für dein individuelles Training an Kraft-/Cardio-Geräten oder in den funktionellen Zonen. Mit ASVZ@home steht dir ausserdem eine Alternative für ein Workout zu Hause oder von unterwegs zur Verfügung. ",
            asvzpara2: "Mit der Bezahlung deiner Studiengebühren bist du automatisch ASVZ-zutrittsberechtigt. Die kostenlose ASVZ-App ist dein persönlicher Zugang in die ASVZ-Sport Center, denn mittels QR-Code in der App checkst du vor Ort am Check-In-Terminal ein. Nehme zudem ein Sporthandtuch sowie ein Schloss mit (auch am ASVZ-Info-Desk für CHF 10.- erhältlich), damit du deine Sporttasche im Schliessfach deponieren kannst.",
            asvzlink: "http://asvz.ch/willkommen",
            asvzbutton: "Webseite des ASVZ",
            stadttitle: "Stadt Zürich",
            stadtpara: "Ansonsten kannst du gerne die Stadt Zürich erkunden - die Stadt hat mit ihren Gewässern, ihren Bergen und Hügeln und den schönen Wäldern sowie vielen weiteren Angeboten in der Stadt für jeden Geschmack etwas dabei!",
            stadtbutton: "Die Stadt Zürich und die Umgebung",
        },
        VorstellungPage: {
            title: "Der VSETH",
            para: "Wir vom VSETH und den Fachvereinen sorgen für alles, was dein Studium ausserhalb des Hörsaals bereichert: Ersti- und Skiweekends, eine Bücherbörse, wo du günstig gebrauchte Bücher bekommst, ein wöchentliches Gratis-Programmkino, eine Rechtsberatung, Musikzimmer und Bandräume, in denen du ungestört üben kannst, Tanzkurse, gesellige Fondues, legendäre Parties um Leute kennenzulernen, die Mitgestaltung der ETH durch die hochschulpolitische Vertretung der Studis und, und, und, ...",
            wertitle: "Wer wir sind",
            werpara: "Wir, der VSETH, vertreten dich hochschulpolitisch gegenüber der ETH und der Schweiz, organisieren viele Veranstaltungen von der Polymesse über Filmabende bis hin zu Partys, kümmern uns um den perfekten Einstieg für die Erstsemestrigen, bieten dir günstige Musikzimmer, einen Bandraum, Tanzkurse und vieles mehr an. Neben Dienstleistungen fürs Studium, wie zum Beispiel die Prüfungsvorbereitungskurse der Fachvereine, bieten wir für fast jedes Hobby etwas an. In den meisten Gremien der ETH und vielen Genossenschaften stellen wir Interessensvertretungen und können somit deine Anliegen sehr direkt an der korrekten Stelle einbringen. Sei dies Lob oder Kritik an der Verpflegungssituation in der Gastrokommission, fehlende oder mangelhaft ausgestattete Lernräume beim Rektorat oder suboptimale Vorlesungen in den verschiedenen Unterrichtskommissionen der Departemente. Wir nehmen deine Anliegen entweder selber auf oder unterstützen dich dabei, dass du diese an den richtigen Stellen anbringen kannst.",
            wotitle: "Wo wir sind",
            zentrumtitle: "Zentrum",
            zentrumpara: "Du findest uns im linken Flügel vom CAB, dem rot-weissen Backsteingebäude an der Universitätstrasse 6. Dort befinden sich unser Sekretariat, die beiden Veranstaltungsräume CAB F21 und D21, diverse Büros und der Aufenthaltsraum von AMIV, VMP und VIS mit Billiard, Töggelichasten, Kaffeemaschine und Bierautomat.",
            hoenggtitle: "Hönggerberg",
            hoenggpara: "Es gibt im HXE-Gebäude einen Aufenthaltsraum mit Kaffeemaschine und Sofas sowie einige Fachvereinsbüros.",
            erreichsttitle: "Wie du uns erreichst",
            erreichstpara: "Komm für einen Kaffee im CAB vorbei oder schreib eine Mail an ",
            mitgliedtitle: "Wie du Mitglied wirst",
            mitgliedpara: "Um Mitglied zu werden, setz das Häkchen beim Einschreiben auf mystudies.ethz.ch bei «Mitgliedschaft VSETH» und schon bist du dabei. Der Mitgliedsbeitrag beträgt 10 CHF im Semester. Deine Vorteile: Vergünstigte oder freie Eintritte bei allen Veranstaltungen, du erhältst den VSETH-Newsletter, das Polykum wartet jeden Monat in deinem Briefkasten und das Wichtigste: Du kannst in deinem Fachverein aktiv werden und das Studierendenleben mitgestalten!",
            engagierentitle: "Wie du dich engagieren kannst",
            engagierenpara: "Wenn du gerne aktiv werden möchtest und dich neben deinem Studium für etwas Sinnvolles engagieren willst, gibt es viele Möglichkeiten für dich. Dein Fachverein sowie auch der VSETH haben viele Projekte, bei denen du dich beteiligen kannst.",
            fachvereintitle: "Fachverein",
            fachvereinpara: "Dein Fachverein bietet dir eine einfache Einstiegsmöglichkeit ins VSETH Engagement an. Er hat den Vorteil, dass du dich dort mit Studierenden aus ähnlichen Studienrichtungen austauschen kannst.",
            kommissiontitle: "Kommission",
            kommissionpara: "Der VSETH hat verschiedenste Kommissionen, bei denen sich Studierende aus allen Studienrichtungen engagieren können. Das Angebot ist hier sehr vielfältig, und du wirst sicher etwas finden, was dich interessiert.",
            kommissionlink: "https://vseth.ethz.ch/organisationen/#kommissionen",
            kommissionbutton: "Kommissionen des VSETH",
            vorstandtitle: "Vorstand",
            vorstandpara: "Der Verband könnte natürlich nicht ohne seinen Vorstand existieren. Das ist eine Aufgabe, für die sich die meisten erst nach dem Bestehen ihrer Basisprüfung entscheiden. Jedoch kannst du dich bereits als Ersti bei den Vorständen informieren, oder sogar kleinere Aufgaben übernehmen. Von kurzen, einmaligen Helferschichten, bis zur Arbeit im Vorstand findet sich für jedes Zeitprofil etwas. Einen groben Überblick der Vorstandsarbeit findest du unter ",
            vorstandlink: "https://vseth.ethz.ch/recruiting/",
            vorstandlinktext: "vseth.ethz.ch/recruiting/",
            sonstigestitle: "Sonstiges Angebot",
            sonstigespara: "Der VSETH bietet dir noch viele weitere Dienstleistungen, die nicht als Kommission organisiert sind. Hier eine kleine Auflistung:",
            musiktitle: "Musikzimmer",
            musiklink: "http://vseth.ethz.ch/angebot/#musikzimmer",
            musikbutton: "Musikzimmer",
            musikpara: "Der VSETH bietet dir zu günstigen Preisen Musikzimmer mit Klavieren und Flügeln sowie einen gut ausgestatteten Band-Proberaum mit Drums, Verstärkern, Stage Piano, Mischpult und vielem mehr an. Dort kannst du ungestört zum reservierten Zeitpunkt üben. Mehr Informationen findest du auf der Website oder im Sekretariat des VSETH.",
            buechertitle: "Bücherbörse",
            buecherlink: "http://bb.vseth.ethz.ch",
            buecherbutton: "Bücherbörse",
            buecherpara: "Einige Fachvereine bieten eine Bücherbörse für ihre Studierenden an oder binden die VSETH-Bücherbörse in ihre Website ein. Hier findest du sehr einfach Fachbücher oder wirst alte los. Das kann dir grosse Kosten einsparen, bedeutet wenig Arbeit und ist damit eine Win-Win-Situation!",
            euletitle: "Goldene Eule",
            eulelink: "http://eule.vseth.ethz.ch",
            eulebutton: "Goldene Eule",
            eulepara: "Die Goldene Eule ist ein Sympathiepreis von den Studierenden an die Dozierenden der ETH Zürich, der vom VSETH für besonders gute Lehre verliehen wird.",
        },
        ZuerichPage: {
            title: "Die Stadt Zürich und ihre Umgebung",
            para: "Dieser Teil richtet sich vor allem an die frisch zugezogenen Studierenden und ist eine gute Hilfe, wenn du auch mal etwas anderes als den Hörsaal und die Mensa sehen möchtest. Zürich oder eben „Züri“ ist mit rund 400‘000 Einwohnern die grösste Stadt der Schweiz. Ihr Umland ist dicht besiedelt, so dass in der Agglomeration Zürich etwa 1,3 Millionen und in der Metropolitanregion Zürich etwa 1,83 Millionen Menschen leben (laut 2012 Daten auf Wikipedia, lies doch selber). Zürich ist das wichtigste wirtschaftliche, wissenschaftliche und gesellschaftliche Zentrum der Schweiz. Die Stadt liegt im östlichen Schweizer Mittelland, an der Limmat, am Ausfluss des Zürichsees. Ihre Einwohner:innen werden Zürcher genannt (bzw. Stadtzürcher zur Differenzierung mit den übrigen Einwohnern des Kantons).",
            tippstitle: "Tipps für das erste Wochenende in Zürich",
            tippspara_1: "Du hast die erste Woche hinter dich gebracht! Wir hoffen, du hast dich schon ein bisschen an der ETH eingelebt und die ersten Studi-Freunde gefunden. Weil in der ersten Woche noch keine Übungen stattfinden, solltest du jetzt noch ein bisschen freie Zeit haben, um Zürich und Umgebung kennenzulernen. Du bist nämlich in eine sehr vielseitige und tolle Stadt gezogen. Und das Vorurteil, dass man hier viel Geld braucht, um Spass zu haben, versuchen wir mit den folgenden Tipps und Vorschlägen zu widerlegen. Unter ",
            tippslink: "https://zuerichunbezahlbar.ch",
            tippslinktext: "zuerichunbezahlbar.ch",
            tippspara_2: " findest du viele kostenlose Angebote in Zürich. Hier lohnt es sich immer, einen Blick rein zu werfen.",
            sonnetitle: "Bei Sonnenschein",
            schwimmentitle: "Schwimmen",
            schwimmenlink: "https://sportamt.ch",
            schwimmenbutton: "sportamt.ch",
            schwimmenpara: "Einer der vielen Vorteile von Zürich ist die Lage am Wasser. Die Flussbäder Oberer und Unterer Letten, die Säffainsel in Wollishofen und die Werdinsel in Höngg sind perfekt für eine kleine Abkühlung. Ebenfalls ist es erlaubt, überall wo nicht Schiffahrtszone ist, in den See zu springen. Sonst kannst du gegen Eintritt in einem der über 30 Schwimmbäder der Stadt Zürich den kühlen Sprung ins Nass wagen. Übrigens kann man das Leitungs- und Brunnenwasser in Zürich trinken. Selbst der See hat Trinkwasserqualität (wir empfehlen es trotzdem nicht).",
            velotitle: "Velo",
            velolink1: "https://www.veloland.ch",
            velobutton1: "veloland.ch",
            velolink2: "https://www.stadt-zuerich.ch/aoz/de/index/shop/veloverleih.html",
            velobutton2: "www.stadt-zuerich.ch",
            velopara: "Eine schöne Art, die Innenstadt und das Seebecken zu erforschen, ist eine Radtour. Wenn du dein eigenes Fahrrad noch nicht mitgebracht hast, kannst du bei Züri rollt gratis Fahrräder (hier in der Schweiz: <b>Velos</b>) ausleihen. Ausserdem profitierst du als ETH Studierender von einem vergünstigten Abo beim Bike-Sharing Service Publibike. Unter Veloland Schweiz findest du verschiedene regionale Routen. Am Abend kaufst du dir eine Wurst oder einen Maiskolben und legst ihn auf eine der öffentlichen Grillstellen der Grünstadt Zürich, zum Beispiel hat es bei der Chinawiese gratis öffentliche Elektrogrills.",
            bergetitle: "\"Berge\"",
            bergelink: "https://szu.ch",
            bergebutton: "szu.ch",
            bergepara: "Obwohl Zürich keine Millionenmetropole ist, mag es vielleicht sein, dass du dir mal einen Überblick über die Stadt verschaffen willst. Jeder der umliegenden Hügel bietet sich natürlich an, aber insbesondere der Üetliberg ist der Aussichtspunkt schlechthin. Der Üetliberg ist der Hügel im Westen der Stadt, gut erkennbar an der Fernsehantenne. Er lässt sich mit Mountainbike, zu Fuss oder mit der Üetlibergbahn erklimmen. Im Winter und bei genügend Schnee (also eigentlich nie) kannst du auch runter schlitteln. Die Schweiz ist zu 60% Alpengebiet. Zürich liegt im aber im Mittelland, wo es nur einige Hügel hat. Falls du aber doch auf die Schnelle ein bisschen Bergfeeling erleben willst, holst du dir das am Besten mit einer Fahrt mit der Seilbahn Rigiblick oder der Zahnradbahn hoch zum Dolder (ab Römerhof). Für Besitzer eines Zone 110 Tickets sind die Fahrten inklusive. Den Blick über die Stadt gibts sowieso gratis.",
            maerktetitle: "Märkte",
            maerktelink: "https://zuercher-maerkte.ch",
            maerktebutton: "zuercher-maerkte.ch",
            maerktepara: "Wenn du gerne am Wochenende über Märkte schlenderst, findest du auf der Website eine Übersicht. Flohmarktliebhaber werden auf dem Flohmarkt Kanzlei oder Bürkliplatz fündig.",
            sporttitle: "Sport",
            sportlink1: "https://helsana.ch/trails",
            sportbutton1: "helsana.ch/trails",
            sportlink2: "https://www.zurichvitaparcours.ch" ,
            sportbutton2: "www.zurichvitaparcours.ch",
            sportpara: "Vielleicht gehörst du aber auch zum sportlichen Typ. Der ASVZ hat auch am Wochenende geöffnet und bietet dann auch Lektionen an. Sonst findest du unter den obigen Seiten schöne Joggingrouten.",
            regentitle: "Bei Regenwetter",
            brockititle: "Brockenhaus",
            brockilink: "https://zuercher-brockenhaus.ch",
            brockibutton: "zuercher-brockenhaus.ch",
            brockipara: "Brockenhäuser sind die Indoorvariante von Flohmärkten. Dort findest du gebrauchte Möbel, Geschirr und alles andere zu günstigen Preisen.",
            zootitle: "Zoo",
            zoolink: "https://zoo.ch",
            zoobutton: "zoo.ch",
            zoopara: "Leider nicht ganz gratis, aber lohnenswert, ist ein Besuch im Zürich Zoo mit einem Abstecher in die Masoala-Halle. Der Regenwald Madagaskars wächst und gedeiht dort in einer grossen Halle. Sehr empfehlenswert soll auch der Elefanten-Brunch im Zoo sein.",
            landestitle: "Landesmuseum",
            landeslink: "https://nationalmuseum.ch",
            landesbutton: "nationalmuseum.ch",
            landespara: "Wenn du dich für die Geschichte der Schweiz interessierst, ist die Ausstellung im Landesmuseum zu empfehlen. Ziemlich interaktiv werden dir die verschiedenen wichtigen Epochen, Schlachten und prägenden Ereignisse der neuen und alten Schweizer Vergangenheit präsentiert.",
            museumtitle: "Museum für Gestaltung",
            museumlink: "https://museum-gestaltung.ch",
            museumbutton: "museum-gestaltung.ch",
            museumpara: "In der Schweiz wird gutes, praktisches und schönes Design gross geschrieben. Das Museum dazu ist das Museum für Gestaltung. Unter anderem gibt es immer wieder faszinierende Fotoausstellungen. Dazu lernst du den beeindruckend modernen Campus der Zürcher Hochschule der Künste kennen (Geheimtipp: Dachterrasse).",
            voltairetitle: "Cabaret Voltaire",
            voltairelink: "https://cabaretvoltaire.ch",
            voltairebutton: "cabaretvoltaire.ch",
            voltairepara: "In Zürich wurde der Mythos der Kunst und Philosophierichtung Dada begründet. Und zwar 1916 im Cabaret Voltaire. Das Haus steht immer noch und es gibt Ausstellungen und ein Café/Bar, wo das Bier trotz der Lage im Niederdörfli noch bezahlbar ist.",
            sukkulententitle: "Sukkulentensammlung",
            sukkulentenlink: "https://stadt-zuerich.ch/sukkulenten",
            sukkulentenbutton: "stadt-zuerich.ch/sukkulenten",
            sukkulentenpara: "Ein absoluter Geheimtipp ist die Sukkulentensammlung Zürich. Immerhin eine der weltweit grössten und umfangreichsten.",
            kunsttitle: "Kunsthaus",
            kunstlink: "https://museen-zuerich.ch",
            kunstbutton: "museen-zuerich.ch",
            kunstpara: "Am Mittwoch ist die Sammlung des Kunsthaus Zürich gratis. Alle Museen der Stadt findest du unter dem obigen Link.",
            einkaufentitle: "Einkaufen",
            einkaufenpara: "Was man am Sonntag generell nicht kann, ist Einkaufen. Es gibt aber ein paar Geschäfte, die an allen Tagen geöffnet haben:",
            einkaufenlist1: "In den Bahnhöfen Stadelhofen, Enge, Oerlikon und im Hauptbahnhof hat der Supermarkt offen.",
            einkaufenlist2: "Bäckereien sind meistens auch sonntags geöffnet.",
            einkaufenlist3: "Der Flughafen hat auch täglich geöffnet, samt allen vorhandenen Geschäfte.",
            abendtitle: "Am Abend",
            abendlink: "https://usgang.ch",
            abendbutton: "usgang.ch",
            abendpara: "Die besten Tipps für den Ausgang findest du unter diesem Link. Für die Donnerstage und Freitage schaust du am besten in unseren Newsletter, auf Fachvereinsseiten und/oder auf Instagram nach.",
            theatertitle: "Theater",
            theaterlink1: "https://www.opernhaus.ch/jung/club-jung/",
            theaterbutton1: "Club Jung des Opernhaus Zürich",
            theaterlink2: "https://kulturstelle.ch/de/",
            theaterbutton2: "Kulturstelle des VSETH",
            theaterpara: "Günstig ins Theater kommst du mit der Kulturstelle des VSETH. Für alle Vorstellungen gibt es aber auch Last Minute Tickets direkt an der Kasse. Das Gleiche gilt für das Opernhaus. Legi auf keinen Fall vergessen! Wenn du Glück hast, erhältst du Tickets der besten Kategorie (300 CHF) für 20CHF.",
            kinotitle: "Openair-Kino",
            kinolink: "https://kino-openair.ch",
            kinobutton: "kino-openair.ch",
            kinopara: "Im Sommer gibt es neben unserem eigenen Openair-Kino von der FLiK auch noch weitere Filme unter freiem Himmel. Eine Übersicht aller Openair- Kinos findest du unter dem obigen Link.",
            barstitle: "Bars",
            barspara: "In Zürich gibt es generell sehr viele gute Bars, viele auch nahe der Innenstadt. Schau dir die Bars mal selbst an und finde heraus, welche Bar du die beste findest. Eine günstige Alternative (allerdings nicht am Wochenende) ist das BQM gleich bei der Polyterrasse oder natürlich unsere Studibars: das LochNess dienstags und donnerstags auf dem Hönggerberg oder das PapperlaPub am Mittwoch im CAB.",
            clubstitle: "Clubs",
            clubspara: "Generell günstig sind die Eintrittspreise für Clubs am Donnerstag, vor allem die Partys im StuZ. Sonst zahlt man schnell über 20 CHF Eintritt.",
            wochenendetitle: "Ein Wochenende mit den Eltern",
            wochenendelink1: "https://zuerich.ch",
            wochenendebutton1: "zuerich.ch",
            wochenendelink2: "https://ronorp.net",
            wochenendebutton2: "ronorp.net",
            wochenendepara: "Wenn deine Eltern vorbeikommen, braucht es schnell ein gutes Programm. Fang an mit einem Bummel durch das Niederdorf oder die Altstadt mit dem Lindenhof. Ein ganz bünzliges und darum wieder schönes Café ist das Schober an der Napfgasse. Am besten zeigst du ihnen das schöne Hauptgebäude der ETH. Das Alpenpanorama von der Polyterrasse wird sie hoffentlich genug umhauen. Macht doch dann einen Spaziergang durch das urbane Zürich und schaut euch das Viadukt und die Sonnensüchtigen am Letten an. Dann könnt ihr im Clouds, dem Restaurant im Prime Tower lecker essen und die Aussicht geniessen. Mit dem Bus hoch zum Hönggerberg und sich auf den Feldern die Beine vertreten. Zum Kafi ins Restaurant zur Waid und bei dem Blick werden auch deine Eltern verstehen, wieso Zürich die schönste Stadt der Welt ist. Wir haben alles gegeben und unsere absoluten Highlights zusammengelegt. Falls Du schon nach zwei Tagen alles ausprobiert hast und deine Eltern weiter überraschen möchtest, findest du weitere Infos und Tipps unter den Links am Anfang.",
            ausflugtitle: "Ausflugtipps in andere Regionen",
            ausflugpara: "Die nächste grosse Stadt heisst Winterthur. Sie hat eine süsse Altstadt und ein tolles Fotomuseum. Dort befindet sich auch das Technorama. Ein Museum, in dem naturwissenschaftliche Phänomene auf einfache Weise erklärt werden. Am besten besuchst du es nach einem halben Jahr. Nach einem Semester Physik kannst du dir alle Effekte selber erklären. Juhu, das ETH-Basisjahr ist doch zu Etwas nutze. Noch weiter nördlich befindet sich der Rheinfall; immerhin der grösste Wasserfall Europas. Damit ein perfektes Ziel für eine ausgedehnte Fahrradtour. Und sonst hoch in die Berge. Zum Beispiel auf den grossen Mythen im Kanton Schwyz oder weiter in den Kanton Glarus. Dort gibt es wunderschöne Wanderwege. Oder nach Graubünden oder oder oder. Es gibt gefühlt tausende Websites im Internet!",
        },
        WohnenPage: {
            title: "Wohnen und Finanzielles",
            wohnentitle: "Wohnen in Zürich",
            wohnenpara: "In Zürich eine Wohnung zu finden, ist nicht ganz einfach. Vor allem auf dem freien Wohnungsmarkt haben Studenten aufgrund von grosser Nachfrage und hohen Preisen oftmals schlechte Karten. Einige weichen deshalb in günstigere Gemeinden ausserhalb der Stadt Zürich aus. Es gibt einige Plattformen, auf denen man Wohnungen suchen (und hoffentlich finden) kann. Kostengünstiges Wohnen in der Stadt Zürich bieten dir",
            wohnenlist1_1: "WoKo:",
            wohnenlist1_2: " Die Wohnkommission bietet kleine Wohnungen und Zimmer in Studentenheimen zu bezahlbaren Preisen. Siehe ",
            wohnenlist1link: "https://www.woko.ch",
            wohnenlist1linktext: "woko.ch",
            wohnenlist2_1: "Juwo:",
            wohnenlist2_2: " Die Stiftung Jugendwohnnetz bietet günstige Wohnugen für Jugendliche mit kleinem Einkommen. Siehe ",
            wohnenlist2link: "https://www.juwo.ch",
            wohnenlist2linktext: "juwo.ch",
            wohnenlist3_1: "ETH:",
            wohnenlist3_2: " Die ETH bietet ebenfalls eine Plattform an für die Wohnungssuche. Siehe ",
            wohnenlist3link: "https://www.wohnen.ethz.ch",
            wohnenlist3linktext: "wohnen.ethz.ch",
            wohnenlist4_1: "Student Village und Living Science:",
            wohnenlist4_2: " Auf dem Hönggerberg gibt es zwei Studierendenkomplexe: Das Student Village und das Living Science. Diese Wohnungen werden aber meistens über Facebook-Gruppen unter der Hand vergeben. Sucht auf Facebook nach den Gruppen, vielleicht habt ihr ja Glück.",
            finanziellestitle: "Finanzielles",
            finanziellespara1: "Das Leben in der Schweiz und vor allem in Zürich ist ziemlich teuer. Mit Miete, Essen und Kulturellem sind bald mehr als 1500 Franken monatlich weg. Die ETH hat eine Aufstellung der Lebenserhaltungskosten während des Studiums hier aufgestellt:",
            finanzielleslink1: "https://ethz.ch/de/die-eth-zuerich/arbeiten-lehren-forschen/welcome-center/services-und-downloads/lebenshaltungskosten.html",
            finanziellesbutton1: "Lebenserhaltungskosten",
            finanziellespara2: "Die ETH bietet ergänzende Stipendien für alle Studierenden, die aufgrund ihrer Situation bereits von anderen Stellen finanziell unterstützt werden. Aus diesem Grund sollte ein Studium an der ETH bestimmt nicht an finanziellen Hürden scheitern.",
            finanzielleslink2: "https://ethz.ch/studierende/de/studium/finanzielles/stipendien.html",
            finanziellesbutton2: "Stipendien an der ETH Zürich",
        },
    }
};