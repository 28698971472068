import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";

import {linkClassName, useStyles} from "../styles"
import { useTranslation } from "react-i18next";

export default function VorstellungPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
          <div className={classes.container}>

            <h1 className={classes.title}>{t("VorstellungPage.title")}</h1>

            <div className={classes.content}>
              
              <Paragraph>
              {t("VorstellungPage.para")}
              </Paragraph>

              <h2>{t("VorstellungPage.wertitle")}</h2>
              
              <Paragraph>
              {t("VorstellungPage.werpara")}
              </Paragraph>               


              <h2>{t("VorstellungPage.wotitle")}</h2>

              <h3>{t("VorstellungPage.zentrumtitle")}</h3>
                
              <Paragraph>
              {t("VorstellungPage.zentrumpara")}
              </Paragraph>

              <h3>{t("VorstellungPage.hoenggtitle")}</h3>

              <Paragraph>
              {t("VorstellungPage.hoenggpara")}
              </Paragraph>

              <h2>{t("VorstellungPage.erreichsttitle")}</h2>

              <Paragraph>
              {t("VorstellungPage.erreichstpara")}<a href="mailto:hallo@vseth.ethz.ch">hallo@vseth.ethz.ch</a>.
              </Paragraph>

              <h2>{t("VorstellungPage.mitgliedtitle")}</h2>
                
              <Paragraph>
              {t("VorstellungPage.mitgliedpara")}
              </Paragraph>

              <h2>{t("VorstellungPage.engagierentitle")}</h2>

              <Paragraph>
              {t("VorstellungPage.engagierenpara")}
              </Paragraph>

              <h3>{t("VorstellungPage.fachvereintitle")}</h3>

              <Paragraph>
              {t("VorstellungPage.fachvereinpara")}
              </Paragraph>

              <h3>{t("VorstellungPage.kommissiontitle")}</h3>

              <Paragraph>
              {t("VorstellungPage.kommissionpara")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" href={t("VorstellungPage.kommissionlink")}>
					  {t("VorstellungPage.kommissionbutton")}
		        </Button>

            <div className={classes.content}>

              <h3>{t("VorstellungPage.vorstandtitle")}</h3>
              <Paragraph>
              {t("VorstellungPage.vorstandpara")}<a href={t("VorstellungPage.vorstandlink")} className={linkClassName}>{t("VorstellungPage.vorstandlinktext")}</a>.
              </Paragraph>

              <h2>{t("VorstellungPage.sonstigestitle")}</h2>
                
              <Paragraph>
              {t("VorstellungPage.sonstigespara")}
              </Paragraph>

              <h3>
              {t("VorstellungPage.musiktitle")}
              <Button className={classes.headerbutton} component="a" href={t("VorstellungPage.musiklink")} leftIcon={<ExternalLink size={14} />}>
					    {t("VorstellungPage.musikbutton")}
		          </Button>
              </h3>

              <Paragraph>
              {t("VorstellungPage.musikpara")}
              </Paragraph>

              <h3>
              {t("VorstellungPage.buechertitle")}
              <Button className={classes.headerbutton} component="a" href={t("VorstellungPage.buecherlink")} leftIcon={<ExternalLink size={14} />}>
					    {t("VorstellungPage.buecherbutton")}
		          </Button>
              </h3>

              <Paragraph>
              {t("VorstellungPage.buecherpara")}
              </Paragraph>

              <h3>
              {t("VorstellungPage.euletitle")}
              <Button className={classes.headerbutton} component="a" href={t("VorstellungPage.eulelink")} leftIcon={<ExternalLink size={14} />}>
				      {t("VorstellungPage.eulebutton")}
		          </Button>
              </h3>
                
              <Paragraph>
              {t("VorstellungPage.eulepara")}
              </Paragraph>

            </div>
          </div>
        </SafeAreaContainer>
    </div>
  );
}
