import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";


import {linkClassName, useStyles} from "../styles"
import { useTranslation } from "react-i18next";

export default function SonstigesPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
          <div className={classes.container}>

            <h1 className={classes.title}>{t("SonstigesPage.title")}</h1>

            <div className={classes.content}>

              <h2>{t("SonstigesPage.ittitle")}</h2>

              <Paragraph>
              {t("SonstigesPage.itpara_1")}<b>polybox</b>{t("SonstigesPage.itpara_2")}
              </Paragraph>             

              <h3>
              {t("SonstigesPage.polyboxtitle")}
              <Button className={classes.headerbutton} component="a" href={t("SonstigesPage.polyboxlink")} leftIcon={<ExternalLink size={14} />}>
              {t("SonstigesPage.polyboxbutton")}
              </Button>
              </h3>

              <Paragraph>
              {t("SonstigesPage.polyboxpara1")}
              </Paragraph>

              <Paragraph>
              {t("SonstigesPage.polyboxpara2")}
              </Paragraph>

              <h3>
              {t("SonstigesPage.personensuchetitle")}
              <Button className={classes.headerbutton} component="a" href={t("SonstigesPage.personensuchelink")} leftIcon={<ExternalLink size={14} />}>
			        {t("SonstigesPage.personensuchebutton")}
	            </Button>
              </h3>

              <Paragraph>
              {t("SonstigesPage.personensuchepara")}
              </Paragraph>

              <h3>{t("SonstigesPage.apptitle")}</h3>
              <Paragraph>
              {t("SonstigesPage.apppara")}
              </Paragraph>

              <h3>{t("SonstigesPage.computertitle")}
              <Button className={classes.headerbutton} component="a" href={t("SonstigesPage.computerlink")} leftIcon={<ExternalLink size={14} />}>
              {t("SonstigesPage.computerbutton")}
              </Button>
              </h3>
                
              <Paragraph>
              {t("SonstigesPage.computerpara")}
              </Paragraph>

              <h3>
              {t("SonstigesPage.supporttitle")}
              <Button className={classes.headerbutton} component="a" href={t("SonstigesPage.supportlink")} leftIcon={<ExternalLink size={14} />}>
			        {t("SonstigesPage.supportbutton")}
		    	    </Button>
              </h3>

              <Paragraph>
              {t("SonstigesPage.supportpara1_1")}<a href={t("SonstigesPage.supportlink1")} className={linkClassName}>{t("SonstigesPage.supportlink1text")}</a>{t("SonstigesPage.supportpara1_2")}
              </Paragraph>

              <Paragraph>
              {t("SonstigesPage.supportpara2_1")}<a href={t("SonstigesPage.supportlink2")} className={linkClassName}>{t("SonstigesPage.supportlink2text")}</a>{t("SonstigesPage.supportpara2_2")}
              </Paragraph>

              <h3>
              {t("SonstigesPage.itshoptitle")}
              <Button className={classes.headerbutton} component="a" href={t("SonstigesPage.itshoplink")} leftIcon={<ExternalLink size={14} />}>
              {t("SonstigesPage.itshopbutton")}
              </Button>
              </h3>

              <Paragraph>
              {t("SonstigesPage.itshoppara")}
              </Paragraph>

              <h3>
              {t("SonstigesPage.multimediatitle")}
              <Button className={classes.headerbutton} component="a" href={t("SonstigesPage.multimedialink")} leftIcon={<ExternalLink size={14} />}>
              {t("SonstigesPage.multimediabutton")}
              </Button>
              </h3>

              <Paragraph>
              {t("SonstigesPage.multimediapara")}
              </Paragraph>

            </div>

            <div className={classes.content}>
              
              <h2>
              {t("SonstigesPage.austauschtitle")}
              <Button className={classes.headerbutton} component="a" href={t("SonstigesPage.austauschlink")} leftIcon={<ExternalLink size={14} />}>
              {t("SonstigesPage.austauschbutton")}
              </Button>
              </h2>

              <Paragraph>
              {t("SonstigesPage.austauschpara1")}
              </Paragraph>
              
              <Paragraph> 
              {t("SonstigesPage.austauschpara2")}
              </Paragraph>
              
              <h2>
              {t("SonstigesPage.sprachentitle")}
              <Button className={classes.headerbutton} component="a" href={t("SonstigesPage.sprachenlink")} leftIcon={<ExternalLink size={14} />}>
              {t("SonstigesPage.sprachenbutton")}
              </Button>
              </h2>
              
              <Paragraph>
              {t("SonstigesPage.sprachenpara")}
              </Paragraph>

          </div>

          <div className={classes.content}>

            <h2>{t("SonstigesPage.sphtitle")}
            <Button className={classes.headerbutton} component="a" href={t("SonstigesPage.sphlink")} leftIcon={<ExternalLink size={14} />}>
				    {t("SonstigesPage.sphbutton")}
					  </Button>
            </h2>

            <Paragraph>
            {t("SonstigesPage.sphpara1")}
            </Paragraph>

            <Paragraph>
            {t("SonstigesPage.sphpara2")}
            </Paragraph>

            <Paragraph>
            {t("SonstigesPage.sphpara3")}
            </Paragraph>


            <h2>
            {t("SonstigesPage.campustitle")}
            <Button className={classes.headerbutton} component="a" href={t("SonstigesPage.campuslink")} leftIcon={<ExternalLink size={14} />}>
				    {t("SonstigesPage.campusbutton")}
						</Button>
            </h2>

            <Paragraph>
            {t("SonstigesPage.campuspara")}
            </Paragraph>

            <h3>{t("SonstigesPage.auskunfttitle")}</h3>

            <Paragraph>
            {t("SonstigesPage.auskunftpara")}
            </Paragraph>

            <h2>
            {t("SonstigesPage.bibtitle")}
            <Button className={classes.headerbutton} component="a" href={t("SonstigesPage.biblink1")} leftIcon={<ExternalLink size={14} />}>
            {t("SonstigesPage.bibbutton1")}
            </Button>
            <Button className={classes.headerbutton} component="a" href={t("SonstigesPage.biblink2")} leftIcon={<ExternalLink size={14} />}>
            {t("SonstigesPage.bibbutton2")}
            </Button>
            </h2>

            <Paragraph>
            {t("SonstigesPage.bibpara1")}
            </Paragraph>

            <Paragraph>
            {t("SonstigesPage.bibpara2")}
            </Paragraph>

            <Paragraph>
            {t("SonstigesPage.bibpara3")}
            </Paragraph>

            <Paragraph>
            {t("SonstigesPage.bibpara4")}
            </Paragraph>             

            </div>
          </div>
        </SafeAreaContainer>

    </div>
  );
}
