import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import {Button, Image, Grid} from "@mantine/core";

import {useStyles} from "../styles"
import { useTranslation } from "react-i18next";

import legi from "../images/legi.png"
import vorlesung from "../images/vorlesung.jpg"
import essen from "../images/essen.jpg"
import link from "../images/link.jpg"

export default function WochePage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
          <div className={classes.container}>
            
            <h1 className={classes.title}>{t("WochePage.title")}</h1>

            <div className={classes.content}>

              <Paragraph>
              {t("WochePage.para1")}
              </Paragraph>

              <Paragraph>
              {t("WochePage.para2")}
              </Paragraph>

              <h2>{t("WochePage.legititle")}</h2>
          
              <Grid grow gutter="lg" justify="space-around" align="stretch">

                <Grid.Col sm ={8} md={5}>
                  <Paragraph>
                  {t("WochePage.legipara1")}
                  </Paragraph>
                </Grid.Col>

                <Grid.Col sm ={4} md={3}>
                  <div>
                    <Image
                      maw = {240}
                      mx="auto"
                      radius="md"
                      src={legi}
                      caption={t("WochePage.legicaption")}
                      alt={t("WochePage.legialt")}
                    />
                  </div>
                </Grid.Col>

              </Grid>

              <Paragraph>
              {t("WochePage.legipara2")}
              </Paragraph>
            </div>
            
            <Button className={classes.headerbutton} component="a" href="./legi">
            {t("WochePage.legibutton")}
            </Button>

            <div className={classes.content}>
              <h2>{t("WochePage.passworttitle")}</h2>

              <Paragraph>
              {t("WochePage.passwortpara")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" href="./passwort">
            {t("WochePage.passwortbutton")}
            </Button>

            <div className={classes.content}>

              <h2>{t("WochePage.wlantitle")}</h2>

              <Paragraph>
              {t("WochePage.wlanpara")}
              </Paragraph>

            </div>
            
            <Button className={classes.headerbutton} component="a" href="./wlan">
            {t("WochePage.wlanbutton")}
            </Button>
            
            <div className={classes.content}>

              <h2>{t("WochePage.mailtitle")}</h2>

              <Paragraph>
              {t("WochePage.mailpara")}
              </Paragraph>

            </div>
            
            <Button className={classes.headerbutton} component="a" href="./mail">
            {t("WochePage.mailbutton")}
            </Button>

            <div className={classes.content}>

              <h2>{t("WochePage.lehrtitle")}</h2>

              <Grid grow gutter="lg" justify="space-around" align="stretch">

                <Grid.Col sm ={8} md={5}>
                  <Paragraph>
                  {t("WochePage.lehrpara")}
                  </Paragraph>
                </Grid.Col>

                <Grid.Col sm ={4} md={3}>
                  <div>
                    <Image
                      mx="auto"
                      radius="md"
                      src={vorlesung}
                      caption={t("WochePage.lehrcaption")}
                      alt={t("WochePage.lehralt")}
                    />
                  </div>
                </Grid.Col>

              </Grid>

            </div>
            
            <Button className={classes.headerbutton} component="a" href="./lehrveranstaltungen">
            {t("WochePage.lehrbutton")}
            </Button>

            <div className={classes.content}>

              <h2>{t("WochePage.essentitle")}</h2>

              <Grid grow gutter="lg" justify="space-around" align="stretch">

                <Grid.Col sm ={8} md={5}>
                  <Paragraph>
                  {t("WochePage.essenpara")}
                  </Paragraph>
                </Grid.Col>

                <Grid.Col sm ={4} md={3}>
                  <div>
                    <Image
                      mx="auto"
                      radius="md"
                      src={essen}
                      caption={t("WochePage.essencaption")}
                      alt={t("WochePage.essenalt")}
                    />
                  </div>
                </Grid.Col>

              </Grid>
            </div>
            
            <Button className={classes.headerbutton} component="a" href="./essen">
            {t("WochePage.essenbutton")}
            </Button>

            <div className={classes.content}>

              <h2>{t("WochePage.studierentitle")}</h2>

              <Paragraph>
              {t("WochePage.studierenpara")}
              </Paragraph>

            </div>
            
            <Button className={classes.headerbutton} component="a" href="./studieren">
            {t("WochePage.studierenbutton")}
            </Button>

            <div className={classes.content}>

              <h2>{t("WochePage.lernplaetzetitle")}</h2>

              <Paragraph>
              {t("WochePage.lernplaetzepara")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" href="./lernplaetze">
            {t("WochePage.lernplaetzebutton")}
            </Button>

            <div className={classes.content}>

              <h2>{t("WochePage.mobilitaettitle")}</h2>

              <Grid grow gutter="lg" justify="space-around" align="stretch">

                <Grid.Col sm ={8} md={5}>
                  <Paragraph>
                  {t("WochePage.mobilitaetpara")}
                  </Paragraph>
                </Grid.Col>
                        
                <Grid.Col sm ={4} md={3}>
                  <div>
                    <Image
                      maw = {240}
                      mx="auto"
                      radius="md"
                      src={link}
                      caption={t("WochePage.mobilitaetcaption")}
                      alt={t("WochePage.mobilitaetalt")}
                    />
                  </div>
                </Grid.Col>

              </Grid>

            </div>
            
            <Button className={classes.headerbutton} component="a" href="./mobilitaet">
            {t("WochePage.mobilitaetbutton")}
            </Button>

            <div className={classes.content}>

              <h2>{t("WochePage.einschreibungtitle")}</h2>

              <Paragraph>
              {t("WochePage.einschreibungpara")}
              </Paragraph>

            </div>
            
            <Button className={classes.headerbutton} component="a" href="./einschreibung">
            {t("WochePage.einschreibungbutton")}
            </Button>

            <div className={classes.content}>

              <h2>{t("WochePage.sonstigestitle")}</h2>

              <Paragraph>
              {t("WochePage.sonstigespara")}
              </Paragraph>
              
            </div>
            
            <Button className={classes.headerbutton} component="a" href="./sonstiges">
            {t("WochePage.sonstigesbutton")}
            </Button>
            
            <div className={classes.content}>

              <h2>{t("WochePage.freizeittitle")}</h2>

              <Paragraph>
              {t("WochePage.freizeitpara")}
              </Paragraph>

            </div>
            
            <Button className={classes.headerbutton} component="a" href="./freizeit">
            {t("WochePage.freizeitbutton")}
            </Button>

            <div className={classes.content}>

              <h2>{t("WochePage.wohnentitle")}</h2>

              <Paragraph>
              {t("WochePage.wohnenpara")}
              </Paragraph>
            </div>
            
            <Button className={classes.headerbutton} component="a" href="./wohnen">
            {t("WochePage.wohnenbutton")}
            </Button>


            <div className={classes.content}>             

              <h2>{t("WochePage.unterstuetzungtitle")}</h2>

              <Paragraph>
              {t("WochePage.unterstuetzungpara")}
              </Paragraph>
              
            </div>

            <Button className={classes.headerbutton} component="a" href="https://help.vseth.ethz.ch">
            {t("WochePage.unterstuetzungbutton")}
            </Button>

          </div>
        </SafeAreaContainer>
    </div>
  );
}
