import { PropsWithChildren } from "react";

// Lukas fragen was das macht :D
export default function SafeAreaContainer({ children }: PropsWithChildren<{}>) {
  return (
    <div
      style={{
        paddingLeft: "env(safe-area-inset-left,0)",
        paddingRight: "env(safe-area-inset-right,0)",
      }}
    >
      {children}
    </div>
  );
}
