import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button, List, Image } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";

import { linkClassName, useStyles } from "../styles"
import { useTranslation } from "react-i18next";

import mail from "../images/mail.png"

export default function MailPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
            <div className={classes.container}>

                <h1 className={classes.title}>{t("MailPage.title")}</h1>

                <div className={classes.content}>
                    
                    <h2>{t("MailPage.adressetitle")}</h2>

                    <Paragraph>
                    {t("MailPage.adressepara1")}
                    </Paragraph>

                    <List className={classes.content}>
                        <List.Item>{t("MailPage.adresselist1")}</List.Item>
                        <List.Item>{t("MailPage.adresselist2")}</List.Item>
                        <List.Item>{t("MailPage.adresselist3")}</List.Item>
                        <List.Item>{t("MailPage.adresselist4")}</List.Item>
                        <List.Item>{t("MailPage.adresselist5")}</List.Item>
                        <List.Item>{t("MailPage.adresselist6")}</List.Item>
                    </List>

                    <Paragraph>
                    {t("MailPage.adressepara2")}
                    </Paragraph>

                    <Paragraph>
                    {t("MailPage.adressepara3_1")}<b><i>{t("MailPage.adressepara3_2")}</i>@student.ethz.ch</b>{t("MailPage.adressepara3_3")}<b><i>{t("MailPage.adressepara3_4")}</i>@ethz.ch</b>.
                    </Paragraph>

                    <h2>{t("MailPage.mailboxtitle")}</h2>

                    <Paragraph>
                    {t("MailPage.mailboxpara1")} <a href={t("MailPage.mailboxlink1_1")} className={linkClassName}>{t("MailPage.mailboxlink1_2")}</a>.
                    </Paragraph>
                
                </div>

                <Button className={classes.headerbutton} component="a" href={t("MailPage.mailboxlink1_1")} target="_blank" leftIcon={<ExternalLink size={14} />}>
                {t("MailPage.mailboxbutton1")}
                </Button>

                <div className={classes.content}>

                    <Paragraph>
                    {t("MailPage.mailboxpara2")}
                    </Paragraph>

                </div>

                <Button className={classes.headerbutton} component="a" href={t("MailPage.mailboxlink2")} target="_blank" leftIcon={<ExternalLink size={14} />}>
                {t("MailPage.mailboxbutton2")}
                </Button>
                
                <div className={classes.content}>

                    <h2>{t("MailPage.weiterleitungtitle")}</h2>

                    <Paragraph>
                    {t("MailPage.weiterleitungpara1")}<a href={t("MailPage.weiterleitunglink1_1")}>{t("MailPage.weiterleitunglink1_2")}</a>.
                    </Paragraph>
                    
                </div>    

                <Button className={classes.headerbutton} component="a" href={t("MailPage.weiterleitunglink1_1")} target="_blank" leftIcon={<ExternalLink size={14} />}>
                {t("MailPage.weiterleitungbutton")}
                </Button>

                <div className={classes.content}>

                    <div>
                        <Image
                            mx="auto"
                            radius="md"
                            src={mail}
                            caption={t("MailPage.weiterleitungcaption1")}
                            alt={t("MailPage.weiterleitungalt1")}
                        />
                    </div>

                    <Paragraph>
                    {t("MailPage.weiterleitungpara2")}
                    </Paragraph>

                    <Paragraph>
                    {t("MailPage.weiterleitungpara3")}
                    </Paragraph>

                </div>
            </div>
        </SafeAreaContainer>
    </div>
  );
}
