import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { List, Grid, Image } from "@mantine/core";


import { useStyles } from "../styles"

import legi from "../images/legi.png"
import { useTranslation } from "react-i18next";

export default function LegiPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
            <div className={classes.container}>
                
                <h1 className={classes.title}>{t("LegiPage.title")}</h1>

                <div className={classes.content}>

                    <Paragraph>
                    {t("LegiPage.para")}
                    </Paragraph>

                    <List className={classes.content}>
                        <List.Item>{t("LegiPage.list1")}</List.Item>
                        <List.Item>{t("LegiPage.list2")}</List.Item>
                        <List.Item>{t("LegiPage.list3")}</List.Item>
                    </List>

                    <h2>{t("LegiPage.title1")}</h2>
                    
                    <Grid grow gutter="lg" justify="space-around" align="stretch">

                        <Grid.Col sm ={8} md={5}>
                            <Paragraph>
                            {t("LegiPage.para1")}
                            </Paragraph>
                        </Grid.Col>
                        
                        <Grid.Col sm ={4} md={3}>
                            <div>
                                <Image
                                    maw={240}
                                    mx="auto"
                                    radius="md"
                                    src={legi}
                                    caption={t("LegiPage.caption")}
                                    alt={t("LegiPage.alt")}
                                />
                            </div>
                        </Grid.Col>
                        
                    </Grid>

                    <h2>{t("LegiPage.title2")}</h2>

                    <Paragraph>
                    {t("LegiPage.para2")}
                    </Paragraph>

                    <h2>{t("LegiPage.title3")}</h2>

                    <Paragraph>
                    {t("LegiPage.para3_1")}<i>{t("LegiPage.para3_2")}</i>@student.ethz.ch.
                    </Paragraph>

                </div>
            </div>
        </SafeAreaContainer>
    </div>
  );
}
