import { createStyles } from "@mantine/core";
import { FileX } from "tabler-icons-react";


export const useStyles = createStyles((_theme, _params, _getRef) => ({
    container: {
      marginTop: 20,
      marginBottom: 20,
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      minHeight: "64vh",
    },
    content: {
      maxWidth: 970,
      width: "100%",
      color: '#183059'
    },
    title: {
      maxWidth: 970,
      width: "100%",
      color: '#183059'
    },
    checkbox: { margin: 10 },
  
    headerbutton: {
      float: "right",
      marginLeft: 10,
    },

    button: {

      },

    videoright: {

      }
  }));

  export const linkClassName = "underline";