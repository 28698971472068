import { SetStateAction } from "react";
import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Grid } from "@mantine/core";
import PageGrid from "../PageGridListPage";
import { Button, List } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";
import { useTranslation } from "react-i18next";

import {linkClassName, useStyles} from "../styles"


export default function ListPage() {
    const { t } = useTranslation();
	const { classes } = useStyles();
	
	return (
		<div>
			<SafeAreaContainer>
                <div className={classes.container}>
          			<h1 className={classes.title}>
					  {t("HomePage.title")} an der ETH Lausanne
          			</h1>
                    
                        <PageGrid primaryColor={""} onPrimaryColorChange={function (value: SetStateAction<string>): void {
						throw new Error("Function not implemented.");
					} }></PageGrid>
			    </div>
			</SafeAreaContainer>
		</div>
	);
}