import { Dispatch, SetStateAction } from "react";
import { Card, Group, Image, Text, Grid} from "@mantine/core";
import { Link } from "react-router-dom";

import hg from "./images/hg.jpg"
import hg_wide from "./images/hg_wide.jpg"
import { useStyles} from "./styles"
import { useTranslation } from "react-i18next";
interface Cards {
    title: string,
    teaser: string,
    image_path: string,
    alt_image: string,
    page_link: string
    image: string
}

export function CardsFunction() {
    const { t } = useTranslation();
    const cards: Cards[] = [
        {
            title : t("PageGridHomePage.title1"),
            teaser : t("PageGridHomePage.teaser1"),
            image_path: "",
            alt_image: t("PageGridHomePage.alt1"),
            page_link: "/erstewoche",
            image: hg_wide
        }
    ]
    return cards;
}

{/*,
        {
            title : t("PageGridHomePage.title2"),
            teaser : t("PageGridHomePage.teaser2"),
            image_path: "./image.png",
            alt_image: t("PageGridHomePage.alt2"),
            page_link: "/list",
            image: hg
        }*/}

export default function PageGrid({
    primaryColor,
	onPrimaryColorChange,
}: {
	primaryColor: string;
	onPrimaryColorChange: Dispatch<SetStateAction<string>>;
}) {
	const { classes } = useStyles();
	return (
		<div className={classes.content}>
            <Grid grow gutter="lg" justify="space-around" align="stretch">
                
                {CardsFunction().map((c) => (
                    
                    <Grid.Col sm ={6} md={4}>
                        <div>
                         <Card shadow="lg" p="lg" component={Link} to={c.page_link}>
                             
                             <Card.Section>                
                                 <Image src={c.image} height={200} alt={c.alt_image} />
                             </Card.Section>
 
                             <Group position="apart" style={{ marginBottom: 5, marginTop: 5 }}>
                                 <Text weight={500}>{c.title}</Text>
                             </Group>
 
                             <Text size="sm" style={{ color: '#183059', lineHeight: 1.5 }}>
                                 {c.teaser}
                             </Text>
                         </Card>
                         </div>
                    </Grid.Col>
                     
        
                ))}
            </Grid>                                  
		</div>
	);
}
