import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button, List, Image } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";

import { useStyles } from "../styles"

import mystudieslogin from "../images/mystudieslogin.png"
import mystudiespage from "../images/mystudiespage.png"
import mystudiesfreiwillige from "../images/mystudiesfreiwillige.png"
import { useTranslation } from "react-i18next";

export default function LehrveranstaltungenPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
          <div className={classes.container}>
            <h1 className={classes.title}>{t("LehrveranstaltungenPage.title")}</h1>

            <div className={classes.content}>

              <Paragraph>
              {t("LehrveranstaltungenPage.para1_1")}<b>myStudies</b>{t("LehrveranstaltungenPage.para1_2")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" href={t("LehrveranstaltungenPage.link")} target="_blank" leftIcon={<ExternalLink size={14} />}>
            {t("LehrveranstaltungenPage.button")}
			      </Button>

            <div className={classes.content}>

              <Paragraph>
              {t("LehrveranstaltungenPage.para2")}
              </Paragraph>
              
              <Image
                  mx="auto"
                  radius="md"
                  src={mystudieslogin}
                  caption={t("LehrveranstaltungenPage.caption1")}
                  alt={t("LehrveranstaltungenPage.alt1")}
              />

              <Paragraph>
              {t("LehrveranstaltungenPage.para3")}
              </Paragraph>

              <List className={classes.content}>
                <List.Item><b>{t("LehrveranstaltungenPage.list1")}</b></List.Item>
                <List.Item><b>{t("LehrveranstaltungenPage.list2_1")}</b> {t("LehrveranstaltungenPage.list2_2")}</List.Item>
              </List>

              <Paragraph>
              {t("LehrveranstaltungenPage.para4")}
              </Paragraph>

              <Image
                mx="auto"
                radius="md"
                src={mystudiespage}
                caption={t("LehrveranstaltungenPage.caption2")}
                alt={t("LehrveranstaltungenPage.alt2")}
              />

              <Paragraph>
              {t("LehrveranstaltungenPage.para5")}
              </Paragraph>

            </div>

            <div className={classes.content}>

              <h2>{t("LehrveranstaltungenPage.stundenplantitle")}</h2>

              <Paragraph>
              {t("LehrveranstaltungenPage.stundenplanpara1")}
              </Paragraph>

              <List className={classes.content}>
                <List.Item><b>{t("LehrveranstaltungenPage.stundenplanlist1")}</b> <b>V</b> {t("LehrveranstaltungenPage.stundenplanlist1v")}, <b>U</b> {t("LehrveranstaltungenPage.stundenplanlist1u")}, <b>P</b> {t("LehrveranstaltungenPage.stundenplanlist1p")}, <b>S</b> {t("LehrveranstaltungenPage.stundenplanlist1s")}, <b>G</b> {t("LehrveranstaltungenPage.stundenplanlist1g")}.</List.Item>
                <List.Item><b>{t("LehrveranstaltungenPage.stundenplanlist2")}</b></List.Item>
                <List.Item><b>{t("LehrveranstaltungenPage.stundenplanlist3")}</b></List.Item>
                <List.Item><b>{t("LehrveranstaltungenPage.stundenplanlist4")}</b></List.Item>
              </List>

              <Paragraph>
              {t("LehrveranstaltungenPage.stundenplanpara2")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" href="/orientierung">
            {t("LehrveranstaltungenPage.stundenplanbutton")}
			      </Button>

            <div className={classes.content}>

              <Paragraph>
              {t("LehrveranstaltungenPage.stundenplanpara3")}
              </Paragraph>

              <h2>{t("LehrveranstaltungenPage.lernmaterialientitle")}</h2>
            
              <Paragraph>
              {t("LehrveranstaltungenPage.lernmaterialienpara")}
              </Paragraph>

              <h2>{t("LehrveranstaltungenPage.eduapptitle")}</h2>

              <Paragraph>
              {t("LehrveranstaltungenPage.eduapppara_1")}<b>EduApp</b>{t("LehrveranstaltungenPage.eduapppara_2")}
              </Paragraph>

              <h2>{t("LehrveranstaltungenPage.freiwilligetitle")}</h2>
              
              <Paragraph>
              {t("LehrveranstaltungenPage.freiwilligepara")}
              </Paragraph>

              <Image
                mx="auto"
                radius="md"
                src={mystudiesfreiwillige}
                caption={t("LehrveranstaltungenPage.freiwilligecaption")}
                alt={t("LehrveranstaltungenPage.freiwilligealt")}
              />

            </div>

          </div>
        </SafeAreaContainer>

    </div>
  );
}
