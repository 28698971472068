import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button, List } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";

import { linkClassName, useStyles } from "../styles"
import { useTranslation } from "react-i18next";

export default function WohnenPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
            <div className={classes.container}>
                
                <h1 className={classes.title}>{t("WohnenPage.title")}</h1>

                <div className={classes.content}>
                    
                    <h2>{t("WohnenPage.wohnentitle")}</h2>

                    <Paragraph>
                    {t("WohnenPage.wohnenpara")}
                    </Paragraph>

                    <List className={classes.content}>
                        <List.Item><b>{t("WohnenPage.wohnenlist1_1")}</b>{t("WohnenPage.wohnenlist1_2")}<a href={t("WohnenPage.wohnenlist1link")} className={linkClassName}>{t("WohnenPage.wohnenlist1linktext")}</a>.</List.Item>
                        <List.Item><b>{t("WohnenPage.wohnenlist2_1")}</b>{t("WohnenPage.wohnenlist2_2")}<a href={t("WohnenPage.wohnenlist2link")} className={linkClassName}>{t("WohnenPage.wohnenlist2linktext")}</a>.</List.Item>
                        <List.Item><b>{t("WohnenPage.wohnenlist3_1")}</b>{t("WohnenPage.wohnenlist3_2")}<a href={t("WohnenPage.wohnenlist3link")} className={linkClassName}>{t("WohnenPage.wohnenlist3linktext")}</a>.</List.Item>
                        <List.Item><b>{t("WohnenPage.wohnenlist4_1")}</b>{t("WohnenPage.wohnenlist4_2")}</List.Item>
                    </List>

                </div>

                <div className={classes.content}>

                    <h2>{t("WohnenPage.finanziellestitle")}</h2>

                    <Paragraph>
                    {t("WohnenPage.finanziellespara1")}
                    </Paragraph>

                </div>

                <Button className={classes.headerbutton} component="a" href={t("WohnenPage.finanzielleslink1")} target="_blank" leftIcon={<ExternalLink size={14} />}>
                {t("WohnenPage.finanziellesbutton1")}
                </Button>

                <div className={classes.content}>
                    
                    <Paragraph>
                    {t("WohnenPage.finanziellespara2")}
                    </Paragraph>

                </div>

                <Button className={classes.headerbutton} component="a" href={t("WohnenPage.finanzielleslink2")} target="_blank" leftIcon={<ExternalLink size={14} />}>
                {t("WohnenPage.finanziellesbutton2")}
                </Button>

            </div>
        </SafeAreaContainer>
    </div>
  );
}
                    