import SafeAreaContainer from "../components/safe-area-container";


import {useStyles} from "../styles"

export default function PageNotFound() {
	const { classes } = useStyles();
	
	return (
		<div>
			
			<SafeAreaContainer>
        		<div className={classes.container}>
          			<h1 className={classes.title}>
            			404 Page not Found
          			</h1>
                </div>
			</SafeAreaContainer>

		</div>
	);
}
