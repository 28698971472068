import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Grid, Image } from "@mantine/core";


import { useStyles } from "../styles"
import { useTranslation } from "react-i18next";

import zentrum from "../images/zentrum.png"
import hoenggerberg from "../images/hoenggerberg.png"
import link from "../images/link.jpg"

export default function OrientierungPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
        <SafeAreaContainer>
          <div className={classes.container}>

            <h1 className={classes.title}>{t("OrientierungPage.title")}</h1>

            <div className={classes.content}>

              <h2>{t("OrientierungPage.campustitle")}</h2>

              <Paragraph>
              {t("OrientierungPage.campuspara1_1")}<b>Zentrum</b>{t("OrientierungPage.campuspara1_2")}<b>Hönggerberg</b>.
              </Paragraph>

              <Grid grow gutter="lg" justify="space-around" align="stretch">

                <Grid.Col sm ={6} md={4}>
                  <div>
                    <Image
                      maw = {480}
                      mx="auto"
                      radius="md"
                      src={zentrum}
                      caption={t("OrientierungPage.campuscaptionzentrum")}
                      alt={t("OrientierungPage.campusaltzentrum")}
                    />
                  </div>
                </Grid.Col>
                        
                <Grid.Col sm ={6} md={4}>
                  <div>
                    <Image
                      maw = {480}
                      mx="auto"
                      radius="md"
                      src={hoenggerberg}
                      caption={t("OrientierungPage.campuscaptionhoenggerberg")}
                      alt={t("OrientierungPage.campusalthoenggerberg")}
                    />
                  </div>
                </Grid.Col>

              </Grid>

              <Grid grow gutter="lg" justify="space-around" align="stretch">

                <Grid.Col sm ={6} md={4}>
                  <div>
                    <Image
                      maw = {480}
                      mx="auto"
                      radius="md"
                      src={link}
                      caption={t("OrientierungPage.campuscaptionlink")}
                      alt={t("OrientierungPage.campusaltlink")}
                    />
                  </div>
                </Grid.Col>

              </Grid>

              <Paragraph>
              {t("OrientierungPage.campuspara2_1")}<b>ETH eLink</b>{t("OrientierungPage.campuspara2_2")}
              </Paragraph>

              <h2>{t("OrientierungPage.gebaeudetitle")}</h2>
              <Paragraph>
              {t("OrientierungPage.gebaeudepara1")}
              </Paragraph>

              <Paragraph>
              <b>{t("OrientierungPage.gebaeudepara2_1")}</b>{t("OrientierungPage.gebaeudepara2_2")}<b>H</b>{t("OrientierungPage.gebaeudepara2_3")}
              </Paragraph>

              <h2>{t("OrientierungPage.raeumetitle")}</h2>

              <Paragraph>
              {t("OrientierungPage.raeumepara1_1")}<b>E</b> {t("OrientierungPage.raeumepara1_2")}<b>F, G, ...</b> {t("OrientierungPage.raeumepara1_3")}<b>D, C, ...</b>{t("OrientierungPage.raeumepara1_4")}
              </Paragraph>

              <Paragraph>
              {t("OrientierungPage.raeumepara2_1")}<b>HCI J 7</b>{t("OrientierungPage.raeumepara2_2")}<b>HCI</b>{t("OrientierungPage.raeumepara2_3")}<b>J</b>{t("OrientierungPage.raeumepara2_4")}<b>E</b>{t("OrientierungPage.raeumepara2_5")}<b>7</b>{t("OrientierungPage.raeumepara2_6")}
              </Paragraph>

              <h2>{t("OrientierungPage.eduapptitle")}</h2>

              <Paragraph>
              <b>{t("OrientierungPage.eduapppara_1")}</b>{t("OrientierungPage.eduapppara_2")}<b>Campus Map</b>{t("OrientierungPage.eduapppara_3")}
              </Paragraph>
                
            </div>
          </div>
        </SafeAreaContainer>

    </div>
  );
}
