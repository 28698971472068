import SafeAreaContainer from "../components/safe-area-container";
import Paragraph from "../components/paragraph";
import { Button, List } from "@mantine/core";
import { ExternalLink } from "tabler-icons-react";

import { useStyles} from "../styles"
import { useTranslation } from "react-i18next";

export default function EssenPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <SafeAreaContainer>
          <div className={classes.container}>

            <h1 className={classes.title}>{t("EssenPage.title")}</h1>

            <div className={classes.content}>

              <Paragraph>
              {t("EssenPage.para")}
              </Paragraph>

              <Button className={classes.headerbutton} component="a" target="_blank" href={t("EssenPage.link")} leftIcon={<ExternalLink size={14} />}>
              {t("EssenPage.button")}
              </Button>
            </div>

            <div className={classes.content}>

              <h2>{t("EssenPage.mensentitle")}</h2>

              <Paragraph>
              {t("EssenPage.mensenpara")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" href="./mensen">
            {t("EssenPage.mensenbutton")}
            </Button>

            <div className={classes.content}>
              
              <h2>{t("EssenPage.mikrowellentitle")}</h2>

              <Paragraph>
              {t("EssenPage.mikrowellenpara")}
              </Paragraph>

              <List className={classes.content}>
                <List.Item>{t("EssenPage.mikrowellenlist1")}</List.Item>
                <List.Item>{t("EssenPage.mikrowellenlist2")}</List.Item>
                <List.Item>{t("EssenPage.mikrowellenlist3")}</List.Item>
                <List.Item>{t("EssenPage.mikrowellenlist4")}</List.Item>
                <List.Item>{t("EssenPage.mikrowellenlist5")}</List.Item>
              </List>

              <h2>{t("EssenPage.truckstitle")}</h2>

              <Paragraph>
              {t("EssenPage.truckspara1")}
              </Paragraph>

            </div>

            <Button className={classes.headerbutton} component="a" target="_blank" href={t("EssenPage.truckslink")} leftIcon={<ExternalLink size={14} />}>
            {t("EssenPage.trucksbutton")}
            </Button>

            <div className={classes.content}>

              <Paragraph>
              {t("EssenPage.truckspara2")}  
              </Paragraph>
              
              <h2>{t("EssenPage.sonstigestitle")}</h2>

              <Paragraph>
              {t("EssenPage.sonstigespara")}
              </Paragraph>

            </div>
  
          </div>
        </SafeAreaContainer>

    </div>
  );
}
